import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Dialog, TableRow, TableCell, Tooltip, IconButton, Checkbox} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ConfirmDialog from "./ConfirmDialog";
import { fillStEditFormApi } from "../apiServices";
import StTaskForm from "./StTaskForm";
import moment from "moment";
import { regexConstants } from "../../../../constants/regEx";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { permissionCheckFnforCamo, checkApiStatus,Comma_format } from "../../../../utils";
import { globalPutService, globalDeleteService } from "../../../../utils/globalApiServices";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";

const StList = ({ item, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, headerTsn, headerCsn, uuid, downloadAllApi, last_used_folder_uuid, getResponseBack, last_used_folder_uuid_link_document, checkbox_last_used_folder_uuid, checkbox_last_used_folder_name,assetsDetail,bulkOperation,toggleBulkOps,basicDetails }) => {
	const [open, setOpen] = useState(false);
	const [openEditForm, setEditForm] = useState(false);
	const [editFormId, setEditFormId] = useState(null);
	const [editFormData, setEditFormData] = useState(null);
	const [formTitle, setFormTitle] = useState("");
	const [attachments, setAttachments] = useState(null);
	const [showFullDescription, setShowFullDescription] = useState({});
	const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false)
	const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] = useState(null)
	const [moduleId, setmoduleId] = useState(null)
	const [lessMore, setLessMore] = useState({});

	const handleClick = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const closeAddForm = () => {
		setEditForm(false);
	};
	const toggleLessMore = (lessMr) => {
		setLessMore({
			...lessMore,
			[lessMr]: !lessMore[lessMr]
		});
	}
	const lessMoreFn = (name, lessMr) => {
		return <>
			{name && name?.length > 35 ? (
				<>
					{lessMore[lessMr] ?
						(<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(lessMr); }}  > ..less </span> </>)
						:
						(<>{name.substring(0, 35)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(lessMr); }}  > ..more </span> </>)
					}
				</>
			) : name || '--'
			}
		</>
	}

	const handleEditRow = () => {
		fillStEditFormApi(item, props).then((response) => {
			setEditFormData(response.data.data);
			setEditFormId(response.data.data.id);
			setAttachments(response.data.data.attachments);
			setFormTitle("Update Soft Time");
			setEditForm(true);
		});
	};
	const downAllAttach = () => {
		fillStEditFormApi(item, props).then((response) => {
			setmoduleId(response.data.data.id)
		})
		getAttachements()
	}
	const getAttachements = () => {
		const matchingItem = assetsDetail?.list?.find(items => items?.id === item.id);
		if (!matchingItem) return [];
		const { checklist, attachments } = matchingItem || {};
		if (checklist && attachments || checklist || attachments) {
			const { files, folders } = checklist || {}; // Add null check here
			if (attachments?.length > 0 && files?.length > 0 && folders?.length > 0) {
				getAttachments([...attachments, ...files, ...folders])
			} else if (files?.length > 0 && folders?.length > 0) {
				getAttachments([...folders, ...files])
			} else if (attachments?.length > 0 && files?.length > 0) {
				getAttachments([...attachments, ...files])
			} else if (attachments?.length > 0 && folders?.length > 0) {
				getAttachments([...attachments, ...folders])
			} else if (attachments?.length > 0) {
				getAttachments([...attachments])
			} else if (folders?.length > 0) {
				getAttachments([...folders])
			} else if (files?.length > 0) {
				getAttachments([...files])
			}
		}
		return [];

	};

	const openAttachment = (file) => {
		var decodedURL = decodeURIComponent(file);
		window.open(decodedURL, "_blank");
	}
	const getAttachments = (attachments) => {
		setAllAttachmentsforDialogue(attachments)
		setAttachmentsDialogue(true)
	}
	const handleCloseAttachmentDialogue = () => {
		setAttachmentsDialogue(false)
	}

	const removeAttachment = (item, checklist, fileId, uuid) => {
        const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
        const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
        if (uuid) {
			let payload = {};
			payload.delete = true;
			payload.folder_ids = checklist.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).toString()
			payload.file_ids = checklist.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).toString()
            globalPutService(`camo/soft_time/${item?.id}/document-checklists/`, payload)
                .then((response) => {
                    if (checkApiStatus(response)) {
                        fillStEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							const matchingItem = response.data.data;
							if (!matchingItem) return [];
							const { checklist, attachments } = matchingItem || {};
							if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								}else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

							return [];
						});
                    }
                })
        } else {
            globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
                if (response.status === 500) {
                    props.enqueueSnackbar("Something went wrong.", {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    });
                } else {
                    if (response) {
                        fillStEditFormApi(item, props).then((response) => {
                            setEditFormData(response.data.data);
							const matchingItem = response.data.data;
							if (!matchingItem) return [];
							const { checklist, attachments } = matchingItem || {};
							if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								}else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

							return [];
						});
                    }
                }
            });
        }
    };
	const formatDate = (date) => {
		return moment(date).format("DD-MM-YYYY");
	};
	const toggleDescription = (id) => {
		setShowFullDescription({
			...showFullDescription,
			[id]: !showFullDescription[id],
		});
	};

	let timezone = moment().format()
	const timestamp = Math.floor(Date.now() / 1000);
	const softTimeurl = `audit/camo/softtime/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`
    function getAttachmentCount(item) {
		const { attachments, checklist } = item;
		const { files, folders } = checklist || {};

		const attachmentCount = attachments?.length || 0;
		const fileCount = files?.length || 0;
		const folderCount = folders?.length || 0;

		if (attachmentCount && fileCount && folderCount) {
			return attachmentCount + fileCount + folderCount;
		} else if (fileCount && folderCount) {
			return fileCount + folderCount;
		} else if (attachmentCount && folderCount) {
			return attachmentCount + folderCount;
		} else if (attachmentCount && fileCount) {
			return attachmentCount + fileCount;
		} else {
			return folderCount || fileCount || attachmentCount || '--';
		}
	}
	const attachmentCount = getAttachmentCount(item);
	const selectedItem = bulkOperation.ids.find(data => data.id === item?.id);

	return (
		<>
			<TableRow hover tabIndex={-1} style={{ cursor: "pointer" }} className={item.remainingValues_D < 0 || item.remainingValues_D == null ? "red-row" : "none"} >
				{window?.location?.search.includes('archived-assets') ? null :
				<TableCell width="30">
					<Checkbox
						name={item.id}
						color="primary"
						size="small"
						onChange={(e) => toggleBulkOps(e.target.checked)}
						checked={selectedItem ? true : false}
					/>
				</TableCell>}
				<TableCell> {window?.location?.search.includes('archived-assets') ? null : (
					<Tooltip title="Edit">
						<IconButton className="edit-icon" onClick={(e) => { handleEditRow(e); }}>
							<CreateOutlinedIcon />
						</IconButton>
					</Tooltip>
				)}
					{window?.location?.search.includes('archived-assets') ? null : (
						<Tooltip title="Delete" arrow>
							<IconButton className="delete-icon" onClick={handleClick}>
								<DeleteOutlinedIcon />
							</IconButton>
						</Tooltip>
					)}
					<RevisionHistory buttonType={true} url={softTimeurl} />
				</TableCell>
				<TableCell>{item.part_number ? item.part_number : "--"}</TableCell>
				<TableCell>{item.serial_number ? item.serial_number : "--"}</TableCell>
				<TableCell style={{minWidth:"200px" }}> {lessMoreFn(item?.description, "description")} </TableCell>
				<TableCell>{item.life_limit ? regexConstants.alphanumespcespclchar.test(item.life_limit) ? item.life_limit :  Comma_format(parseInt(item.life_limit)) : "--"} </TableCell>
				<TableCell>{item.tsn ?  regexConstants.alphanumespcespclchar.test(item.tsn) ? item.tsn :  Comma_format(parseFloat(item.tsn)) : "--"}</TableCell>
				<TableCell>{item.csn ? regexConstants.alphanumespcespclchar.test(item.csn) ? item.csn :  Comma_format(parseInt(item.csn)) : "--"}</TableCell>
				<TableCell>{item?.remaining?.life_remaining > 0 ? Comma_format(parseInt(item?.remaining?.life_remaining)) : item?.remaining?.life_remaining === null ? "--" :0}</TableCell>
				<TableCell style={{minWidth:"200px" }}> {lessMoreFn(item?.remarks, "remarks")} </TableCell>
				<TableCell>
					{attachmentCount ? (
						<>
							<Tooltip title="View Attachments">
								<span className="center" onClick={() => downAllAttach()} >
									<AttachFileIcon className="attach-icon" />
									<a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
								</span>
							</Tooltip>
						</>
					)
						: (
							"--"
						)}
				</TableCell>
			</TableRow>
			<Dialog open={open} onClose={handleClose} id="htDialog">
				<ConfirmDialog
					handleClose={() => handleClose()}
					handleDeleteRow={handleDeleteRow}
				/>
			</Dialog>
			<Dialog
				position="relative"
				maxWidth="lg"
				open={openEditForm}
			>
				<StTaskForm
					formTitle={formTitle}
					closeAddForm={() => closeAddForm()}
					openForm={()=>setEditForm(true)}
					props={props}
					editFormId={editFormId}
					editFormData={editFormData}
					attachments={attachments}
					item={item}
					headerTsn={headerTsn}
					headerCsn={headerCsn}
					updateFormData={updateFormData}
					fileUploadData={fileUploadData}
					currentRecordPerPage={currentRecordPerPage}
					uuid={uuid}
					last_used_folder_uuid={last_used_folder_uuid}
					last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
					checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
					checkbox_last_used_folder_name={checkbox_last_used_folder_name}
					getResponseBack={() => getResponseBack()}
				/>
			</Dialog>
			<Dialog
				open={openGetAttachmentsDialogue}
				onClose={handleCloseAttachmentDialogue}
				id="htDialog"
			>
				<ShowAttachments
					handleClose={() => handleCloseAttachmentDialogue()}
					handleShowAttachments={AllAttachmentsforDialogue}
					openAttachment={openAttachment}
					files={{ title: "AMP", extension: "zip", key: "" }}
					downloadAllApi={downloadAllApi}
					moduleId={moduleId}
					basicDetails={basicDetails}
					removeAttachment={removeAttachment}
					item={item}
				/>
			</Dialog>
		</>
	);
};
export default withRouter(StList);