export const formValues = {
  utilization_period: null,
  tsn:null,
  csn:null,
  monthly_FH: null,
  monthly_FC: null,
  monthly_derate: null,
  operating_thrust_current: null,
  location_reg_aircraft: '',
  location_position: null,
  engine_stand_location:'',
  location_country: '',
  date_of_installation:null,
  date_of_removal:null,
  reason_of_removal:'',
  remarks: "",
  attachments: [],
  checklist: [],
  folder_uuid: '',
  operating_environment:null,
  redirect_url:null,
}

export const taskErrorCode = {
  utilization_period: {
    0: "",
    1: "Required",
  },
  utilization_period_obj: {
    required: true,
  },
  monthly_FH: {
    0: "",
    1: "Required",
  },
  monthly_FH_obj: {
    required: true,
  },
  monthly_FC: {
    0: "",
    1: "Required",
  },
  monthly_FC_obj: {
    required: true,
  },
}
