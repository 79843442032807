export const LLpTableHeadMainHead = [
	{ id: 1, label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "Total Since New", sortOption: false, colspan: 2 },
	{ id: "", label: "Operated Cycles", sortOption: false, colspan: 5 },
	{ id: "", label: "Life Limit", sortOption: false, colspan: 5 },
	{ id: "", label: "Cycles Remaining", sortOption: false, colspan: 5 },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },

]
export const LLpTableHeadMainHead_B = [
	{ id: 1, label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "Total Since New", sortOption: false, colspan: 2 },
	{ id: "", label: "Operated Cycles", sortOption: false, colspan: 2 },
	{ id: "", label: "Life Limit", sortOption: false, colspan: 2 },
	{ id: "", label: "Cycles Remaining", sortOption: false, colspan: 2 },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },

]
export const LLpTableHeadMainHead_C = [
	{ id: 1, label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "Total Since New", sortOption: false, colspan: 2 },
	{ id: "", label: "Operated Cycles", sortOption: false, colspan: 3 },
	{ id: "", label: "Life Limit", sortOption: false, colspan: 3 },
	{ id: "", label: "Cycles Remaining", sortOption: false, colspan: 3 },
	{ id: "", label: "", sortOption: false },
	{ id: "", label: "", sortOption: false },

]

export const LLpTableHead = [
	{ id: "action", label: "Action" },
	{ id: "module_type", label: "Module" ,sortOption: true},
	{ id: "description", label: "Description" ,sortOption: true},
	{ id: "part_number", label: "Part Number" ,sortOption: true},
	{ id: "serial_number", label: "Serial Number",sortOption: true },
	{ id: "tsn", label: "TSN",sortOption: true },
	{ id: "csn", label: "CSN" ,sortOption: false},
	{ id: "COOTR_thrust_a", label: "Thrust A" ,sortOption: true},
	{ id: "COOTR_thrust_b", label: "Thrust B" ,sortOption: true},
	{ id: "COOTR_thrust_c", label: "Thrust C",sortOption: true },
	{ id: "COOTR_thrust_d", label: "Thrust D" ,sortOption: true},
	{ id: "COOTR_thrust_e", label: "Thrust E",sortOption: true },

	{ id: "life_limit_thrust_a", label: "Thrust A" ,sortOption: true},
	{ id: "life_limit_thrust_b", label: "Thrust B",sortOption: true },
	{ id: "life_limit_thrust_c", label: "Thrust C",sortOption: true },
	{ id: "life_limit_thrust_d", label: "Thrust D",sortOption: true },
	{ id: "life_limit_thrust_e", label: "Thrust E",sortOption: true },

	{ id: "", label: "Thrust A",sortOption: true },
	{ id: "", label: "Thrust B" ,sortOption: true},
	{ id: "", label: "Thrust C" ,sortOption: true},
	{ id: "", label: "Thrust D" ,sortOption: true},
	{ id: "", label: "Thrust E" ,sortOption: true},
	{ id: "remarks", label: "Remarks" ,sortOption: true},
	{ id: "attachment", label: "Attachment" },
]
