import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { Box, Grid, IconButton } from "@material-ui/core";
import MasterSearchBar from "../components/MasterSearchBar";
import MasterTable from "../components/MasterTable";
import { permissionCheckFnforCamo, } from "../../../../utils";
import { MasterTableHead } from "./MasterTableHead";
import { AccessDenied, EmptyCollection, PageLoader, } from "../../../../shared_elements";
import MasterList from "../components/MasterList";
import { deleteMasterDetailRow, fillMasterEditFormApi, getMasterCompApi, imporMasterFileApi, UpdateMasterFormApi, uploadMasterFileApi, exportMasterListApi, DownloadAllApi, exportMasterApi,bulkDelete } from "../apiServices";
import BasicDetailHeader from "../../BasicDetailHeader";
import { Link } from "react-router-dom";
import RefreshIcon from "@material-ui/icons/Refresh";
import PieChartOutlinedIcon from "@material-ui/icons/PieChartOutlined";
import { Tooltip } from "@material-ui/core";
import { assetType } from "../../../../constants";
import FlightIcon from '@material-ui/icons/Flight';
import config from '../../../../config'
import DownloadXLSAssetDetail from "../../../../shared_elements/DownloadXLSAssetDetail";
import { getLocalStorageInfo } from "../../../../utils";


class MasterListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addFormDialog: false,
            sidebarOpen: true,
            tabIndex: 0,
            pageLoader: false,
            sort: "",
            sort_by: "",
            assetsDetail: {
                list: [],
                pagination: {},
                asset_count: {},
                new_task_last_used_folder_uuid: '',
                last_used_folder_uuid_link_document: "",
                checkbox_last_used_folder_uuid: '',
                checkbox_last_used_folder_name: ''
            },
            headerData: {},
            headerTsn: "",
            headerCsn: "",
            currentRecordPerPage: 50,
            basicDetails:{},
            uuid: "",
            slug: "",
            bulkOperation: { modal: false, ids: [], title: '', type: '' }
        };
        this.getMasterCompApi = getMasterCompApi.bind(this);
        this.openAddForm = this.openAddForm.bind(this);
        this.closeAddForm = this.closeAddForm.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleDeleteRow = deleteMasterDetailRow.bind(this); //done
        this.handleEditRow = fillMasterEditFormApi.bind(this);
        this.UpdateMasterFormApi = UpdateMasterFormApi.bind(this); //done
        this.uploadMasterFileApi = uploadMasterFileApi.bind(this); //done
        this.imporMasterFileApi = imporMasterFileApi.bind(this); //done
        this.downloadAllApi = DownloadAllApi.bind(this);
        this.exportMasterListApi = exportMasterListApi.bind(this);
        this.exportMasterApi = exportMasterApi.bind(this);
        this.bulkDelete = bulkDelete.bind(this);
    }

    componentDidMount() {
        this.getData();
        this.pullData();
    }
    pullData = (slug, tsn, csn,) => {
        this.setState({ slug: slug });
        this.setState({ headerTsn: tsn });
        this.setState({ headerCsn: csn });

    };
    uuidData = (uuid) => {
        this.setState({ uuid: uuid });
    }


    getData(item) {
        this.getMasterCompApi({ per_page: this.state.currentRecordPerPage }, "skeletonLoader", this.props, item ? item : null);
    }
    openAddForm() {
        this.setState({ addFormDialog: true });
    }
    closeAddForm() {
        this.setState({ addFormDialog: false });
    }

    toggleSidebar() {
        this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }
    createSortHandler = (sortField) => {
        const { sort, sort_by, filter, tabIndex, assetsDetail } = this.state;
        this.setState({
            sort: sortField,
            sort_by:
                sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "desc",
        })
        if (sortField === sort) {
            if (sort_by === "asc") {
                this.getMasterCompApi({ ...filter, sort: sortField, sort_by: "desc", per_page: assetsDetail.pagination.per_page, asset_type: tabIndex, }, "pageLoader", this.props);
            } else {
                this.getMasterCompApi({ ...filter, sort: sortField, sort_by: "asc", per_page: assetsDetail.pagination.per_page, asset_type: tabIndex, }, "pageLoader", this.props);
            }
        } else {
            this.getMasterCompApi({ ...filter, sort: sortField, sort_by: "asc", per_page: assetsDetail.pagination.per_page, asset_type: tabIndex, }, "pageLoader", this.props);
        }
    };
    searchResults = (item) => {
        if (item !== null) {
            this.getData(item);
        }
    };

    updateFormData = async (updateId, updatedData, props,close,formclose) => {
        await this.UpdateMasterFormApi(updateId, updatedData, props, this.state.currentRecordPerPage,close,formclose);
    };

    fileUploadData = async (uploadId, formdata, props, haveAttachments) => {
        await this.uploadMasterFileApi(uploadId, formdata, props, this.state.currentRecordPerPage, haveAttachments);
    };

    importFile = (formData, props, isXLSX) => {
        this.imporMasterFileApi(formData, props, isXLSX, this.state.currentRecordPerPage);
    };

    exportAssetDetailListFunc = (props) => {
        this.exportMasterListApi(props);
    };

    downloadAllFilesApi = (props) => {
        this.downloadAllApi(props);
    };

    refreshComp = () => {
        this.getData();
    };
    engineType=(data)=>{
		this.setState({basicDetails:data})
	}
    toggleBulkOps = async (flag, type, item) => {
        const { bulkOperation, assetsDetail } = this.state;   
        try {
            if (type === 'bulk') {
                const response = await this.getMasterCompApi(
                    { per_page: assetsDetail?.pagination?.total},
                    "skeletonLoader",
                    this.props,
                    null
                );
                this.setState({
                    bulkOperation: { ...bulkOperation, type: 'bulk', ids: flag ? response?.data?.results : [] }
                });
            } else {
                this.setState({
                    bulkOperation: {
                        ...bulkOperation,
                        type: 'single',
                        ids: flag ? [...bulkOperation.ids, item] : bulkOperation.ids.filter(optionItem => optionItem.id !== item.id)
                    }
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    render() {
        const { tabIndex, sort, sort_by, assetsDetail, pageLoader, uuid, slug,bulkOperation ,basicDetails} = this.state;
        const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
        return (
            <section className="amp-listing-section">
                {pageLoader ? <PageLoader /> : null}
                <Grid className="mt-0" spacing={3} container>
                    <Grid className="pt-0 pr-0" xs={12} item>
                        <Box display="flex" alignItems="center">
                            <Box flexGrow={1}>
                                <ul className='list-inline'>
                                    <li className='list-inline-item'> <h3 className="module-heading" style={{ marginTop: '6px' }}>Master Components</h3></li>
                                    {smbcInstance ? null :<li className='list-inline-item'> <a href={`${config.domain.subDomian}technical-specification/${assetType[this.props?.match?.params?.assetType]}/${slug}`} target="_blank"> <h3 className="module-heading" style={{ marginTop: '6px' }}><FlightIcon fontSize='small' color='primary' style={{ verticalAlign: "bottom", marginLeft: "30px" }} />Technical Specification</h3></a></li>}
                                    {smbcInstance && <li className='list-inline-item export-list' > <DownloadXLSAssetDetail files={{ title: "Master Export", extension: "xls", key: "" }} exportReportFn={(file) => this.exportMasterApi({ download: file.extension }, "pageLoader", this.props)} style={{ color: "#bd10e0" }} /> </li>}
                                </ul>
                            </Box>
                            <Box className="mr-18">
                                {smbcInstance ? null : <Link to={`/dashboard`}>
                                    <Tooltip title="Analytics">
                                        <IconButton className="p-5px" color="primary">
                                            <PieChartOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Link>}
                                <Tooltip title="Refresh">
                                    <IconButton
                                        className="p-5px"
                                        color="primary"
                                        onClick={this.refreshComp}
                                    >
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        <BasicDetailHeader props={this.props} func={this.pullData} engineType={this.engineType} uuid={this.uuidData} module='mc' />
                        {permissionCheckFnforCamo({
                            primaryKey: "occm_component",
                            keyIndex: "R",
                        }) ? (
                            <div className="custom-border">
                                <MasterSearchBar
                                    currentRecordPerPage={this.state.currentRecordPerPage}
                                    tabIndex={tabIndex}
                                    pageLoader={"pageLoader"}
                                    props={this.props}
                                    uuid={uuid}
                                    basicDetails={basicDetails}
                                    importFile={this.importFile}
                                    getResponseBack={this.refreshComp}
                                    fileUploadData={this.fileUploadData}
                                    searchData={(e) => this.searchResults(e.target.value)}
                                    exportReportFn={(file) =>
                                        this.exportAssetDetailListFunc(
                                            {
                                                download: file.extension,
                                            },
                                            "pageLoader"
                                        )
                                    }
                                />
                                <MasterTable
                                    currentRecordPerPage={this.state.currentRecordPerPage}
                                    heads={MasterTableHead}
                                    sort={sort}
                                    sort_by={sort_by}
                                    bulkDeletefn={()=>this.bulkDelete(this.props,bulkOperation, assetsDetail.pagination.per_page,)}
                                    bulkOperation={bulkOperation}
                                    bulkFlag={assetsDetail?.list?.length === bulkOperation?.ids?.length && assetsDetail?.list?.length > 0}
                                    toggleBulkOps={(flag) => this.toggleBulkOps(flag, 'bulk')}
                                    data={assetsDetail?.list?.map((item, index) => (
                                        <MasterList
                                            bulkOperation={bulkOperation}
                                            toggleBulkOps={(flag) => this.toggleBulkOps(flag, 'single', item)}
                                            currentRecordPerPage={this.state.currentRecordPerPage}
                                            key={index}
                                            item={item}
                                            uuid={uuid}
                                            assetsDetail={assetsDetail}
                                            getResponseBack={this.refreshComp}
                                            last_used_folder_uuid={assetsDetail.new_task_last_used_folder_uuid}
                                            last_used_folder_uuid_link_document={assetsDetail.last_used_folder_uuid_link_document}
                                            checkbox_last_used_folder_uuid={assetsDetail.checkbox_last_used_folder_uuid}
                                            checkbox_last_used_folder_name={assetsDetail.checkbox_last_used_folder_name}
                                            index={index}
                                            handleDeleteRow={(data) =>
                                                this.handleDeleteRow(
                                                    item,
                                                    this.props,
                                                    this.state.currentRecordPerPage
                                                )
                                            }
                                            updateFormData={this.updateFormData}
                                            handleEditRow={(data) =>
                                                this.handleEditRow(item, this.props)
                                            }
                                            props={this.props}
                                            fileUploadData={this.fileUploadData}
                                            downloadAllApi={(file) =>
                                                this.downloadAllFilesApi(
                                                    {
                                                        download: file.extension,
                                                        ...{
                                                            asset_type: tabIndex,
                                                            item,
                                                        },
                                                    },

                                                    "pageLoader"
                                                )
                                            }
                                        />
                                    ))}
                                    createSortHandler={this.createSortHandler}
                                    pagination={assetsDetail.pagination}
                                    onChangePage={(event, newPage) =>
                                        this.getMasterCompApi({
                                            asset_type: this.state.tabIndex,
                                            per_page: assetsDetail.pagination.per_page,
                                            page: newPage + 1,
                                        },
                                            "pageLoader",
                                            this.props
                                        )
                                    }
                                    onChangeRowsPerPage={(event) =>
                                        this.setState(
                                            { currentRecordPerPage: event.target.value },
                                            () => {
                                                this.getMasterCompApi({
                                                    asset_type: this.state.tabIndex,
                                                    per_page: this.state.currentRecordPerPage,

                                                },
                                                    "pageLoader",
                                                    this.props
                                                );
                                            }
                                        )
                                    }
                                    noRecord={
                                        assetsDetail?.list?.length ? null : !this.state.pageLoader ? (
                                            <EmptyCollection title="No Records Found" />
                                        ) : null
                                    }
                                />
                            </div>
                        ) : (
                            <AccessDenied />
                        )}
                    </Grid>
                </Grid>
            </section>
        );
    }
}
export default withSnackbar(MasterListComponent);
