import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, FormControl, InputLabel, MenuItem, Select, FormLabel, FormControlLabel, Radio, Dialog, Tooltip, TextareaAutosize } from "@material-ui/core"
import RadioGroup from "@material-ui/core/RadioGroup"
import CloseIcon from "@material-ui/icons/Close"
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/SbTaskFormData"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import moment from "moment";
import axios from 'axios';
import InfoIcon from '@material-ui/icons/Info';
import MomentUtils from "@date-io/moment";
import { backendDateFormat, dayMonthDateFormat } from "../../../../constants";
import { addSbFormApi, deleteAdAttachment,getApplicabilty } from "../apiService";
import SbConfirmDeleteAttachment from "./SbConfirmDeleteAttachment";
import { regexConstants } from "../../../../constants/regEx";
import { checkApiStatus, getLocalStorageInfo, format_Commas, validationMess } from "../../../../utils";
import LinkFiles from "../../../../shared_elements/components/LinkFiles";
import { globalPutService } from "../../../../utils/globalApiServices";
import UploadFile from "../../../../shared_elements/components/UploadFile";
import config from "../../../../config";
import { trackActivity } from '../../../../utils/mixpanel';
import { assetType, imgStoragePath, specificKey } from "../../../../constants";
class SbTaskForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error: {},
			data: formValues,
			attachments: [],
			newUploadedAttachments: [],
			btnStatus: "",
			open: false,
			deleteAttachmentId: 0,
			csn: this.props.headerData.csn,
			tsn: this.props.headerData.tsn,
			showSuccessUpload: false,
			linkFile: false,
			uploadFile: false,
			applicabilitydropdown: []
		}
		this.addSbFormApi = addSbFormApi.bind(this)
		this.dropDownChange = this.dropDownChange.bind(this)
		this.dropDownChange1 = this.dropDownChange1.bind(this)
		this.dropDownChange2 = this.dropDownChange2.bind(this)
		this.getApplicabilty = getApplicabilty.bind(this)
	}

	componentDidMount = () => {
		this.getApplicabilty(this.props.basicDetails?.engine_type)
		if (this.props.editFormId) {
			this.fillEditData()
		}
		trackActivity('CAMO', {
			event_type: 'Modal Opened',
			page_title: 'Service Bulletins(SB) Add Task Form',
		})
	}
	handleOpenFile = () => {
		this.setState({ linkFile: true })
	};
	handleCloseFile = () => {
		this.setState({ linkFile: false })
	};
	handleOpenUploadFile = () => {
		this.setState({ uploadFile: true })
	};
	handleUploadFileCloseFile = () => {
		this.setState({ uploadFile: false })
	};


	dropDownChange(e, index, value) {
		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				type: e.target.value,
			},
		}))
	}

	dropDownChange1(e, index, value) {
		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				sb_interval_type: e.target.value,
			},
		}))
	}

	dropDownChange2(e, index, value) {
		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				sb_compliance_status: e.target.value,
			},
		}))
	}

	onFieldChange = (keyParam, value, removeComma) => {
		if (removeComma) {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
					[keyParam]: value ? value.replace(",", '') : value,
				},
			}))
		} else {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
					[keyParam]: value,
				},
			}))
		}

	}

	onRestErrorKey = (keyParam) => {
		this.setState((prevState) => ({
			...prevState,
			error: {
				...prevState.error,
				[keyParam]: "",
			},
		}))
	}

	handleSubmit = (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		const props = this.props.props
		const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
		validationInputs = {
			...validationInputs,
			ata_chapter: smbcInstance ? "" : taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
			sb_ref_number: taskErrorCode["sb_ref_number"][fieldValidation({ ...taskErrorCode["sb_ref_number_obj"], fieldval: data.sb_ref_number, })],
			description: smbcInstance ? "" : taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
			revision: smbcInstance ? "" : taskErrorCode["revision"][fieldValidation({ ...taskErrorCode["revision_obj"], fieldval: data.revision, })],
			revision_date: smbcInstance ? "" : taskErrorCode["revision_date"][fieldValidation({ ...taskErrorCode["revision_date_obj"], fieldval: data.revision_date, })],
			last_done_fc: smbcInstance ? "" : taskErrorCode["last_done_fc"][fieldValidation({ ...taskErrorCode["last_done_fc_obj"], fieldval: data.last_done_fc !== null ? String(data.last_done_fc) : "", })],
			last_done_fh: smbcInstance ? "" : taskErrorCode["last_done_fh"][fieldValidation({ ...taskErrorCode["last_done_fh_obj"], fieldval: data.last_done_fh !== null ? String(data.last_done_fh) : "", })],
		}
		if (data.sb_interval_type == "FC") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
			}
		} else if (data.sb_interval_type == "FC,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FH") {
			validationInputs = {
				...validationInputs,
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
			}
		} else if (data.sb_interval_type == "D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FH,D") {
			validationInputs = {
				...validationInputs,
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FC,FH") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
			}
		}
		else if (data.sb_interval_type == "FC,FH,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],

			}
		}
		if (data.sb_compliance_status == "COMPLIED") {
			validationInputs = {
				...validationInputs,
				sb_compliance_tsn: smbcInstance ? "" : taskErrorCode["sb_compliance_tsn"][fieldValidation({ ...taskErrorCode["sb_compliance_tsn_obj"], fieldval: data.sb_compliance_tsn, })],
				sb_compliance_csn: smbcInstance ? "" : taskErrorCode["sb_compliance_csn"][fieldValidation({ ...taskErrorCode["sb_compliance_csn_obj"], fieldval: data.sb_compliance_csn, })],
				sb_compliance_date: smbcInstance ? "" : taskErrorCode["sb_compliance_date"][fieldValidation({ ...taskErrorCode["sb_compliance_date_obj"], fieldval: data.sb_compliance_date, })],
			}
		}
		if (data.sb_compliance_tsn) {
			const { error } = this.state
			validationInputs = {
				...validationInputs,
				sb_compliance_tsn: error?.sb_compliance_tsn || '',
			}
		}
		if (data.sb_compliance_csn) {
			const { error } = this.state
			validationInputs = {
				...validationInputs,
				sb_compliance_csn: error?.sb_compliance_csn || '',
			}
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
			
			let paylaod = Object.assign({}, data)
			paylaod = {
				...paylaod,
				asset_id: props.match.params.asset,
				asset_type_id: props.match.params.assetType,
				last_done_fc: paylaod.last_done_fc ? paylaod.last_done_fc : null,
				last_done_fh: paylaod.last_done_fh ? paylaod.last_done_fh : null,
				sb_compliance_tsn: paylaod.sb_compliance_tsn ? paylaod.sb_compliance_tsn : null,
				sb_compliance_csn: paylaod.sb_compliance_csn ? paylaod.sb_compliance_csn : null,
				sb_applicability: paylaod?.sb_applicability ? paylaod?.sb_applicability?.map(item => item?.id).join(',') : ''
			}
			addSbFormApi(paylaod, props, this.props.addForm).then(async (response) => {
				const uploadId = response.data?.data?.id
				if (uploadId) {
					await this.uploadFileTest(uploadId, props, false)
					trackActivity('CAMO', {
						event_type: 'Task Added Successfully',
						page_title: 'Service Bulletins(SB)',
						item_id: uploadId ? uploadId : '',
						request_body: paylaod ? paylaod : '',
						response_msg: response.data.message ? response.data.message : ''
					})
				}
				if (uploadId && this.state.btnStatus !== "addAnother") {
					this.props.closeAddForm()
				} else {
					return false
				}
			})

		} else {
			const { error } = this.state
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Add TASK Failed',
				page_title: 'Service Bulletins(SB)',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			})
		}
	}

	fillEditData = () => {
		const updateId = this.props.editFormId
		this.setState({ attachments: this.props.attachments })
		if (updateId === undefined || updateId === null) {
			this.setState({
				data: {},
			})
		} else {
			this.setState({
				data: this.props.editFormData,
			})
		}
	}

	updateData = async (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		const props = this.props.props

		const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
		validationInputs = {
			...validationInputs,
			ata_chapter: smbcInstance ? "" : taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter !== null ? String(data.ata_chapter) : "", })],
			sb_ref_number: taskErrorCode["sb_ref_number"][fieldValidation({ ...taskErrorCode["sb_ref_number_obj"], fieldval: data.sb_ref_number !== null ? String(data.sb_ref_number) : "", })],
			description: smbcInstance ? "" : taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description !== null ? String(data.description) : "", })],
			revision: smbcInstance ? "" : taskErrorCode["revision"][fieldValidation({ ...taskErrorCode["revision_obj"], fieldval: data.revision !== null ? String(data.revision) : "", })],
			revision_date: smbcInstance ? "" : taskErrorCode["revision_date"][fieldValidation({ ...taskErrorCode["revision_date_obj"], fieldval: data.revision_date !== null ? String(data.revision_date) : "", })],
			last_done_fc: smbcInstance ? "" : taskErrorCode["last_done_fc"][fieldValidation({ ...taskErrorCode["last_done_fc_obj"], fieldval: data.last_done_fc !== null ? String(data.last_done_fc) : "", })],
			last_done_fh: smbcInstance ? "" : taskErrorCode["last_done_fh"][fieldValidation({ ...taskErrorCode["last_done_fh_obj"], fieldval: data.last_done_fh !== null ? String(data.last_done_fh) : "", })],
		}
		if (data.sb_interval_type == "FC") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
			}
		} else if (data.sb_interval_type == "FC,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FH") {
			validationInputs = {
				...validationInputs,
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
			}
		} else if (data.sb_interval_type == "D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FH,D") {
			validationInputs = {
				...validationInputs,
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.sb_interval_type == "FC,FH") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
			}
		} else if (data.sb_interval_type == "FC,FH,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				sb_interval: smbcInstance ? "" : taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],

			}
		}

		if (data.sb_compliance_status == "COMPLIED") {
			validationInputs = {
				...validationInputs,
				sb_compliance_tsn: smbcInstance ? "" : taskErrorCode["sb_compliance_tsn"][fieldValidation({ ...taskErrorCode["sb_compliance_tsn_obj"], fieldval: data.sb_compliance_tsn, })],
				sb_compliance_csn: smbcInstance ? "" : taskErrorCode["sb_compliance_csn"][fieldValidation({ ...taskErrorCode["sb_compliance_csn_obj"], fieldval: data.sb_compliance_csn, })],
				sb_compliance_date: smbcInstance ? "" : taskErrorCode["sb_compliance_date"][fieldValidation({ ...taskErrorCode["sb_compliance_date_obj"], fieldval: data.sb_compliance_date, })],
			}
		}
		if (data.sb_compliance_tsn) {
			const { error } = this.state
			validationInputs = {
				...validationInputs,
				sb_compliance_tsn: error?.sb_compliance_tsn || '',
			}
		}
		if (data.sb_compliance_csn) {
			const { error } = this.state
			validationInputs = {
				...validationInputs,
				sb_compliance_csn: error?.sb_compliance_csn || '',
			}
		}

		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
			const updateId = this.props.editFormId
			let paylaod = Object.assign({}, data)
			paylaod = {
				...paylaod,
				asset_id: props.match.params.asset,
				asset_type_id: props.match.params.assetType,
				last_done_fc: paylaod.last_done_fc ? paylaod.last_done_fc : null,
				last_done_fh: paylaod.last_done_fh ? paylaod.last_done_fh : null,
				sb_compliance_tsn: paylaod.sb_compliance_tsn ? paylaod.sb_compliance_tsn : null,
				sb_compliance_csn: paylaod.sb_compliance_csn ? paylaod.sb_compliance_csn : null,
				sb_applicability: paylaod?.sb_applicability ? paylaod?.sb_applicability?.map(item => item?.id).join(',') : ''
			}
			await this.uploadFileTest(updateId, props, true)
			await this.props.updateFormData(updateId, paylaod, props, this.props.addForm, this.props.closeAddForm)
			this.setState((prevState) => ({
				error: {
					...prevState.error,
					sb_interval: "",
				},
			}))
			// this.props.closeAddForm()
			trackActivity('CAMO', {
				event_type: 'Task Updated Successfully',
				page_title: 'Service Bulletins(SB)',
				item_id: updateId ? updateId : '',
				request_body: paylaod ? paylaod : ''
			})
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Update TASK Failed',
				page_title: 'Service Bulletins(SB)',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			})
		}
	}

	uploadFileTest = async (uploadId, props, isUpdate) => {
		if (this.state.newUploadedAttachments.length > 0) {
			let newAttachmentObj = []
			let formdata = new FormData()
			const module_type_id = 5
			Object.keys(this.state.newUploadedAttachments).map((key, index) => {
				newAttachmentObj.push({
					file: this.state.newUploadedAttachments[key],
					file_name: this.state.newUploadedAttachments[key].name,
					file_type: this.state.newUploadedAttachments[key].type,
					module_type_id,
					module_id: uploadId,
					asset_id: this.props.props.match.params.asset,
					asset_type_id: this.props.props.match.params.assetType

				})
				formdata.append("[" + key + "]file", this.state.newUploadedAttachments[key])
				formdata.append("[" + key + "]file_type", this.state.newUploadedAttachments[key].type)
				formdata.append("[" + key + "]file_name", this.state.newUploadedAttachments[key].name)
				formdata.append("[" + key + "]module_id", uploadId)
				formdata.append("[" + key + "]module_type_id", module_type_id)
				formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
				formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
			})
			// uploadOccmFile(uploadId, formdata, props)
			this.props.fileUploadData(uploadId, formdata, props, true)
			if (this.state.btnStatus === "addAnother") {
				this.resetBothAttachmentArray()
			}
			if (!isUpdate) {
				this.onSubmitFileLink(uploadId)
				this.props.fileUploadData(uploadId, null, props, false)
			}
		} else {
			if (this.state.btnStatus === "addAnother") {
				this.setState((prevState) => ({
					...prevState,
					data: formValues,
					attachments: [],
				}))
				document.getElementById("asset-form").reset()
				this.props.fileUploadData(uploadId, null, props, false)
			} else {
				if (!isUpdate) {
					this.onSubmitFileLink(uploadId)
					this.props.fileUploadData(uploadId, null, props, false)
				}
			}
		}
	}

	resetBothAttachmentArray = () => {
		this.setState((prevState) => ({
			...prevState,
			data: formValues,
			attachments: [],
			newUploadedAttachments: [],
			open: false,
			deleteAttachmentId: 0,
		}))
		document.getElementById("asset-form").reset()
	}

	onUpload = (files) => {
		const chosenFiles = Array.prototype.slice.call(files)
		for (let i = 0; i < chosenFiles.length; i++) {
			if (chosenFiles[i].size > 52428800) {
				this.props.props.enqueueSnackbar(
					`${chosenFiles[i].name} Maximum file size should be 50MB.`,
					{
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					}
				)
				delete chosenFiles[i]
				continue
			} else {
				this.handleUploadFiles(chosenFiles)
				this.setState({
					showSuccessUpload: true,
				})
				setTimeout(() => {
					if (this.state.attachments) {
						this.setState({
							showSuccessUpload: false,
						})
					}
				}, 2500)
			}
		}
	}

	handleUploadFiles = (files) => {
		const uploaded = [...this.state.newUploadedAttachments]
		const existingAttachments = [...this.state.attachments]
		files.some((file) => {
			uploaded.push(file)
		})
		files.map((file) => {
			var objc = {
				id: 0,
				module_id: 0,
				module_type_id: 5,
				file_name: file.name,
				file_type: file.type,
				file: "",
				name: file.name,
				asset_id: this.props.props.match.params.asset,
				asset_type_id: this.props.props.match.params.assetType
			}
			existingAttachments.push(objc)
		})

		this.setState({
			newUploadedAttachments: uploaded,
			attachments: existingAttachments,
		})
	}
	nextDueValue = () => {
		const { data } = this.state
		if (data.sb_interval_type === "D") {
			data.sb_due = data.dimension_interval_days && data.last_done_date ? moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(dayMonthDateFormat) : ''
		} else if (data.sb_interval_type === "FC") {
			data.sb_due = (data.last_done_fc && data.dimension_interval_fc) ? (+data.last_done_fc + +data.dimension_interval_fc) : ''
		} else if (data.sb_interval_type === "FH") {
			data.sb_due = (data.last_done_fh && data.sb_interval) ? (+data.last_done_fh + +data.sb_interval) : ''
		} else if (data.sb_interval_type === "FC,D") {
			const fcDue = (data.last_done_fc && data.dimension_interval_fc) ? (+data.last_done_fc + +data.dimension_interval_fc) : ''
			const dateDue = data.dimension_interval_days && data.last_done_date ? moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(dayMonthDateFormat) : ''
			data.sb_due = fcDue < moment(dateDue, dayMonthDateFormat) ? fcDue : dateDue || ''
		} else if (data.sb_interval_type === "FH,D") {
			const fhDue = (data.last_done_fh && data.sb_interval) ? (+data.last_done_fh + +data.sb_interval) : ''
			const dateDue = data.dimension_interval_days && data.last_done_date ? moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(dayMonthDateFormat) : ''
			data.sb_due = fhDue < moment(dateDue, dayMonthDateFormat) ? fhDue : dateDue;
		} else if (data.sb_interval_type === "FC,FH,D") {
			const fhDue = (data.last_done_fh && data.sb_interval) ? (+data.last_done_fh + +data.sb_interval) : ''
			const fcDue = (data.last_done_fc && data.dimension_interval_fc) ? (+data.last_done_fc + +data.dimension_interval_fc) : ''
			const dateDue = data.dimension_interval_days && data.last_done_date ? moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(dayMonthDateFormat) : ''

			if (fhDue <= fcDue && fhDue <= moment(dateDue, dayMonthDateFormat)) {
				data.sb_due = fhDue || ''
			} else if (fcDue <= fhDue && fcDue <= moment(dateDue, dayMonthDateFormat)) {
				data.sb_due = fcDue;
			} else {
				data.sb_due = dateDue;
			}
		} else if (data.sb_interval_type === "FC,FH") {
			const fcDue = (data.last_done_fc && data.dimension_interval_fc) ? (+data.last_done_fc + +data.dimension_interval_fc) : ''
			const fhDue = (data.last_done_fh && data.sb_interval) ? (+data.last_done_fh + +data.sb_interval) : ''
			data.sb_due = fcDue < fhDue ? fcDue : fhDue;
		}
		return data.sb_due
	}

	remainingVal = () => {
		const { data } = this.state
		const todayD = moment(new Date())
		let remDay = moment(data.last_done_date).diff(todayD, "days")

		if (data.sb_interval_type === "D") {
			data.remaining_val = data.dimension_interval_days && data.last_done_date ? moment(remDay).add(data.dimension_interval_days) : ''
		} else if (data.sb_interval_type === "FC") {
			data.remaining_val = (data.last_done_fc && data.dimension_interval_fc) ? ((+data.last_done_fc + +data.dimension_interval_fc) - this.props.headerCsn) : ''
		} else if (data.sb_interval_type === "FH") {
			data.remaining_val = (data.last_done_fh && data.sb_interval) ? ((+data.last_done_fh + +data.sb_interval) - this.props.headerTsn) : ''
		} else if (data.sb_interval_type === "FC,D") {
			const fcRem_val = (data.last_done_fc && data.dimension_interval_fc) ? ((+data.last_done_fc + +data.dimension_interval_fc) - this.props.headerCsn) : ''
			const dRem_val = data.dimension_interval_days && data.last_done_date ? moment(remDay).add(data.dimension_interval_days) : ''
			data.remaining_val = fcRem_val < moment(dRem_val, "DD-MM-YYYY") ? fcRem_val : dRem_val;
		} else if (data.sb_interval_type === "FH,D") {
			const fhRem_val = (data.last_done_fh && data.sb_interval) ? ((+data.last_done_fh + +data.sb_interval) - this.props.headerTsn) : ''
			const dRem_val = data.dimension_interval_days && data.last_done_date ? moment(remDay).add(data.dimension_interval_days) : ''
			data.remaining_val = fhRem_val < moment(dRem_val, "DD-MM-YYYY") ? fhRem_val : dRem_val;
		} else if (data.sb_interval_type === "FC,FH,D") {
			const fcRem_val = (data.last_done_fc && data.dimension_interval_fc) ? ((+data.last_done_fc + +data.dimension_interval_fc) - this.props.headerCsn) : ''
			const fhRem_val = (data.last_done_fh && data.sb_interval) ? ((+data.last_done_fh + +data.sb_interval) - this.props.headerTsn) : ''
			const dRem_val = data.dimension_interval_days && data.last_done_date ? moment(remDay).add(data.dimension_interval_days) : ''

			if (fhRem_val <= fcRem_val && fhRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
				data.remaining_val = fhRem_val;
			} else if (fcRem_val <= fhRem_val && fcRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
				data.remaining_val = fcRem_val;
			} else {
				data.remaining_val = dRem_val;
			}
		} else if (data.sb_interval_type === "FC,FH") {
			const fcRem_val = (data.last_done_fc && data.dimension_interval_fc) ? ((+data.last_done_fc + +data.dimension_interval_fc) - this.props.headerCsn) : ''
			const fhRem_val = (data.last_done_fh && data.sb_interval) ? ((+data.last_done_fh + +data.sb_interval) - this.props.headerTsn) : ''
			data.remaining_val = fcRem_val < fhRem_val ? fcRem_val : fhRem_val;
		}
		return data.remaining_val || ''
	}

	handleClickAttachment = (e, id) => {
		this.setState({ open: true })
		this.setState({ deleteAttachmentId: id })
	}

	handleCloseAttachment = (e) => {
		this.setState({ open: false })
		this.setState({ deleteAttachmentId: 0 })
	}

	deleteAttachmentFile = async (id, file) => {
		if (id !== undefined && id != 0) {
			const props = this.props.props
			deleteAdAttachment(id, props)
			var array = [...this.state.attachments]
			array = array.filter((item) => item.id !== id)
			this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
		} else {
			this.onRemoveCamoFile(file)
		}
	}

	removeAttachment = (e, obj) => {
		var array = [...this.state.attachments]
		var newuploadarray = [...this.state.newUploadedAttachments]

		var filteredArray = []

		newuploadarray.map((file) => {
			if (file.name == obj.file_name && file.type == obj.file_type) {
			} else {
				filteredArray.push(file)
			}
		})
		// Set the state with the new filtered array
		array = array.filter((item) => item !== obj)
		this.setState({
			newUploadedAttachments: filteredArray,
			attachments: array,
		})
	}

	saveAndAddAnother = async (e) => {
		await this.setState({ btnStatus: "addAnother" })
		await this.handleSubmit(e)
	}

	addFormData = async (e) => {
		await this.setState({ btnStatus: "add" })
		this.handleSubmit(e)
	}

	getResponseBack = (file) => {
		const { data, } = this.state
		this.setState({
			data: {
				...data,
				checklist: file,
			},
		});
	}
	listResponseBack = (file) => {
		if (this.props.actionType === "add") {
			return null
		} else {
			this.props.getResponseBack(file)
		}
	}
	downloadFile = (file) => {
		axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.name);
				document.body.appendChild(link);
				link.click();
			})
	}
	onUnlinkCheckListFile = (checklist, fileId) => {
		const { data } = this.state;
		let payload = {};
		payload.delete = true;
		payload.folder_ids = checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
		payload.file_ids = checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
		globalPutService(`camo/sb/${this.props.editFormId}/document-checklists/`, payload)
			.then((response) => {
				if (checkApiStatus(response)) {
					const updatedChecklist = { ...checklist };
					updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
					updatedChecklist.folders = updatedChecklist?.folders.filter((file) => file.id !== fileId);
					this.setState({
						data: {
							...data,
							checklist: updatedChecklist,
						},
					});
				}
				trackActivity('CAMO', {
					event_type: 'Checklist File Unlinked',
					page_title: 'Service Bulletins(SB)',
					file_id: fileId ? fileId : '',
					edited_form_id: this.props.editFormId ? this.props.editFormId : '',
					request_body: payload ? payload : ''
				})
			});
	}
	onRemoveFile = (file) => {
		const { data } = this.state;
		this.setState({
			data: {
				...data,
				checklist: data.checklist.filter(item => item.name !== file.name)
			}
		});
	}
	onRemoveCamoFile = (file) => {
		const { attachments } = this.state;
		this.setState({
			attachments: attachments.filter(item => item.file_name !== file.file_name)
		});
	}

	onLinkFile = (file, uuid, type) => {
		if (type == "link" || uuid) {
			const { data } = this.state;
			const folderUUID = [...data.folder_uuid, uuid];
			const updatedChecklist = [...data.checklist, file];

			let uuidData = [];
			let output = [];

			for (const arr of folderUUID) {
				uuidData = uuidData.concat(arr);
			}

			for (const arr of updatedChecklist) {
				output = output.concat(arr);
			}
			this.setState({
				data: {
					...data,
					checklist: output,
					folder_uuid: uuidData.join("")
				},
			});
		} else {
			this.onUpload(file)
		}
	}

	onSubmitFileLink = (id) => {
		const { data } = this.state;
		if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
			let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
			globalPutService(`camo/sb/${id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid?.length > 0 && data.folder_uuid) {
			let formData = new FormData();
			formData.append('folder_uuid', data.folder_uuid);
			for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
				formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
			}
			globalPutService(`camo/sb/${id}/document-checklists/`, formData)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
	}

	render() {
		const { data, error, attachments,applicabilitydropdown } = this.state
		const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
		return (
			<Fragment>
				<div id="AmpTaskForm">
					<div id="HtTaskForm">
						<DialogTitle>
							{this.props.formTitle}
							<CloseIcon
								onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
								className="close-icon"
							/>
						</DialogTitle>
						<form
							id="asset-form"
							onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
						>
							<DialogContent>
								<Grid spacing={1} container>
									<Grid item xs={3}>
										<TextField
											required={!smbcInstance}
											variant="outlined"
											label="ATA Chapter"
											id="ata_chapter"
											value={data.ata_chapter}
											InputLabelProps={{ shrink: true }}
											error={error.ata_chapter ? true : false}
											helperText={error.ata_chapter ? error.ata_chapter : ""}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => { this.onFieldChange('ata_chapter', e.target.value); this.onRestErrorKey("ata_chapter") }} // change as per validation sheet
										// onChange={(e) => { e.target.value.length <= 50 && (regexConstants.numericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('ata_chapter', e.target.value); this.onRestErrorKey("ata_chapter") }}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											required
											label="SB Reference Number"
											id="sb_ref_number"
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 50 }}
											value={data.sb_ref_number}
											error={error.sb_ref_number ? true : false}
											helperText={error.sb_ref_number ? error.sb_ref_number : ""}
											onChange={(e) => { this.onFieldChange("sb_ref_number", e.target.value); this.onRestErrorKey("sb_ref_number") }}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="SB Issued by"
											id="sb_issued_by"
											InputLabelProps={{ shrink: true }}
											value={data.sb_issued_by}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => this.onFieldChange("sb_issued_by", e.target.value)}
										/>
									</Grid>
									<Grid item xs={3}>
										<Autocomplete
											id="applicability"
											options={applicabilitydropdown?.engine_type || []}
											getOptionLabel={(option) => option?.name}
											getOptionSelected={(option, value) => option?.name === value?.name}
											value={data?.sb_applicability || []}
											margin='normal'
											multiple={true}
											disableCloseOnSelect={true}
											onChange={(e, value) => this.onFieldChange("sb_applicability", value)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="SB Applicability"
													margin='normal'
													placeholder="Select SB Applicability"
													variant="outlined"
													fullWidth
													InputLabelProps={{ shrink: true }}
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={12}>
										<TextField
											required={!smbcInstance}
											variant="outlined"
											label="SB Description"
											name="description"
											id="description"
											multiline
											minRows={5}
											value={data.description}
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 250 }}
											error={error.description ? true : false}
											helperText={error.description ? error.description : ""}
											onChange={(e) => this.onFieldChange("description", e.target.value)}
										/>
									</Grid>
								</Grid>
								<div className="border-grey"></div>
								<Grid spacing={1} container>
									<Grid item xs={4}>
										<Grid container id="ht-dropdown">
											<Grid item>
												<FormControl>
													<InputLabel
														id="demo-simple-select-label"
														style={{ padding: "0px 14px" }}
													>
														SB Type
													</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														variant="outlined"
														value={data.type}
														onChange={this.dropDownChange}
													>
														<MenuItem value={"ALERT"}>Alert</MenuItem>
														<MenuItem value={"STANDARD"}>Standard</MenuItem>
														<MenuItem value={"OPTIONAL"}>Optional</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="SB Effectivity"
											name="effectivity"
											id="effectivity"
											InputLabelProps={{ shrink: true }}
											value={data.effectivity}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => this.onFieldChange("effectivity", e.target.value)}
										/>
									</Grid>
									<Grid item xs={4} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="effectivity_date"
												label="SB Effective Date"
												format={dayMonthDateFormat}
												inputVariant="outlined"
												fullWidth
												disableFuture
												onPaste={(event) => event.preventDefault()}
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.effectivity_date}
												error={error.effectivity_date ? true : false}
												helperText={error.effectivity_date ? error.effectivity_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("effectivity_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("effectivity_date")
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={4} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="last_done_date"
												label="Last Done Date"
												format={dayMonthDateFormat}
												inputVariant="outlined"
												fullWidth
												onPaste={(event) => event.preventDefault()}
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.last_done_date}
												error={error.last_done_date ? true : false}
												helperText={error.last_done_date ? error.last_done_date : ""}
												onChange={(data, value) => this.onFieldChange("last_done_date", moment(data).format(backendDateFormat))}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Last Done FH"
											name="last_done_fh"
											id="last_done_fh"
											InputLabelProps={{ shrink: true }}
											value={data.last_done_fh}
											onPaste={(event) => event.preventDefault()}
											error={error.last_done_fh ? true : false}
											helperText={error.last_done_fh ? error.last_done_fh : ""}
											onChange={(e) => {
												const value = e.target.value;
												const numericValue = value.replace(/[^\d.]/g, '');
												const dotCount = (numericValue.match(/\./g) || []).length;
												const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 4 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 3 && regexConstants.numberWithDot.test(numericValue);
												if (isValid || value === "") {
													this.onFieldChange("last_done_fh", numericValue);
												}
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Last Done FC"
											name="last_done_fc"
											id="last_done_fc"
											value={data.last_done_fc}
											onPaste={(event) => event.preventDefault()}
											InputLabelProps={{ shrink: true }}
											error={error.last_done_fc ? true : false}
											helperText={error.last_done_fc ? error.last_done_fc : ""}
											onChange={(e) => e.target.value.length <= 3 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('last_done_fc', e.target.value)}

										/>
									</Grid>
								</Grid>
								<div className="border-grey"></div>
								<Grid id="data-container" spacing={1} container>
									<Grid item xs={3}>
										<label>INTERVAL</label>
										<Grid spacing={2} container id="ht-dropdown">
											<Grid item>
												<FormControl>
													<InputLabel
														id="demo-simple-select-label"
														style={{ padding: "0px 14px" }}
													>
														SB Compliance Period
													</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="sb_interval_type"
														variant="outlined"
														value={data.sb_interval_type ? data.sb_interval_type : ''}
														onChange={this.dropDownChange1}
													>
														<MenuItem value={"D"}>Days</MenuItem>
														<MenuItem value={"FH"}>FH</MenuItem>
														<MenuItem value={"FC"}>FC</MenuItem>
														<MenuItem value={"FC,D"}>FC & D</MenuItem>
														<MenuItem value={"FH,D"}>FH & D</MenuItem>
														<MenuItem value={"FC,FH,D"}>FC,FH & D</MenuItem>
														<MenuItem value={"FC,FH"}>FC & FH</MenuItem>

													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									<Grid id="dimensions-input" item xs={3}>
										<Grid spacing={2} container>
											{data.sb_interval_type == "FC" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH" || data.sb_interval_type == "FC,FH,D" ? <Grid
												item
												xs={data.sb_interval_type == "FC" ? 12 : data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH" ? 6 : data.sb_interval_type == "FC,FH,D" ? 4 : 12}
											>
												<TextField
													variant="outlined"
													label={data.sb_interval_type == "FC" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH,D" || data.sb_interval_type == "FC,FH" ? "FC Interval" : "FC, Interval"}
													name="sb_interval"
													id="sb_interval"
													value={data.dimension_interval_fc ? data.dimension_interval_fc : ''}
													InputLabelProps={{ shrink: true }}
													inputProps={{maxLength:5}}
													onChange={(e) => {( e.target.value ||  e.target.value === "") && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('dimension_interval_fc', e.target.value)}}
													onPaste={(event) =>event.preventDefault()}
													error={error.dimension_interval_fc ? true : false}
													helperText={error.dimension_interval_fc ? error.dimension_interval_fc : ""}
												/>
											</Grid> : null}
											{data.sb_interval_type == "FH" || data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH" || data.sb_interval_type == "FC,FH,D" ? <Grid
												item
												xs={data.sb_interval_type == "FH" ? 12 : data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH" ? 6 : data.sb_interval_type == "FC,FH,D" ? 4 : 12}
											>
												<TextField
													variant="outlined"
													label={data.sb_interval_type == "FH" || data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH,D" || data.sb_interval_type == "FC,FH" ? "FH Interval" : "FH, Interval"}
													name="sb_interval"
													id="sb_interval"
													value={data.sb_interval ? data.sb_interval : '' }
													InputLabelProps={{ shrink: true }}
													inputProps={{maxLength:5}}
													onChange={(e) => {( e.target.value ||  e.target.value === "") && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('sb_interval', e.target.value)}}
													onPaste={(event) =>event.preventDefault()}
													error={error.sb_interval ? true : false}
													helperText={error.sb_interval ? error.sb_interval : ""}
												/>
											</Grid> : null}
											{data.sb_interval_type == "D" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH,D" ||
												data.sb_interval_type == "FH,D" ? (
												<Grid item
													xs={data.sb_interval_type == "D" ? 12 : data.sb_interval_type == "FC,D" || data.sb_interval_type == "FH,D" ? 6 : data.sb_interval_type == "FC,FH,D" ? 4 : 12}
												>
													<TextField
														variant="outlined"
														required={!smbcInstance}
														label="Days"
														name="dimension_interval_days"
														id="dimension_interval_days"
														value={data.dimension_interval_days ? data.dimension_interval_days : ''}
														InputLabelProps={{ shrink: true }}
														inputProps={{maxLength:5}}
														onChange={(e) => {( e.target.value ||  e.target.value === "") && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('dimension_interval_days', e.target.value)}}
														onPaste={(event) =>event.preventDefault()}
														error={error.dimension_interval_days ? true : false}
														helperText={error.dimension_interval_days ? error.dimension_interval_days : ""}
													/>
												</Grid>
											) : null}
										</Grid>
									</Grid>
									<Grid item xs={3}>
										<label>REMAINING</label>
										<Grid spacing={1} container>
											<Grid className="remaining-input" item xs={6}>
												<TextField
													variant="outlined"
													label={data.sb_interval_type === "D" ? "Days" : data.sb_interval_type === "FC" ? "FC" : data.sb_interval_type === "FH" ? "FH" :
														data.sb_interval_type === "FH,D" ? "FH/Days" : data.sb_interval_type === "FC,D" ? "FC/Days" : data.sb_interval_type === "FC,FH,D" ? "FC/FH/Days" : data.sb_interval_type === "FC,FH" ? "FC/FH" : "Days"}
													name="remaining_val"
													id="remaining_val"
													value={this.remainingVal()}
													onChange={(e, value) => this.onFieldChange("remaining_val", e.target.value)}
													inputProps={{ readOnly: true, }}
													disabled
													className="disabled-input"
													InputLabelProps={{ shrink: true }}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={3}>
										<label>NEXT DUE </label>
										<Grid spacing={2} container>
											<Grid className="remaining-input" item xs={6}>
												<TextField
													variant="outlined"
													label={data.sb_interval_type === "D" ? "Date" : data.sb_interval_type === "FC" ? "FC" : data.sb_interval_type === "FH" ? "FH" : data.sb_interval_type === "FH,D" ? "FH/Date" :
														data.sb_interval_type === "FC,D" ? "FC/Date" : data.sb_interval_type === "FC,FH,D" ? "FC/FH/Date" : data.sb_interval_type === "FC,FH" ? "FC/FH " : "Date"}
													name="ad_due"
													id="ad_due"
													InputLabelProps={{ shrink: true }}
													value={this.nextDueValue()}
													onChange={(e, value) => this.onFieldChange("ad_due", e.target.value)}
													inputProps={{ readOnly: true }}
													disabled
													className="disabled-input"
												/>
											</Grid>
										</Grid>
									</Grid>

								</Grid>

								<div className="border-grey"></div>

								<Grid spacing={1} container>
									<Grid item xs={3}>
										<Grid container id="ht-dropdown">
											<Grid item>
												<FormControl>
													<InputLabel
														id="demo-simple-select-label"
														style={{ padding: "0px 14px" }}
													>
														SB Compliance Status{" "}
														<span style={{ color: "red" }}> *</span>
													</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														variant="outlined"
														value={data.sb_compliance_status ? data?.sb_compliance_status?.toUpperCase() : ''}
														onChange={this.dropDownChange2}
													>
														<MenuItem value={"OPEN"}>OPEN</MenuItem>
														<MenuItem value={"COMPLIED"}>COMPLIED</MenuItem>
														<MenuItem value={"FACTORY COMPLIED"}>
															FACTORY COMPLIED
														</MenuItem>
														<MenuItem value={"PARTIALLY COMPLIED"}>
															PARTIALLY COMPLIED
														</MenuItem>
														<MenuItem value={"INFORMATION ONLY"}>
															INFORMATION ONLY
														</MenuItem>
														<MenuItem value={"CANCELLED"}>
														   CANCELLED
														</MenuItem>
														<MenuItem value={"NOT APPLICABLE"}>
															NOT APPLICABLE
														</MenuItem>
														<MenuItem value={"REPEAT INSPECTION"}>
														    REPEAT INSPECTION
														</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={3}>
										<TextField
											required={data.sb_compliance_status !== "COMPLIED" ? false : smbcInstance ? false : true}
											variant="outlined"
											label={
												<span style={{ verticalAlign: 'super' }}>
													SB Compliance TSN
													<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999.99 </span>} arrow>
														<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
													</Tooltip>
												</span>
											}
											name="sb_compliance_tsn"
											id="sb_compliance_tsn"
											value={regexConstants.alphanumespcespclchar.test(data.sb_compliance_tsn) ? data.sb_compliance_tsn : format_Commas(data?.sb_compliance_tsn.replace(/[^0-9.]/g, ''))}
											disabled={data?.sb_compliance_status === "NOT APPLICABLE"}
											error={error.sb_compliance_tsn ? true : false}
											inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.sb_compliance_tsn) && 10 }}
											InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
											helperText={error.sb_compliance_tsn ? error.sb_compliance_tsn : ""}
											onChange={(e) => {
												const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
												const numericValue = value.replace(/[^\d.]/g, '');
												const dotCount = (numericValue.match(/\./g) || []).length;
												const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 7 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
												if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
													this.onFieldChange("sb_compliance_tsn", e.target.value);
													this.setState({ error: { ...error, sb_compliance_tsn: e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) ? validationMess('SB Compliance TSN', true) : '' } })
												} else if (isValid || value === "") {
													this.onFieldChange("sb_compliance_tsn", numericValue);
													this.setState({ error: { ...error, sb_compliance_tsn: regexConstants.numberWithDot.test(numericValue) || e.target.value === (null || undefined || "") ? "" : e.target.value === null ? "" : "" } })
												}
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											required={data.sb_compliance_status !== "COMPLIED" ? false : smbcInstance ? false : true}
											label={
												<span style={{ verticalAlign: 'super' }}>
													SB Compliance CSN
													<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999</span>} arrow>
														<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
													</Tooltip>
												</span>
											}
											name="sb_compliance_csn"
											id="sb_compliance_csn"
											inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.sb_compliance_csn) ? 8 : 6 }}
											InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
											error={error.sb_compliance_csn ? true : false}
											disabled={data?.sb_compliance_status === "NOT APPLICABLE"}
											helperText={error.sb_compliance_csn ? error.sb_compliance_csn : ""}
											value={regexConstants.alphanumespcespclchar.test(data.sb_compliance_csn) ? data.sb_compliance_csn : format_Commas(data?.sb_compliance_csn.toString().replace(/[^0-9.]/g, ''))}
											onChange={(e) => {
												if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
													this.onFieldChange("sb_compliance_csn", e.target.value);
													this.setState({ error: { ...error, sb_compliance_csn: e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess('SB Compliance CSN', false) }, })
												} else {
													(regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && this.onFieldChange('sb_compliance_csn', e.target.value, "remove-comma")
													this.setState({ error: { ...error, sb_compliance_csn: e.target?.value === null || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === "" ? "" : "" } })
												}
											}
											}
										/>
									</Grid>
									<Grid item xs={3} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="sb_compliance_date"
												label={<React.Fragment> SB Compliance Date {smbcInstance ? null : data.sb_compliance_status === "COMPLIED" ? (<span style={{ color: "red" }}> *</span>) : null} </React.Fragment>}
												format={dayMonthDateFormat}
												inputVariant="outlined"
												fullWidth
												disableFuture
												onPaste={(event) => event.preventDefault()}
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.sb_compliance_date}
												error={error.sb_compliance_date ? true : false}
												helperText={error.sb_compliance_date ? error.sb_compliance_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("sb_compliance_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("sb_compliance_date")
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>

								<Grid spacing={1} container>
									{smbcInstance ? <Grid item xs={smbcInstance ? 3 :4}>
										<TextField
											variant="outlined"
											label="Category"
											value={data.sb_category}
											margin="normal"
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => this.onFieldChange("sb_category", e.target.value)}
										/>
									</Grid> :
										<Grid item xs={smbcInstance ? 3 :4}>
											<TextField
												variant="outlined"
												label={<React.Fragment> Service Bulletin <span style={{ color: "red" }}> *</span> </React.Fragment>}
												name="service_bulletin"
												id="service_bulletin"
												value={data.service_bulletin}
												InputLabelProps={{ shrink: true }}
												error={error.service_bulletin ? true : false}
												helperText={error.service_bulletin ? error.service_bulletin : ""}
												onChange={(e, value) => {
													const input = e.target.value
													if (input.length <= 20) {
														this.onFieldChange("service_bulletin", input)
														this.onRestErrorKey("service_bulletin")
													} else {
														const truncatedInput = input.slice(0, 20)
														this.onFieldChange("service_bulletin", truncatedInput)
														this.onRestErrorKey("service_bulletin")
													}
												}}
											/>
										</Grid>
									}
									{smbcInstance &&<Grid item xs={smbcInstance ? 3 :4}>
										<TextField
											variant="outlined"
											label="SB Impact"
											id="sb_impact"
											required={!smbcInstance}
											value={data.sb_impact}
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 250 }}
											onChange={(e) =>this.onFieldChange("sb_impact", e.target.value)}
										/>
									</Grid>}
									<Grid item xs={smbcInstance ? 3 :4}>
										<TextField
											variant="outlined"
											label="Revision"
											id="revision"
											required={!smbcInstance}
											value={data.revision}
											InputLabelProps={{ shrink: true }}
											error={error.revision ? true : false}
											helperText={error.revision ? error.revision : ""}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => { this.onFieldChange("revision", e.target.value); this.onRestErrorKey("revision") }}
										/>
									</Grid>
									<Grid item xs={smbcInstance ? 3 :4} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="revision_date"
												required={!smbcInstance}
												label="Revision Date"
												format={dayMonthDateFormat}
												inputVariant="outlined"
												fullWidth
												disableFuture
												onPaste={(event) => event.preventDefault()}
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.revision_date}
												error={error.revision_date ? true : false}
												helperText={error.revision_date ? error.revision_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("revision_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("revision_date")
												}}

											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="AD"
											name="ad_no"
											id="ad_no"
											value={data.ad_no}
											InputLabelProps={{ shrink: true }}
											error={error.ad_no ? true : false}
											helperText={error.ad_no ? error.ad_no : ""}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => { this.onFieldChange("ad_no", e.target.value); this.onRestErrorKey("ad_no") }}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Subject"
											name="subject"
											id="subject"
											value={data.subject}
											InputLabelProps={{ shrink: true }}
											error={error.subject ? true : false}
											helperText={error.subject ? error.subject : ""}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => { this.onFieldChange("subject", e.target.value); this.onRestErrorKey("subject") }}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label={<React.Fragment>Work Order</React.Fragment>}
											name="workorder"
											id="workorder"
											value={data.workorder}
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => { this.onFieldChange("workorder", e.target.value); this.onRestErrorKey("workorder") }}
											error={error.workorder ? true : false}
											helperText={error.workorder ? error.workorder : ""}
										/>
									</Grid>
								</Grid>
								<div className="border-grey"></div>
								<Grid id="data-container" spacing={3} container>
									<Grid item xs={3}>
										<label>SB Compliance DFP</label>
										<FormControl
											error={error.sb_compliance_dfp ? true : false}
											helperText={
												error.sb_compliance_dfp ? error.sb_compliance_dfp : ""
											}
										>
											<RadioGroup
												required
												row
												aria-label="sb_compliance_dfp"
												name="sb_compliance_dfp"
												defaultValue="top"
												value={data.sb_compliance_dfp}
												onChange={(e, value) => this.onFieldChange("sb_compliance_dfp", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
											{error.sb_compliance_dfp && (
												<FormLabel component="sb_compliance_dfp">
													<span style={{ color: "red" }}>
														{error.sb_compliance_dfp}
													</span>
												</FormLabel>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={3}>
										<label>Release Certificate</label>
										<FormControl component="fieldset">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="release_certificate"
												name="release_certificate"
												defaultValue="top"
												value={data.release_certificate}
												onChange={(e, value) => this.onFieldChange("release_certificate", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>

									<Grid item xs={3}>
										<label>Certificate of Conformity</label>
										<FormControl component="fieldset">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="certificate_of_conformity"
												name="certificate_of_conformity"
												defaultValue="top"
												value={data.certificate_of_conformity}
												onChange={(e, value) => this.onFieldChange("certificate_of_conformity", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
												<FormControlLabel
													value="N/A"
													control={<Radio size="small" color="primary" />}
													label="N/A"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>
								<div className="border-grey"></div>
								<Grid spacing={1} container>
									<Grid item xs={6}>
										<TextField
											label="Remarks"
											margin='normal'
											variant='outlined'
											fullWidth
											value={data.remark}
											InputLabelProps={{ shrink: true }}
											onChange={(e) => this.onFieldChange("remark", e.target.value)}
											rows={3}
											multiline
											inputProps={{ maxLength: 250 }}
										/>
									</Grid>
									<Grid item xs={6}>
										<div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
											<ul className="list-inline spacing-list">
												<li className="list-inline-item" onClick={this.handleOpenUploadFile} >
													<span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
												</li>
												<li className="list-inline-item" onClick={this.handleOpenFile}>
													<span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
												</li>
											</ul>
										</div>
									</Grid>
								</Grid>
								<Grid id="data-container" spacing={1} container>
									<Grid spacing={1} container>
										{this.props.actionType === 'add' ?
											<div className='checklist-files'>
												<ul className='list-inline'>
													{data?.checklist?.map((file) =>
														<li className='list-inline-item'>
															<span className='file-name'>{file.name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
													{attachments?.map((attachments) =>
														<li className='list-inline-item'>
															<span className='file-name'>{attachments.file_name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
												</ul>
											</div>
											:
											<div className='checklist-files'>
												<ul className='list-inline'>
													{data?.checklist?.files?.map((file) =>
														<li className='list-inline-item'>
															<span className='file-name'>{file.name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
													{(data.checklist?.folders?.length > 0) &&
														data.checklist?.folders.map((file) => {
															return (
																<li className='list-inline-item' key={file.id}>
																	<Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
																		<a href={`${config.domain.records}${assetType[this.props.props?.match.params.assetType]}/${file?.asset_slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
																	</Tooltip>
																	<span className='file-name'>{file.name}</span>
																	<span className='file-remove'>
																		<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
																	</span>
																</li>
															);
														})}
													{attachments?.map((attachments) =>
														<li className='list-inline-item'>
															<span className='file-name'>{attachments.file_name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id, attachments)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
												</ul>
											</div>}
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions className="actions-btn">
								<Button type="button" className="btn btn-primary" onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)} >
									{this.props.actionType === "add" ? "Add" : "Update"}
								</Button>
								{this.props.actionType === "add" ? (
									<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
								) : (
									""
								)}
								<Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
							</DialogActions>
							<Dialog
								open={this.state.open}
								onClose={this.handleCloseAttachment}
								id="htDialog"
							>
								<SbConfirmDeleteAttachment
									handleCloseAttachment={() => this.handleCloseAttachment()}
									deleteAttachmentFile={() =>
										this.deleteAttachmentFile(this.state.deleteAttachmentId)
									}
								/>
							</Dialog>
							<Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
								<LinkFiles
									handleClose={() => this.handleCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
									modType="sb"
									last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
									uploadFileInfo={data}
									attachments={attachments}
									type="link"
								/>
							</Dialog>
							<Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
								<UploadFile
									handleClose={() => this.handleUploadFileCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
									modType="sb"
									uploadFileInfo={data}
									last_used_folder_uuid={this.props.last_used_folder_uuid}
									onUpload={(file) => this.onUpload(file)}
									type="upload"
									checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
									checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
								/>
							</Dialog>
						</form>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default SbTaskForm
