import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip } from "@material-ui/core"
import React, { Component, Fragment } from "react"
import CloseIcon from "@material-ui/icons/Close"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import { backendDateFormat, dayMonthDateFormat } from "../../../../constants"
import moment from "moment";
import axios from 'axios';
import InfoIcon from '@material-ui/icons/Info';
import { formValues, taskErrorCode } from "../containers/AdTaskFormData";
import { addAdTaskFormApi, deleteAttachment } from "../apiService";
import { sbNumberDropDown, getApplicabilty } from "../apiService"
import { fieldValidation } from "../../../../utils/formValidation"
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import AdConfirmDeleteAttachment from "./AdConfirmDeleteAttachment";
import { regexConstants } from "../../../../constants/regEx";
import { globalPutService } from "../../../../utils/globalApiServices";
import LinkFiles from "../../../../shared_elements/components/LinkFiles";
import UploadFile from "../../../../shared_elements/components/UploadFile";
import config from "../../../../config";
import { trackActivity } from '../../../../utils/mixpanel';
import { checkApiStatus, getLocalStorageInfo, format_Commas, validationMess } from "../../../../utils";
import { assetType, imgStoragePath, specificKey } from "../../../../constants";

class AdTaskForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error: {},
			data: formValues,
			attachments: [],
			newUploadedAttachments: [],
			ad_due: "",
			btnStatus: "",
			open: false,
			deleteAttachmentId: 0,
			showSuccessUpload: false,
			linkFile: false,
			uploadFile: false,
			sbNumber: [],
			applicabilitydropdown: []
		}
		this.addAdTaskFormApi = addAdTaskFormApi.bind(this)
		this.dropDownChange = this.dropDownChange.bind(this)
		this.dropDownChange1 = this.dropDownChange1.bind(this)
		this.dropDownChange2 = this.dropDownChange2.bind(this)
		this.sbNumberDropDown = sbNumberDropDown.bind(this)
		this.getApplicabilty = getApplicabilty.bind(this)



	}

	componentDidMount = () => {
		this.sbNumberDropDown(this.props)
		this.getApplicabilty(this.props.basicDetails?.engine_type)
		if (this.props.editFormId) {
			this.fillEditData()
		}
		trackActivity('CAMO', {
			event_type: 'Modal Opened',
			page_title: 'Airworthiness Directives(ADs) Add Task Form',
		})

	}
	handleOpenFile = () => {
		this.setState({ linkFile: true })
	};
	handleCloseFile = () => {
		this.setState({ linkFile: false })
	};
	handleOpenUploadFile = () => {
		this.setState({ uploadFile: true })
	};
	handleUploadFileCloseFile = () => {
		this.setState({ uploadFile: false })
	};

	dropDownChange(e, keyParam, value) {
		this.setState(
			(prevState) => (
				{
					...prevState,
					data: {
						...prevState.data,
						ad_compliance_status: e.target.value,
					},
				}
			)
		)
	}
	dropDownChange1(e, keyParam, value) {
		this.setState(
			(prevState) => (
				{
					...prevState,
					data: {
						...prevState.data,
						ad_type: e.target.value,
					},
				}
			)
		)
	}
	dropDownChange2(e, keyParam, value) {
		this.setState(
			(prevState) => (
				{
					...prevState,
					data: {
						...prevState.data,
						dimension_type: e.target.value,
					},
				}
			)
		)
	}

	onFieldChange = (keyParam, value, removeComma) => {
		if (removeComma) {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
					[keyParam]: value ? value.replace(",", '') : value,
				},
			}))
		} else {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
					[keyParam]: value,
				},
			}))
		}
	}

	onRestErrorKey = (keyParam) => {
		this.setState((prevState) => ({
			...prevState,
			error: {
				...prevState.error,
				[keyParam]: "",
			},
		}))
	}
	handleSubmit = (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
		if (!smbcInstance) {
			validationInputs = {
				...validationInputs,
				ata: taskErrorCode["ata"][fieldValidation({ ...taskErrorCode["ata_obj"], fieldval: data.ata, })],
				ad_no: taskErrorCode["ad_no"][fieldValidation({ ...taskErrorCode["ad_no_obj"], fieldval: data.ad_no, })],
				description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
				ad_type: taskErrorCode["ad_type"][fieldValidation({ ...taskErrorCode["ad_type_obj"], fieldval: data.ad_type, })],
				effective_date: taskErrorCode["effective_date"][fieldValidation({ ...taskErrorCode["effective_date_obj"], fieldval: data.effective_date, })],
				type: taskErrorCode["type"][fieldValidation({ ...taskErrorCode["type_obj"], fieldval: data.type, })],
				ac_comp: taskErrorCode["ac_comp"][fieldValidation({ ...taskErrorCode["ac_comp_obj"], fieldval: data.ac_comp, })],
				ad_compliance_status: taskErrorCode["ad_compliance_status"][fieldValidation({ ...taskErrorCode["ad_compliance_status_obj"], fieldval: data.ad_compliance_status, })],
				ref_documents: taskErrorCode["ref_documents"][fieldValidation({ ...taskErrorCode["ref_documents_obj"], fieldval: data.ref_documents, })],
				last_done_date: data.ad_compliance_status === "NOT APPLICABLE" ? null : taskErrorCode["last_done_date"][fieldValidation({ ...taskErrorCode["last_done_date_obj"], fieldval: data.last_done_date, })],
				ad_compliance_dfp: taskErrorCode["ad_compliance_dfp"][fieldValidation({ ...taskErrorCode["ad_compliance_dfp_obj"], fieldval: data.ad_compliance_dfp, })],
				workorder: data.ad_compliance_status === "NOT APPLICABLE" ? null : taskErrorCode["workorder"][fieldValidation({ ...taskErrorCode["workorder_obj"], fieldval: data.workorder, })],
			}
		}
		if (data.dimension_type == "FC") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
			}
		} else if (data.dimension_type == "FC,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type == "FH") {
			validationInputs = {
				...validationInputs,
				dimension_interval: smbcInstance ? "" : taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
			}
		} else if (data.dimension_type == "D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type == "FH,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval: smbcInstance ? "" : taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type == "FC,FH") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval: smbcInstance ? "" : taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
			}
		}
		else if (data.dimension_type == "FC,FH,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval: smbcInstance ? "" : taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],

			}
		}
		if (!smbcInstance && data.ad_compliance_status == "COMPLIED") {
			validationInputs = {
				...validationInputs,
				ad_compliance_tsn: taskErrorCode["ad_compliance_tsn"][fieldValidation({ ...taskErrorCode["ad_compliance_tsn_obj"], fieldval: data.ad_compliance_tsn, })],
				ad_compliance_csn: taskErrorCode["ad_compliance_csn"][fieldValidation({ ...taskErrorCode["ad_compliance_csn_obj"], fieldval: data.ad_compliance_csn, })],
				ad_compliance_date: taskErrorCode["ad_compliance_date"][fieldValidation({ ...taskErrorCode["ad_compliance_date_obj"], fieldval: data.ad_compliance_date, })],
			}
		}
		if (data.ad_compliance_tsn) {
			const { error } = this.state
			validationInputs = {
				...validationInputs,
				ad_compliance_tsn: error?.ad_compliance_tsn || '',
			}
		}
		if (data.ad_compliance_csn) {
			const { error } = this.state
			validationInputs = {
				...validationInputs,
				ad_compliance_csn: error?.ad_compliance_csn || '',
			}
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
			const props = this.props.props
			let payload = Object.assign({}, data)
			payload = {
				...payload,
				asset_id: props.match.params.asset,
				asset_type_id: props.match.params.assetType,
				due_date: payload.dimension_type === ('' || null) ? payload.due_date : null,
				ad_compliance_tsn: payload?.ad_compliance_tsn ? payload?.ad_compliance_tsn : null,
				ad_compliance_csn: payload?.ad_compliance_csn ? payload?.ad_compliance_csn : null,
				last_done_fc: payload?.last_done_fc ? payload?.last_done_fc : null,
				last_done_fh: payload?.last_done_fh ? payload?.last_done_fh : null,
				dimension_interval: payload?.dimension_interval ? payload?.dimension_interval : null,
				dimension_interval_days: payload?.dimension_interval_days ? payload?.dimension_interval_days : null,
				dimension_interval_fc: payload?.dimension_interval_fc ? payload?.dimension_interval_fc : null,
				applicability: payload?.applicability ? payload?.applicability?.map(item => item?.id).join(',') : ''

			}
			addAdTaskFormApi(payload, props, this.props.addForm).then(async (response) => {
				const uploadId = response.data.data.id
				if (uploadId) {

					await this.uploadFileTest(uploadId, props, false)
					trackActivity('CAMO', {
						event_type: 'Task Added Successfully',
						page_title: 'Airworthiness Directives(ADs)',
						item_id: uploadId ? uploadId : '',
						request_body: payload ? payload : '',
						response_msg: response.data.message ? response.data.message : ''
					})
				}
				if (uploadId && this.state.btnStatus !== "addAnother") {
					this.props.closeAddForm()
				} else {
					return false
				}
			})
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Add TASK Failed',
				page_title: 'Airworthiness Directives(ADs)',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			})
		}
	}

	fillEditData = () => {
		const updateId = this.props.editFormId
		this.setState({ attachments: this.props.attachments })
		if (updateId === undefined || updateId === null) {
			this.setState({
				data: {},
			})
		} else {
			this.setState({
				data: this.props.editFormData,
			})
		}
	}

	updateData = async (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
		if (!smbcInstance) {
			validationInputs = {
				...validationInputs,
				ata: taskErrorCode["ata"][fieldValidation({ ...taskErrorCode["ata_obj"], fieldval: data.ata, })],
				ad_no: taskErrorCode["ad_no"][fieldValidation({ ...taskErrorCode["ad_no_obj"], fieldval: data.ad_no, })],
				description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
				ad_type: taskErrorCode["ad_type"][fieldValidation({ ...taskErrorCode["ad_type_obj"], fieldval: data.ad_type, })],
				effective_date: taskErrorCode["effective_date"][fieldValidation({ ...taskErrorCode["effective_date_obj"], fieldval: data.effective_date, })],
				type: taskErrorCode["type"][fieldValidation({ ...taskErrorCode["type_obj"], fieldval: data.type, })],
				ac_comp: taskErrorCode["ac_comp"][fieldValidation({ ...taskErrorCode["ac_comp_obj"], fieldval: data.ac_comp, })],
				ad_compliance_status: data.ad_compliance_status === "NOT APPLICABLE" ? '' : taskErrorCode["ad_compliance_status"][fieldValidation({ ...taskErrorCode["ad_compliance_status_obj"], fieldval: data.ad_compliance_status, })],
				ref_documents: taskErrorCode["ref_documents"][fieldValidation({ ...taskErrorCode["ref_documents_obj"], fieldval: data.ref_documents, })],
				last_done_date: taskErrorCode["last_done_date"][fieldValidation({ ...taskErrorCode["last_done_date_obj"], fieldval: data.last_done_date, })],
				ad_compliance_dfp: taskErrorCode["ad_compliance_dfp"][fieldValidation({ ...taskErrorCode["ad_compliance_dfp_obj"], fieldval: data.ad_compliance_dfp, })],
				workorder: data.ad_compliance_status === "NOT APPLICABLE" ? '' : taskErrorCode["workorder"][fieldValidation({ ...taskErrorCode["workorder_obj"], fieldval: data.workorder, })],
			}
		}
		if (data.dimension_type == "FC") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
			}
		} else if (data.dimension_type == "FC,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type == "FH") {
			validationInputs = {
				...validationInputs,
				dimension_interval: smbcInstance ? "" : taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
			}
		} else if (data.dimension_type == "D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type == "FH,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval: smbcInstance ? "" : taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type == "FC,FH") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval: smbcInstance ? "" : taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
			}
		}
		else if (data.dimension_type == "FC,FH,D") {
			validationInputs = {
				...validationInputs,
				dimension_interval_fc: smbcInstance ? "" : taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval: smbcInstance ? "" : taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
				dimension_interval_days: smbcInstance ? "" : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],

			}
		}
		if (!smbcInstance && data.ad_compliance_status == "COMPLIED") {
			validationInputs = {
				...validationInputs,
				ad_compliance_tsn: taskErrorCode["ad_compliance_tsn"][fieldValidation({ ...taskErrorCode["ad_compliance_tsn_obj"], fieldval: data.ad_compliance_tsn, })],
				ad_compliance_csn: taskErrorCode["ad_compliance_csn"][fieldValidation({ ...taskErrorCode["ad_compliance_csn_obj"], fieldval: data.ad_compliance_csn, })],
				ad_compliance_date: taskErrorCode["ad_compliance_date"][fieldValidation({ ...taskErrorCode["ad_compliance_date_obj"], fieldval: data.ad_compliance_date, })],
			}
		}

		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
			const props = this.props.props
			let payload = Object.assign({}, data)
			payload = {
				...payload,
				due_date: payload.dimension_type === ('' || null) ? payload.due_date : null,
				ad_compliance_tsn: payload?.ad_compliance_tsn ? payload?.ad_compliance_tsn : null,
				ad_compliance_csn: payload?.ad_compliance_csn ? payload?.ad_compliance_csn : null,
				last_done_fc: payload?.last_done_fc ? payload?.last_done_fc : null,
				last_done_fh: payload?.last_done_fh ? payload?.last_done_fh : null,
				dimension_interval: payload?.dimension_interval ? payload?.dimension_interval : null,
				dimension_interval_days: payload?.dimension_interval_days ? payload?.dimension_interval_days : null,
				dimension_interval_fc: payload?.dimension_interval_fc ? payload?.dimension_interval_fc : null,
				applicability: payload?.applicability ? payload?.applicability?.map(item => item?.id).join(',') : ''
			}
			const updateId = this.props.editFormId

			if (updateId) {
				this.uploadFileTest(updateId, props, true)
			}
			this.props.updateFormData(updateId, payload, props, this.props.addForm, this.props.closeAddForm)
			// this.props.closeAddForm()
			trackActivity('CAMO', {
				event_type: 'Task Updated Successfully',
				page_title: 'Airworthiness Directives(ADs)',
				item_id: updateId ? updateId : '',
				request_body: payload ? payload : ''
			})
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Update TASK Failed',
				page_title: 'Airworthiness Directives(ADs)',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			})
		}
	}
	resetBothAttachmentArray = () => {
		this.setState((prevState) => ({
			...prevState,
			data: formValues,
			attachments: [],
			newUploadedAttachments: [],
			open: false,
			deleteAttachmentId: 0,
		}))
		document.getElementById("asset-form").reset()
	}

	nextDueValue = () => {
		const { data } = this.state
		if (data.dimension_type === "D") {
			data.ad_due = data.dimension_interval_days && data.last_done_date ? moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(dayMonthDateFormat) : ''
		} else if (data.dimension_type === "FC") {
			data.ad_due = (data.last_done_fc && data.dimension_interval_fc) ? (+data.last_done_fc + +data.dimension_interval_fc) : ''
		} else if (data.dimension_type === "FH") {
			data.ad_due = (data.last_done_fh && data.dimension_interval) ? (+data.last_done_fh + +data.dimension_interval) : ''
		} else if (data.dimension_type === "FC,D") {
			const fcDue = (data.last_done_fc && data.dimension_interval_fc) ? (+data.last_done_fc + +data.dimension_interval_fc) : ''
			const dateDue = data.dimension_interval_days && data.last_done_date ? moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(dayMonthDateFormat) : ''
			data.ad_due = fcDue < moment(dateDue, dayMonthDateFormat) ? fcDue : dateDue;
		} else if (data.dimension_type === "FH,D") {
			const fhDue = (data.last_done_fh && data.dimension_interval) ? (+data.last_done_fh + +data.dimension_interval) : ''
			const dateDue = data.dimension_interval_days && data.last_done_date ? moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(dayMonthDateFormat) : ''
			data.ad_due = fhDue < moment(dateDue, dayMonthDateFormat) ? fhDue : dateDue;
		} else if (data.dimension_type === "FC,FH,D") {
			const fhDue = (data.last_done_fh && data.dimension_interval) ? (+data.last_done_fh + +data.dimension_interval) : ''
			const fcDue = (data.last_done_fc && data.dimension_interval_fc) ? (+data.last_done_fc + +data.dimension_interval_fc) : ''
			const dateDue = data.dimension_interval_days && data.last_done_date ? moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(dayMonthDateFormat) : ''

			if (fhDue <= fcDue && fhDue <= moment(dateDue, dayMonthDateFormat)) {
				data.ad_due = fhDue;
			} else if (fcDue <= fhDue && fcDue <= moment(dateDue, dayMonthDateFormat)) {
				data.ad_due = fcDue;
			} else {
				data.ad_due = dateDue;
			}
		} else if (data.dimension_type === "FC,FH") {
			const fcDue = (data.last_done_fc && data.dimension_interval_fc) ? (+data.last_done_fc + +data.dimension_interval_fc) : ''
			const fhDue = (data.last_done_fh && data.dimension_interval) ? (+data.last_done_fh + +data.dimension_interval) : ''
			data.ad_due = fcDue < fhDue ? fcDue : fhDue;
		}
		return data.ad_due
	}

	remainingVal = () => {
		const { data } = this.state
		const todayD = moment(new Date())
		let remDay = moment(data.last_done_date).diff(todayD, "days")

		if (data.dimension_type === '') {
			data.remaining_val = ''
		}
		if (data.dimension_type === "D") {
			data.remaining_val = data.dimension_interval_days && data.last_done_date ? moment(remDay).add(data.dimension_interval_days) : ''
		} else if (data.dimension_type === "FC") {
			data.remaining_val = (data.last_done_fc && data.dimension_interval_fc) ? ((+data.last_done_fc + +data.dimension_interval_fc) - this.props.headerCsn) : ''
		} else if (data.dimension_type === "FH") {
			data.remaining_val = (data.last_done_fh && data.dimension_interval) ? ((+data.last_done_fh + +data.dimension_interval) - this.props.headerTsn) : ''
		} else if (data.dimension_type === "FC,D") {
			const fcRem_val = (data.last_done_fc && data.dimension_interval_fc) ? ((+data.last_done_fc + +data.dimension_interval_fc) - this.props.headerCsn) : ''
			const dRem_val = data.dimension_interval_days && data.last_done_date ? moment(remDay).add(data.dimension_interval_days) : ''
			data.remaining_val = fcRem_val < moment(dRem_val, "DD-MM-YYYY") ? fcRem_val : dRem_val;
		} else if (data.dimension_type === "FH,D") {
			const fhRem_val = (data.last_done_fh && data.dimension_interval) ? ((+data.last_done_fh + +data.dimension_interval) - this.props.headerTsn) : ''
			const dRem_val = data.dimension_interval_days && data.last_done_date ? moment(remDay).add(data.dimension_interval_days) : ''
			data.remaining_val = fhRem_val < moment(dRem_val, "DD-MM-YYYY") ? fhRem_val : dRem_val;
		} else if (data.dimension_type === "FC,FH,D") {
			const fcRem_val = (data.last_done_fc && data.dimension_interval_fc) ? ((+data.last_done_fc + +data.dimension_interval_fc) - this.props.headerCsn) : ''
			const fhRem_val = (data.last_done_fh && data.dimension_interval) ? ((+data.last_done_fh + +data.dimension_interval) - this.props.headerTsn) : ''
			const dRem_val = data.dimension_interval_days && data.last_done_date ? moment(remDay).add(data.dimension_interval_days) : ''
			if (fhRem_val <= fcRem_val && fhRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
				data.remaining_val = fhRem_val;
			} else if (fcRem_val <= fhRem_val && fcRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
				data.remaining_val = fcRem_val;
			} else {
				data.remaining_val = dRem_val;
			}
		} else if (data.dimension_type === "FC,FH") {
			const fcRem_val = (data.last_done_fc && data.dimension_interval_fc) ? ((+data.last_done_fc + +data.dimension_interval_fc) - this.props.headerCsn) : ''
			const fhRem_val = (data.last_done_fh && data.dimension_interval) ? ((+data.last_done_fh + +data.dimension_interval) - this.props.headerTsn) : ''
			data.remaining_val = fcRem_val < fhRem_val ? fcRem_val : fhRem_val;
		}
		return data.remaining_val || ''
	}

	handleClickAttachment = (e, id) => {
		this.setState({ open: true })
		this.setState({ deleteAttachmentId: id })
	}

	handleCloseAttachment = (e) => {
		this.setState({ open: false })
		this.setState({ deleteAttachmentId: 0 })
	}


	removeAttachment = (e, obj) => {
		var array = [...this.state.attachments]
		var newuploadarray = [...this.state.newUploadedAttachments]

		var filteredArray = []

		newuploadarray.map((file) => {
			if (file.name == obj.file_name && file.type == obj.file_type) {
			} else {
				filteredArray.push(file)
			}
		})
		// Set the state with the new filtered array
		array = array.filter((item) => item !== obj)
		this.setState({
			newUploadedAttachments: filteredArray,
			attachments: array,
		})
	}

	saveAndAddAnother = async (e) => {
		await this.setState({ btnStatus: "addAnother" })
		await this.handleSubmit(e)
	}

	addFormData = async (e) => {
		await this.setState({ btnStatus: "add" })
		this.handleSubmit(e)
	}
	downloadFile = (file) => {
		axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.name);
				document.body.appendChild(link);
				link.click();
			})
	}

	getResponseBack = (file) => {
		const { data, } = this.state
		this.setState({
			data: {
				...data,
				checklist: file,
			},
		});
	}
	listResponseBack = (file) => {
		if (this.props.actionType === "add") {
			return null
		} else {
			this.props.getResponseBack(file)
		}


	}
	onUnlinkCheckListFile = (checklist, fileId) => {
		const { data } = this.state;
		let payload = {};
		payload.delete = true;
		payload.folder_ids = checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
		payload.file_ids = checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
		globalPutService(`camo/aird/${this.props.editFormId}/document-checklists/`, payload)
			.then((response) => {
				if (checkApiStatus(response)) {
					const updatedChecklist = { ...checklist };
					updatedChecklist.files = updatedChecklist?.files.filter((file) => file.id !== fileId);
					updatedChecklist.folders = updatedChecklist?.folders.filter((file) => file.id !== fileId);
					this.setState({
						data: {
							...data,
							checklist: updatedChecklist,
						},
					});
				}
				trackActivity('CAMO', {
					event_type: 'Checklist File Unlinked',
					page_title: 'Airworthiness Directives(ADs)',
					file_id: fileId ? fileId : '',
					edited_form_id: this.props.editFormId ? this.props.editFormId : '',
					request_body: payload ? payload : ''
				})
			});
	}
	uploadFileTest = async (uploadId, props, isUpdate) => {
		const { data } = this.state
		if (data.attachments) {
			var formData = new FormData();
			Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
				formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
				formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
				formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
				formData.append("[" + key + "]module_id", uploadId)
				formData.append("[" + key + "]module_type_id", 4)
				formData.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
				formData.append("[" + key + "]asset_id", this.props.props.match.params.asset)
			})
			this.props.fileUploadData(uploadId, formData, props, true)
			if (this.state.btnStatus === "addAnother") {
				this.resetBothAttachmentArray()
			}
			if (!isUpdate) {
				this.onSubmitFileLink(uploadId)
				// this.props.fileUploadData(uploadId, null, props, false)
			}
		} else {
			if (this.state.btnStatus === "addAnother") {
				this.setState((prevState) => ({
					...prevState,
					data: formValues,
					attachments: [],
				}))
				document.getElementById("asset-form").reset()
				this.props.fileUploadData(uploadId, null, props, false)
			} else {
				if (!isUpdate) {
					this.onSubmitFileLink(uploadId)
					this.props.fileUploadData(uploadId, null, props, false)
				}
			}
		}
	}
	onUpload = (files) => {
		const chosenFiles = Array.prototype.slice.call(files)
		for (let i = 0; i < chosenFiles.length; i++) {
			if (chosenFiles[i].size > 52428800) {
				this.props.props.enqueueSnackbar(`${chosenFiles[i].name} Maximum file size should be 50MB.`, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } })
				delete chosenFiles[i]
				continue
			} else {
				this.handleUploadFiles(chosenFiles)
			}
		}
	}
	handleUploadFiles = (files) => {
		const { data } = this.state
		if (Array.isArray(files)) {
			const updatedAttachments = [...(data?.attachments || []), ...files];
			this.setState({
				data: {
					...data,
					attachments: updatedAttachments,
					checklist: { ...data?.checklist }
				}
			})
		} else {
			console.error("Value for attachments is not an array:", files);
		}
	}

	deleteAttachmentFile = async (id, file) => {
		const { data } = this.state
		if (id !== undefined && id != 0) {
			deleteAttachment(id, this.props.props)
			this.setState({
				data: {
					attachments: data.attachments.filter(item => item.name !== file.name),
					checklist: { ...data?.checklist }
				}
			})

		} else {
			this.onRemoveCamoFile(file)
		}
	}


	onRemoveFile = (file) => {
		const { data } = this.state;
		this.setState({
			data: {
				...data,
				checklist: Array.isArray(data?.checklist) ? data.checklist.filter(item => item.name !== file.name) : data.checklist.filter(item => item.name !== file.name),
				//  attachments:{...data?.attachments}
			}
		});

	}

	onRemoveCamoFile = (file) => {
		const { data } = this.state;
		this.setState({
			data: {
				...data,
				attachments: data.attachments.filter(item => item.name !== file.name),
				checklist: Array.isArray(data?.checklist) ? data?.checklist : { ...data?.checklist }
			}

		});

	}

	onLinkFile = (file, uuid, type) => {
		if (type == "link" || uuid) {
			const { data } = this.state;
			const updatedChecklist = data?.checklist?.length > 0 && data?.checklist ? [...data.checklist, file] : file;
			// const folderUUID = [...data?.folder_uuid, uuid];
			const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;


			let storeChecklistData = [];
			let folderUuid = [];

			for (const arr of folderUUID) {
				folderUuid = folderUuid.concat(arr);
			}

			for (const arr of updatedChecklist) {
				storeChecklistData = storeChecklistData.concat(arr);
			}
			this.setState({
				data: {
					...data,
					checklist: storeChecklistData,
					folder_uuid: folderUuid.join("")
				},
			});
		} else {
			this.onUpload(file)
		}

	}


	onSubmitFileLink = (id) => {
		const { data } = this.state;
		if (data.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
			let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
			globalPutService(`camo/aird/${id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid?.length > 0 && data.folder_uuid) {
			let formData = new FormData();
			formData.append('folder_uuid', data.folder_uuid);
			for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
				formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
			}
			globalPutService(`camo/aird/${id}/document-checklists/`, formData)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
	}
	render() {
		const { data, error, attachments, sbNumber, applicabilitydropdown } = this.state
		const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
	
		return (
			<Fragment>
				<div id="AmpTaskForm">
					<div id="adTaskForm">
						<DialogTitle>
							{this.props.formTitle}
							<CloseIcon
								onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
								className="close-icon"
							/>
						</DialogTitle>
						<form
							id="asset-form"
							onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
						>
							<DialogContent>
								<Grid spacing={2} container>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											required={!smbcInstance}
											label="ATA Chapter"
											name="ata"
											inputProps={{ maxLength: 25 }}
											error={error.ata ? true : false}
											helperText={error.ata ? error.ata : ""}
											value={data.ata ? data.ata : ''}
											InputLabelProps={{ shrink: true }}
											onChange={(e) => { this.onFieldChange('ata', e.target.value); this.onRestErrorKey("ata") }} // change as per validation sheet
										// onChange={(e) => { e.target.value.length <= 25 && (regexConstants.numericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('ata', e.target.value); this.onRestErrorKey("ata") }}
										/>
									</Grid>
									{smbcInstance ? <>
										<Grid item xs={3}>
											<TextField
												variant="outlined"
												label="FAA AD"
												name="faa_ad"
												value={data.faa_ad}
												InputLabelProps={{ shrink: true }}
												inputProps={{ maxLength: 50 }}
												onChange={(e, value) => this.onFieldChange("faa_ad", e.target.value)}
											/>
										</Grid>
										<Grid item xs={3}>
											<TextField
												variant="outlined"
												label="EASA AD"
												name="easa_ad"
												value={data.easa_ad}
												InputLabelProps={{ shrink: true }}
												inputProps={{ maxLength: 50 }}
												onChange={(e, value) => this.onFieldChange("easa_ad", e.target.value)}
											/>
										</Grid>
									</>
										:
										<Grid item xs={3}>
											<TextField
												variant="outlined"
												required={!smbcInstance}
												label="AD Reference Number"
												name="ad_no"
												error={error.ad_no ? true : false}
												helperText={error.ad_no ? error.ad_no : ""}
												value={data.ad_no ? data.ad_no : ''}
												InputLabelProps={{ shrink: true }}
												inputProps={{ maxLength: 50 }}
												onChange={(e, value) => { this.onFieldChange("ad_no", e.target.value); this.onRestErrorKey("ad_no") }}
											/>
										</Grid>}
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="Issuing Authority"
											name="issuing_authority"
											id="issuing_authority"
											value={data.issuing_authority}
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 50 }}
											onChange={(e, value) => this.onFieldChange("issuing_authority", e.target.value)}
										/>
									</Grid>
									{smbcInstance ? null : <Grid item xs={3}>
										<Autocomplete
											id="applicability"
											options={applicabilitydropdown?.engine_type || []}
											getOptionLabel={(option) => option?.name}
											getOptionSelected={(option, value) => option?.name === value?.name}
											value={data?.applicability || []}
											margin='normal'
											multiple={true}
											disableCloseOnSelect={true}
											onChange={(e, value) => this.onFieldChange("applicability", value)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Applicability"
													margin='normal'
													placeholder="Select Applicability"
													variant="outlined"
													fullWidth
													InputLabelProps={{ shrink: true }}
												/>
											)}
										/>
									</Grid>}
								</Grid>
								{smbcInstance && <Grid spacing={2} container>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="FAA Superseded"
											name="faa_superseded"
											value={data.faa_superseded}
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 50 }}
											onChange={(e, value) => this.onFieldChange("faa_superseded", e.target.value)}

										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="EASA Superseded"
											name="easa_superseded"
											value={data.easa_superseded}
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 50 }}
											onChange={(e, value) => this.onFieldChange("easa_superseded", e.target.value)}

										/>
									</Grid>
									<Grid item xs={3}>
										<Autocomplete
											id="applicability"
											options={applicabilitydropdown?.engine_type || []}
											getOptionLabel={(option) => option?.name}
											getOptionSelected={(option, value) => option?.name === value?.name}
											value={data?.applicability || []}
											margin='normal'
											multiple={true}
											disableCloseOnSelect={true}
											onChange={(e, value) => this.onFieldChange("applicability", value)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Applicability"
													margin='normal'
													placeholder="Select Applicability"
													variant="outlined"
													fullWidth
													InputLabelProps={{ shrink: true }}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="Related Document"
											name="related_document"
											value={data.related_document}
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 250 }}
											onChange={(e, value) => this.onFieldChange("related_document", e.target.value)}

										/>
									</Grid>
								</Grid>}
								<Grid spacing={1} container>
									<Grid item xs={12}>
										<TextField
											multiline
											minRows={5}
											variant="outlined"
											required={!smbcInstance}
											label="Description"
											name="description"
											InputLabelProps={{ shrink: true }}
											error={error.description ? true : false}
											helperText={error.description ? error.description : ""}
											value={data.description}
											onChange={(e, value) => this.onFieldChange("description", e.target.value)}
											inputProps={{ maxLength: 250 }}
										/>
									</Grid>
								</Grid>
								<div className="border-grey"></div>
								<Grid spacing={1} container id="ht-dropdown">
									<Grid item xs={4}>
										<FormControl>
											<InputLabel
												id="demo-simple-select-label"
												className="dropdown-label"
											>
												AD Type {smbcInstance ? null : <span style={{ color: "red" }}> *</span>}
											</InputLabel>
											<Select
												labelId="ad_type"
												name="ad_type"
												id="ad_type"
												error={error.ad_type ? true : false}
												helperText={error.ad_type ? error.ad_type : ""}
												variant="outlined"
												value={data.ad_type}
												onChange={this.dropDownChange1}
											>
												{this.props.props.match.params.assetType == 1 && <MenuItem value={"AIRFRAME"}>Airframe</MenuItem>}
												<MenuItem value={"ENGINE"}>Engine</MenuItem>
												{this.props.props.match.params.assetType == 1 && <MenuItem value={"APPLIANCE"}>Appliance</MenuItem>}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Effectivity"
											name="effectivity"
											value={data.effectivity}
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 500 }}
											onChange={(e) => this.onFieldChange("effectivity", e.target.value)}
										/>
									</Grid>
									{smbcInstance ?
										<>
											<Grid item xs={2} className="input-calender">
												<MuiPickersUtilsProvider utils={MomentUtils}>
													<KeyboardDatePicker
														required={!smbcInstance}
														label="FAA Effective Date"
														format={dayMonthDateFormat}
														inputVariant="outlined"
														fullWidth
														disableFuture
														onPaste={(event) => event.preventDefault()}
														InputLabelProps={{ shrink: true }}
														minDate={moment().subtract(25, "years")}
														maxDate={moment().add(2, "years")}
														error={error.faa_effective_date ? true : false}
														helperText={error.faa_effective_date ? error.faa_effective_date : ""}
														value={data.faa_effective_date}
														onChange={(data, value) => { this.onFieldChange("faa_effective_date", moment(data).format(backendDateFormat)) }}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
											<Grid item xs={2} className="input-calender">
												<MuiPickersUtilsProvider utils={MomentUtils}>
													<KeyboardDatePicker
														required={!smbcInstance}
														label="EASA Effective Date"
														format={dayMonthDateFormat}
														inputVariant="outlined"
														fullWidth
														disableFuture
														onPaste={(event) => event.preventDefault()}
														InputLabelProps={{ shrink: true }}
														minDate={moment().subtract(25, "years")}
														maxDate={moment().add(2, "years")}
														error={error.easa_effective_date ? true : false}
														helperText={error.easa_effective_date ? error.easa_effective_date : ""}
														value={data.easa_effective_date}
														onChange={(data, value) => { this.onFieldChange("easa_effective_date", moment(data).format(backendDateFormat)) }}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
										</>
										:
										<Grid item xs={4} className="input-calender">
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<KeyboardDatePicker
													required={!smbcInstance}
													label="Effective Date"
													id="effective_date"
													format={dayMonthDateFormat}
													inputVariant="outlined"
													fullWidth
													onPaste={(event) => event.preventDefault()}
													disableFuture
													InputLabelProps={{ shrink: true }}
													minDate={moment().subtract(25, "years")}
													error={error.effective_date ? true : false}
													helperText={error.effective_date ? error.effective_date : ""}
													value={data.effective_date}
													onChange={(data, value) => {
														this.onFieldChange("effective_date", moment(data).format(backendDateFormat))
														this.onRestErrorKey("effective_date")
													}}
												/>
											</MuiPickersUtilsProvider>
										</Grid>}
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={3}>
										<Autocomplete
											id="sb_number"
											options={sbNumber}
											getOptionLabel={(option) => { return option?.name || ''; }}
											value={data?.sb_number || []}
											margin="normal"
											multiple={true}
											onChange={(e, value) => this.onFieldChange("sb_number", value)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="SB Number"
													margin="normal"
													placeholder="Select SB Number"
													variant="outlined"
													fullWidth
													InputLabelProps={{ shrink: true }}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="SB Issue Number"
											name="sb_issue_number"
											id="sb_issue_number"
											InputLabelProps={{ shrink: true }}
											value={data?.sb_issue_number}
											inputProps={{ maxLength: 50 }}
											onChange={(e) => this.onFieldChange("sb_issue_number", e.target.value)}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={<React.Fragment> {this.props.props.match.params.assetType == 1 ? "A/C Comp" : "Engine Comp"} {smbcInstance ? null : <span style={{ color: "red" }}> *</span>}</React.Fragment>}
											name="ac_comp"
											id="ac_comp"
											error={error.ac_comp ? true : false}
											helperText={error.ac_comp ? error.ac_comp : ""}
											value={data.ac_comp}
											InputLabelProps={{ shrink: true }}
											onChange={(e) => this.onFieldChange("ac_comp", e.target.value)}
											inputProps={{ maxLength: 50 }}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											required={!smbcInstance}
											label="Type"
											name="type"
											id="type"
											error={error.type ? true : false}
											helperText={error.type ? error.type : ""}
											value={data.type}
											InputLabelProps={{ shrink: true }}
											onChange={(e) => this.onFieldChange("type", e.target.value)}
											inputProps={{ maxLength: 50 }}
										/>
									</Grid>
								</Grid>
								<div className="border-grey"></div>
								<Grid spacing={1} container id="ht-dropdown">
									<Grid item xs={3}>
										<Grid item>
											<FormControl>
												<InputLabel id="demo-simple-select-label">
													AD Compliance Status <span style={{ color: "red" }}> *</span>
												</InputLabel>
												<Select
													labelId="ad_compliance_status"
													id="ad_compliance_status"
													name="ad_compliance_status"
													variant="outlined"
													value={data.ad_compliance_status}
													onChange={(e) => { this.dropDownChange(e); this.onRestErrorKey("ad_compliance_status") }}
												>
													<MenuItem value={"OPEN"}>OPEN</MenuItem>
													<MenuItem value={"COMPLIED"}>COMPLIED</MenuItem>
													<MenuItem value={"PARTIALLY COMPLIED"}> PARTIALLY COMPLIED </MenuItem>
													<MenuItem value={"NOT APPLICABLE"}> NOT APPLICABLE </MenuItem>
													<MenuItem value={"SUPERSEDED"}> SUPERSEDED </MenuItem>
												</Select>
												<FormLabel component="ad_compliance_dfp">
													<span style={{ color: "red" }}>
														{error.ad_compliance_status}
													</span>
												</FormLabel>
											</FormControl>
										</Grid>
									</Grid>
									<Grid item xs={3}>
										<TextField
											required={data.ad_compliance_status !== "COMPLIED" ? false : smbcInstance ? false : true}
											variant="outlined"
											label={
												<span style={{ verticalAlign: 'super' }}>
													AD Compliance TSN
													<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999.99 </span>} arrow>
														<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
													</Tooltip>
												</span>
											}
											id="ad_compliance_tsn"
											disabled={data?.ad_compliance_status === "NOT APPLICABLE"}
											error={error.ad_compliance_tsn ? true : false}
											inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.ad_compliance_tsn) && 10 }}
											helperText={error.ad_compliance_tsn ? error.ad_compliance_tsn : ""}
											value={regexConstants.alphanumespcespclchar.test(data.ad_compliance_tsn) ? data.ad_compliance_tsn : format_Commas(data?.ad_compliance_tsn.replace(/[^0-9.]/g, ''))}
											onChange={(e) => {
												const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
												const numericValue = value.replace(/[^\d.]/g, '');
												const dotCount = (numericValue.match(/\./g) || []).length;
												const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
												if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
													this.onFieldChange("ad_compliance_tsn", e.target.value);
													this.setState({ error: { ...error, ad_compliance_tsn: e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess("AD Compliance TSN", true) }, })
												} else if (isValid || value === "") {
													this.onFieldChange("ad_compliance_tsn", numericValue);
													this.setState({ error: { ...error, ad_compliance_tsn: regexConstants.numberWithDot.test(numericValue) || e.target.value === (null || undefined || "") ? "" : e.target.value === null ? "" : "" } })
												}
											}}
											InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											required={data.ad_compliance_status !== "COMPLIED" ? false : smbcInstance ? false : true}
											label={
												<span style={{ verticalAlign: 'super' }}>
													AD Compliance CSN
													<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999</span>} arrow>
														<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
													</Tooltip>
												</span>
											}
											name="ad_compliance_csn"
											id="ad_compliance_csn"
											InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
											error={error.ad_compliance_csn ? true : false}
											inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data.ad_compliance_csn) ? 8 : 6 }}
											helperText={error.ad_compliance_csn ? error.ad_compliance_csn : ""}
											value={regexConstants.alphanumespcespclchar.test(data.ad_compliance_csn) ? data.ad_compliance_csn : format_Commas(data?.ad_compliance_csn.toString().replace(/[^0-9.]/g, ''))}
											disabled={data?.ad_compliance_status === "NOT APPLICABLE"}
											onChange={(e) => {
												if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
													this.onFieldChange("ad_compliance_csn", e.target.value);
													this.setState({ error: { ...error, ad_compliance_csn: e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) && validationMess("AD Compliance CSN", false) }, })

												} else {
													(regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && this.onFieldChange('ad_compliance_csn', e.target.value, "remove-comma")
													this.setState({ error: { ...error, ad_compliance_csn: e.target?.value === null || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === "" ? "" : "" } })
												}
											}
											}

										/>
									</Grid>
									<Grid className="input-calender" item xs={3}>
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												label={<React.Fragment> Date of compliance {data.ad_compliance_status !== "COMPLIED" ? null : (smbcInstance ? null : <span style={{ color: "red" }}> *</span>)} </React.Fragment>}
												id="ad_compliance_date"
												format={dayMonthDateFormat}
												inputVariant="outlined"
												fullWidth
												onPaste={(event) => event.preventDefault()}
												disableFuture
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												error={error.ad_compliance_date ? true : false}
												helperText={error.ad_compliance_date ? error.ad_compliance_date : ""}
												value={data.ad_compliance_date}
												onChange={(data, value) => {
													this.onFieldChange("ad_compliance_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("ad_compliance_date")
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									{smbcInstance ? <Grid item xs={3}>
										<TextField
											variant="outlined"
											label="Reference"
											name="reference"
											value={data.reference}
											InputLabelProps={{ shrink: true }}
											inputProps={{ maxLength: 50 }}
											onChange={(e, value) => this.onFieldChange("reference", e.target.value)}
										/>
									</Grid> : null}
									<Grid item xs={smbcInstance ? 9 : 12}>
										<TextField
											variant="outlined"
											required={!smbcInstance}
											label="Reference Documents"
											name="ref_documents"
											InputLabelProps={{ shrink: true }}
											error={error.ref_documents ? true : false}
											helperText={error.ref_documents ? error.ref_documents : ""}
											multiline
											minRows={5}
											value={data.ref_documents}
											onChange={(e) => { this.onFieldChange("ref_documents", e.target.value); this.onRestErrorKey("ref_documents") }}
											inputProps={{ maxLength: 50 }}
										/>
									</Grid>
								</Grid>
								<div className="border-grey"></div>
								{/* new start */}
								<Grid spacing={1} container id="data-container">
									<Grid item xs={3} className="input-calender">
										<label className="mb-10">LAST DONE</label>
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												required={!smbcInstance}
												label="Date"
												id="last_done_date"
												format={dayMonthDateFormat}
												inputVariant="outlined"
												fullWidth
												disableFuture
												onPaste={(event) => event.preventDefault()}
												disabled={data.ad_compliance_status === "NOT APPLICABLE"}
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												error={error.last_done_date ? true : false}
												helperText={error.last_done_date ? error.last_done_date : ""}
												value={data.last_done_date}
												onChange={(data, value) => {
													this.onFieldChange("last_done_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("last_done_date")
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
									<Grid item xs={3} id="lastdone-input">
										<TextField
											variant="outlined"
											label="Last Done FH"
											name="last_done_fh"
											id="last_done_fh"
											InputLabelProps={{ shrink: true }}
											disabled={data.ad_compliance_status === "NOT APPLICABLE"}
											value={data.last_done_fh}
											onPaste={(event) => event.preventDefault()}
											onChange={(e) => {
												const value = e.target.value;
												const numericValue = value.replace(/[^\d.]/g, '');
												const dotCount = (numericValue.match(/\./g) || []).length;
												const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 4 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 3 && regexConstants.numberWithDot.test(numericValue);
												if (isValid || value === "") {
													this.onFieldChange("last_done_fh", numericValue);
												}
											}}
										/>
									</Grid>
									<Grid item xs={3} id="lastdone-input">
										<TextField
											variant="outlined"
											label="Last Done FC"
											name="last_done_fc"
											id="last_done_fc"
											InputLabelProps={{ shrink: true }}
											disabled={data.ad_compliance_status === "NOT APPLICABLE"}
											value={data.last_done_fc}
											onPaste={(event) => event.preventDefault()}
											onChange={(e) => e.target.value.length <= 3 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('last_done_fc', e.target.value)}
										/>
									</Grid>
									<Grid item xs={3} id="lastdone-input">
										<TextField
											variant="outlined"
											multiline
											required={!smbcInstance}
											label="Workorder"
											name="workorder"
											id="workorder"
											InputLabelProps={{ shrink: true }}
											disabled={data.ad_compliance_status === "NOT APPLICABLE"}
											error={error.workorder ? true : false}
											helperText={error.workorder ? error.workorder : ""}
											value={data.workorder}
											inputProps={{ maxLength: 25 }}
											onChange={(e) => { this.onFieldChange("workorder", e.target.value); this.onRestErrorKey("workorder") }}
										/>
									</Grid>
								</Grid>
								<Grid id="data-container" spacing={1} container>
									<Grid item xs={3}>
										<label className="ml-10px font-14">INTERVAL</label>
										<Grid spacing={2} container id="ht-dropdown">
											<Grid item>
												<FormControl>
													<InputLabel id="demo-simple-select-label">
														Dimension
													</InputLabel>
													<Select
														labelId="dimension_type"
														name="dimension_type"
														id="dimension_type"
														variant="outlined"
														value={data.dimension_type ? data.dimension_type : ''}
														onChange={this.dropDownChange2}
													>
														<MenuItem value=''><em>None</em> </MenuItem>
														<MenuItem value={"D"}>Days</MenuItem>
														<MenuItem value={"FH"}>FH</MenuItem>
														<MenuItem value={"FC"}>FC</MenuItem>
														<MenuItem value={"FC,D"}>FC & D</MenuItem>
														<MenuItem value={"FH,D"}>FH & D</MenuItem>
														<MenuItem value={"FC,FH,D"}>FC,FH & D</MenuItem>
														<MenuItem value={"FC,FH"}>FC & FH</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									<Grid id="dimensions-input-ht" item xs={3}>
										<Grid spacing={2} container>
											{data.dimension_type == "FC" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH" || data.dimension_type == "FC,FH,D" ? <Grid
												item
												xs={data.dimension_type == "FC" ? 12 : data.dimension_type == "FC,D" || data.dimension_type == "FC,FH" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}
											>
												<TextField
													variant="outlined"
													label={<React.Fragment> {data.dimension_type == "FC" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH,D" || data.dimension_type == "FC,FH" ? "FC Interval" : "FC, Interval"} {smbcInstance ? null : <span style={{ color: "red" }}> *</span>} </React.Fragment>}
													name="dimension_interval"
													id="dimension_interval"
													value={data.dimension_interval_fc ? data.dimension_interval_fc : ''}
													InputLabelProps={{ shrink: true }}
													inputProps={{ maxLength: 5 }}
													onChange={(e) => { (e.target.value || e.target.value === "") && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('dimension_interval_fc', e.target.value) }}
													onPaste={(event) => event.preventDefault()}
													error={error.dimension_interval_fc ? true : false}
													helperText={error.dimension_interval_fc ? error.dimension_interval_fc : ""}
												/>
											</Grid> : null}
											{data.dimension_type == "FH" || data.dimension_type == "FH,D" || data.dimension_type == "FC,FH" || data.dimension_type == "FC,FH,D" ? <Grid
												item
												xs={data.dimension_type == "FH" ? 12 : data.dimension_type == "FH,D" || data.dimension_type == "FC,FH" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}
											>
												<TextField
													variant="outlined"
													label={<React.Fragment> {data.dimension_type == "FH" || data.dimension_type == "FH,D" || data.dimension_type == "FC,FH,D" || data.dimension_type == "FC,FH" ? "FH Interval" : "FH, Interval"} {smbcInstance ? null : <span style={{ color: "red" }}> *</span>} </React.Fragment>}
													name="dimension_interval"
													id="dimension_interval"
													InputLabelProps={{ shrink: true }}
													value={data.dimension_interval ? data.dimension_interval : ''}
													inputProps={{ maxLength: 5 }}
													onChange={(e) => { (e.target.value || e.target.value === "") && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('dimension_interval', e.target.value) }}
													onPaste={(event) => event.preventDefault()}
													error={error.dimension_interval ? true : false}
													helperText={error.dimension_interval ? error.dimension_interval : ""}
												/>
											</Grid> : null}
											{data.dimension_type == "D" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH,D" ||
												data.dimension_type == "FH,D" ? (
												<Grid item xs={data.dimension_type == "D" ? 12 : data.dimension_type == "FC,D" || data.dimension_type == "FH,D" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}>
													<TextField
														variant="outlined"
														required={!smbcInstance}
														label="Days"
														name="dimension_interval_days"
														id="dimension_interval_days"
														value={data.dimension_interval_days ? data.dimension_interval_days : ''}
														InputLabelProps={{ shrink: true }}
														inputProps={{ maxLength: 5 }}
														onChange={(e) => { (e.target.value || e.target.value === "") && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && this.onFieldChange('dimension_interval_days', e.target.value) }}
														onPaste={(event) => event.preventDefault()}
														error={error.dimension_interval_days ? true : false}
														helperText={error.dimension_interval_days ? error.dimension_interval_days : ""}
													/>
												</Grid>
											) : null}
										</Grid>
									</Grid>
									<Grid item xs={2}>
										<label className="ml-10px font-14">REMAINING</label>
										<Grid spacing={1} container>
											<Grid className="remaining-input" item xs={6}>
												<TextField
													variant="outlined"
													label={data.dimension_type === "D" ? "Days" : data.dimension_type === "FC" ? "FC" : data.dimension_type === "FH" ? "FH" :
														data.dimension_type === "FH,D" ? "FH/Days" : data.dimension_type === "FC,D" ? "FC/Days" : data.dimension_type === "FC,FH,D" ? "FC/FH/Days" : data.dimension_type === "FC,FH" ? "FC/FH" : "Days"}
													name="remaining_val"
													id="remaining_val"
													InputLabelProps={{ shrink: true }}
													value={this.remainingVal()}
													onChange={(e, value) => this.onFieldChange("remaining_val", e.target.value)}
													inputProps={{ readOnly: true }}
													disabled
													className="disabled-input"
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={2}>
										<label className="ml-10px font-14">NEXT DUE </label>
										<Grid spacing={2} container>
											<Grid className="remaining-input" item xs={6}>
												{data.dimension_type === '' || data.dimension_type === null ?
													<MuiPickersUtilsProvider utils={MomentUtils}>
														<KeyboardDatePicker
															label='Date'
															keyParam='due_date'
															format={dayMonthDateFormat}
															inputVariant="outlined"
															onPaste={(event) => event.preventDefault()}
															InputLabelProps={{ shrink: true }}
															value={data.due_date ? data.due_date : null}
															onChange={(value) => { this.onFieldChange("due_date", moment(value).format(backendDateFormat)) }}
														/>
													</MuiPickersUtilsProvider> :
													<TextField
														variant="outlined"
														label={data.dimension_type === "D" ? "Date" : data.dimension_type === "FC" ? "FC" : data.dimension_type === "FH" ? "FH" : data.dimension_type === "FH,D" ? "FH/Date" :
															data.dimension_type === "FC,D" ? "FC/Date" : data.dimension_type === "FC,FH,D" ? "FC/FH/Date" : data.dimension_type === "FC,FH" ? "FC/FH " : "Date"}
														name="ad_due"
														id="ad_due"
														value={this.nextDueValue()}
														onChange={(e, value) => this.onFieldChange("ad_due", e.target.value)}
														inputProps={{ readOnly: true }}
														InputLabelProps={{ shrink: true }}
														disabled
														className="disabled-input"
													/>
												}
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={2} style={{ marginTop: '23px' }}>
										<Grid spacing={1} container>
											<Grid className="remaining-input" item xs={6}>
												<TextField
													variant="outlined"
													label="Note"
													fullWidth
													margin='normal'
													id="next_due_note"
													value={data?.next_due_note || ''}
													onChange={(e, value) => this.onFieldChange("next_due_note", e.target.value)}
													InputLabelProps={{ shrink: true }}
													inputProps={{ maxLength: 50 }}
												/>
											</Grid>
										</Grid>
									</Grid>

								</Grid>
								{/* new end */}
								<div className="border-grey"></div>
								<Grid id="data-container" spacing={1} container>
									<Grid item xs={3}>
										<label>
											AD Compliance DFP {smbcInstance ? null : <span style={{ color: "red" }}> *</span>}
										</label>

										<FormControl
											id="d-block"
											onFocus={() => this.onRestErrorKey("ad_compliance_dfp")}
										>
											<RadioGroup
												row
												required
												aria-label="ad_compliance_dfp"
												name="ad_compliance_dfp"
												defaultValue="top"
												value={data.ad_compliance_dfp}
												onChange={(e, value) => this.onFieldChange("ad_compliance_dfp", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
											{error.ad_compliance_dfp && (
												<FormLabel component="ad_compliance_dfp">
													<span style={{ color: "red" }}>
														{error.ad_compliance_dfp}
													</span>
												</FormLabel>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={3}>
										<label>Release certificate</label>
										<FormControl id="d-block">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="release_certificate"
												name="release_certificate"
												defaultValue="top"
												value={data.release_certificate}
												onChange={(e, value) => this.onFieldChange("release_certificate", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<label>Certificate of Conformity</label>
										<FormControl id="d-block">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="conformity_crt"
												name="conformity_crt"
												defaultValue="top"
												value={data.conformity_crt}
												onChange={(e, value) => this.onFieldChange("conformity_crt", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
												<FormControlLabel
													value="N/A"
													control={<Radio size="small" color="primary" />}
													label="N/A"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={6}>
										<TextField
											label="Remark"
											margin='normal'
											variant='outlined'
											fullWidth
											value={data.remark}
											InputLabelProps={{ shrink: true }}
											onChange={(e) => this.onFieldChange("remark", e.target.value)}
											rows={3}
											multiline
											inputProps={{ maxLength: 255 }}
										/>
									</Grid>
									<Grid item xs={6}>
										<div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
											<ul className="list-inline spacing-list">
												<li className="list-inline-item" onClick={this.handleOpenUploadFile} >
													<span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
												</li>
												<li className="list-inline-item" onClick={this.handleOpenFile}>
													<span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
												</li>
											</ul>
										</div>
									</Grid>
								</Grid>
								<Grid id="data-container" spacing={1} container>
									<Grid spacing={1} container>
										{this.props.actionType === 'add' ?
											<div className='checklist-files'>
												<ul className='list-inline'>
													{(Array.isArray(data?.checklist) && data?.checklist?.length > 0) &&
														data?.checklist?.map((file) =>
															<li className='list-inline-item'>
																<span className='file-name'>{file.name}</span>
																<span className='file-remove'>
																	{file?.type === 'folder' ? null : file?.type && file?.uuid ?
																		<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																		:
																		<a href={URL.createObjectURL(file)} download={file.name}>
																			<GetAppIcon color="primary" /></a>

																	}
																	{file?.type === 'folder' ?
																		<Tooltip title={`Records Data Room :- ${file?.name}`} arrow>
																			<a href={`${config.domain.records}${assetType[this.props.props?.match.params.assetType]}/${this.props?.slug}/data-room/technical/${file?.uuid}`} target="_new">
																				<img style={{ width: '15px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
																		</Tooltip> : null}
																	<CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
																</span>
															</li>
														)}
													{(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) =>
														<li className='list-inline-item'>
															<span className='file-name'>{attachments.name}</span>
															<span className='file-remove'>
																<a href={URL.createObjectURL(attachments)} download={attachments.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
												</ul>
											</div>
											:
											<div className='checklist-files'>
												<ul className='list-inline'>
													{data?.checklist?.files?.map((file) =>
														<li className='list-inline-item'>
															<span className='file-name'>{file.name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
													{(data.checklist?.folders?.length > 0) &&
														data.checklist?.folders.map((file) => {
															return (
																<li className='list-inline-item' key={file.id}>
																	<Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
																		<a href={`${config.domain.records}${assetType[this.props.props?.match.params.assetType]}/${file?.asset_slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
																	</Tooltip>
																	<span className='file-name'>{file.name}</span>
																	<span className='file-remove'>
																		<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
																	</span>
																</li>
															);
														})}
													{(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) =>
														<li className='list-inline-item'>
															<span className='file-name'>{attachments.name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(attachments?.file)} download={attachments.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id, attachments)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
												</ul>
											</div>}
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions className="actions-btn">
								<Button type="button" className="btn btn-primary" onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)} >
									{this.props.actionType === "add" ? "Add" : "Update"}
								</Button>
								{this.props.actionType === "add" ? (
									<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
								) : (
									""
								)}
								<Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}> Close </Button>
							</DialogActions>
							<Dialog
								open={this.state.open}
								onClose={this.handleCloseAttachment}
								id="htDialog"
							>
								<AdConfirmDeleteAttachment
									handleCloseAttachment={() => this.handleCloseAttachment()}
									deleteAttachmentFile={() => this.deleteAttachmentFile(this.state.deleteAttachmentId)}
								/>
							</Dialog>
							<Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
								<LinkFiles
									handleClose={() => this.handleCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
									modType="aird"
									last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
									uploadFileInfo={data}
									attachments={attachments}
									type="link"
								/>
							</Dialog>
							<Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
								<UploadFile
									handleClose={() => this.handleUploadFileCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
									modType="aird"
									uploadFileInfo={data}
									last_used_folder_uuid={this.props.last_used_folder_uuid}
									onUpload={(file) => this.onUpload(file)}
									type="upload"
									checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
									checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
								/>
							</Dialog>
						</form>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default AdTaskForm
