import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip, CircularProgress } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import AttachmentIcon from '@material-ui/icons/Attachment';
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, dayMonthDateFormat } from "../../../constants"
import moment from "moment";
import { useSnackbar } from 'notistack';
import config from "../../../config";
import { getLocalStorageInfo, checkApiStatus, format_Commas } from "../../../utils";
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../utils/globalApiServices';
import { PageLoader } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { specificKey, specificMessTsn, specificMessCsn } from '../../camo';
import { fleetSbData } from '.';


function AddEditFleetSB({ toggleModalFn, fleetData, modeType, getResponseBack }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [data, setData] = useState(fleetData ? fleetData : fleetSbData)
	const [error, setError] = useState({})
	const [isLoading, setLoading] = useState(false)
	const [applicability, setApplicability] = useState([])
	const [egineFamily, setEngineFamily] = useState([])
	const [uploadFile, setUploadFile] = useState(false)

	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
	useEffect(() => {
	
		getEngineFamily()
		if (data?.engine_family) {
			onChangeEngineFamily('engine_family', data?.engine_family)
		}else{
			getApplicability()
		}
	}, [])

	const onFieldChange = (keyParam, value) => {
		if (keyParam === "attachments") {
			if (Array.isArray(value)) {
				const updatedAttachments = [...(data?.attachments || []), ...value];
				setData({ ...data, [keyParam]: updatedAttachments });
			} else {
				console.error("Value for attachments is not an array:", value);
			}
		}
		setData({ ...data, [keyParam]: value });

	}

	const onChangeEngineFamily = (keyParam, value) => {
		if (keyParam === 'engine_family' && !value) {
			setData({ ...data, ['engine_family']: '', ['sb_applicability']: '' });
			getApplicability()
		} else {
			globalGetService(`camo/engine_dropdown/${value?.id}/`,)
				.then(response => {
					if (checkApiStatus(response)) {
						setData({
							...data,
							[keyParam]: value,
							sb_applicability: response?.data?.engine_type.filter((item)=> data?.sb_applicability?.length > 0 && data?.sb_applicability?.map((item)=>item.id).includes(item.id))
						});
						setApplicability(response?.data?.engine_type)
					} else {
						setData({ ...data, [keyParam]: value });
					}
				})
		}
	}

	const onRestErrorKey = (keyParam) => {
		setError({ [keyParam]: '' })
	}

	const getEngineFamily = () => {
		globalGetService(`camo/engine_dropdown/`)
			.then((response) => {
				if (response.status === 200) {
					setEngineFamily(response?.data?.engine_family)
				}
			})
	}
	const getApplicability = () => {
		globalGetService(`console/engine-types/`)
			.then((response) => {
				if (response.status === 200) {
					setApplicability(response?.data?.data?.engineTypes)
				}
			})
	}
	const onAddNewAsset = () => {
		let validationInputs = {}
		if (!smbcInstance) {
			validationInputs = {
				...validationInputs,
				ata_chapter: data?.ata_chapter ? '' : "Please Enter ATA Chapter",
				sb_ref_number: data?.sb_ref_number ? '' : "Please Enter SB Refrence Number",
				description: data?.description ? '' : "Please Enter Description",
				revision: data?.revision ? '' : "Please Enter Revision",
				workorder: data?.sb_compliance_status === "NOT APPLICABLE" || data?.workorder ? '' : "Please Enter WorkOrder",
				revision_date: data?.revision_date ? '' : "Please Enter Revision Date",
				sb_compliance_dfp: data?.sb_compliance_dfp ? '' : "Please Select SB Compliance",
			}
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
			setLoading(true)
			let payload = Object.assign({}, data);
			if (data?.id) {
				globalPutService(`camo/fleet_sb/${payload.id}/`, { ...payload, asset_type_id: 2, sb_applicability: payload?.sb_applicability?.map(item => item?.id).join(','), sb_compliance_tsn: payload.sb_compliance_tsn ? payload?.sb_compliance_tsn : null, sb_compliance_csn: payload.sb_compliance_csn ? payload?.sb_compliance_csn.toString().replace(",", "") : null })
					.then(response => {
						setLoading(false)
						if (response.data?.statusCode === 200) {
							enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
							toggleModalFn(); getResponseBack()
							importFile(response?.data?.data)
						} else {
							enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

						}
					})
			} else {
				globalPostService(`camo/fleet_sb/`, { ...payload, asset_type_id: 2, sb_applicability: payload?.sb_applicability?.map(item => item?.id).join(','), sb_compliance_tsn: payload.sb_compliance_tsn ? payload?.sb_compliance_tsn : null, sb_compliance_csn: payload.sb_compliance_csn ? payload?.sb_compliance_csn.toString().replace(",", "") : null })
					.then(response => {
						setLoading(false)
						if (response.data?.statusCode === 200) {
							enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
							toggleModalFn(); getResponseBack()
							importFile(response?.data?.data)
						} else {
							enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

						}
					})

			}
		} else {
			setError(validationInputs)
		}
	}
	const importFile = (item) => {
		let attach = data?.attachments || []
		var formData = new FormData();
		Object.keys(attach?.length && attach.filter(item => item instanceof File)).map((key) => {
			formData.append("[" + key + "]file", attach.filter(item => item instanceof File)[key])
			formData.append("[" + key + "]file_type", attach.filter(item => item instanceof File)[key].type)
			formData.append("[" + key + "]file_name", attach.filter(item => item instanceof File)[key].name)
			formData.append("[" + key + "]module_id", item?.id)
			formData.append("[" + key + "]module_type_id", 15)
			formData.append("[" + key + "]asset_type_id", 2)
		})
		setLoading(true);
		globalFileUploadService(`camo/attachment/upload/?fleet=sb`, formData)
			.then(response => {
				toggleModalFn(); getResponseBack()
				if (checkApiStatus(response)) {
					// enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				} else {
					enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}
			})

	}
	const onRemoveUploadFile = (files) => {
		if (!files?.id) {
			setData({ ...data, attachments: data?.attachments.filter(item => item.name !== files.name) });
		} else {
			globalDeleteService(`camo/attachment/${files?.id}/delete/?fleet=sb`)
				.then((response) => {
					if (response.data?.statusCode === 200) {
						setData({ ...data, attachments: data?.attachments.filter(item => item.name !== files.name) })
						getResponseBack()
						enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
					} else {
						enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

					}
				})
		}
	}

	return (
		<>
			<Dialog
				open={true}
				onClose={toggleModalFn}
			>
				<DialogTitle> {modeType === "edit" ? "Edit fleet SB" : "Add Fleet SB"} </DialogTitle>
				<DialogContent dividers>
					<Grid spacing={1} container>
						<Grid item xs={12} md={3} sm={3}>
							<TextField
								required={!smbcInstance}
								variant="outlined"
								margin='normal'
								label="ATA Chapter"
								id="ata_chapter"
								fullWidth
								inputProps={{ maxLength: 200 }}
								value={data?.ata_chapter}
								InputLabelProps={{ shrink: true }}
								error={error.ata_chapter ? true : false}
								helperText={error.ata_chapter ? error.ata_chapter : ""}
								onChange={(e) => { onFieldChange("ata_chapter", e.target.value); onRestErrorKey("ata_chapter") }}
							/>
						</Grid>
						<Grid item xs={12} md={3} sm={3}>
							<TextField
								variant="outlined"
								margin='normal'
								label="SB Reference Number"
								id="sb_ref_number"
								required={!smbcInstance}
								fullWidth
								value={data?.sb_ref_number}
								InputLabelProps={{ shrink: true }}
								inputProps={{ maxLength: 200 }}
								error={error.sb_ref_number ? true : false}
								helperText={error.sb_ref_number ? error.sb_ref_number : ""}
								onChange={(e) => { onFieldChange("sb_ref_number", e.target.value); onRestErrorKey("sb_ref_number") }}
							/>
						</Grid>
						<Grid item xs={12} md={6} sm={6}>
							<TextField
								variant="outlined"
								label="SB Issued by"
								margin='normal'
								id="sb_issued_by"
								fullWidth
								inputProps={{ maxLength: 200 }}
								InputLabelProps={{ shrink: true }}
								value={data?.sb_issued_by}
								onChange={(e) => onFieldChange("sb_issued_by", e.target.value)}
							/>
						</Grid>
						<Grid spacing={1} container>
							<Grid item xs={12} md={3} sm={3}>
								<Autocomplete
									id="engine_family"
									options={egineFamily || []}
									getOptionLabel={(option) => option?.name}
									value={data?.engine_family || []}
									margin='normal'
									disabled={modeType === "edit"}
									onChange={(e, value) => onChangeEngineFamily("engine_family", value)}
									renderInput={(params) => (
										<TextField
											{...params}
											label={
												<span style={{ verticalAlign: 'super' }}>
													Engine Family
													<Tooltip placement='right' title="Select Engine Family to apply all Engine Types under the family to Applicability" arrow>
														<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
													</Tooltip>
												</span>
											}
											margin='normal'
											placeholder="Select Engine Family"
											variant="outlined"
											fullWidth
											disabled={modeType === "edit"}
											InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={9} sm={9}>
								<Autocomplete
									id="ad_type"
									options={applicability || []}
									getOptionLabel={(option) => option?.name}
									value={data?.sb_applicability || []}
									margin='normal'
									multiple={true}
									disabled={modeType === "edit"}
									disableCloseOnSelect={true}
									onChange={(e, value) => onFieldChange("sb_applicability", value)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="SB Applicability"
											margin='normal'
											placeholder="Select Applicability"
											variant="outlined"
											fullWidth
											disabled={modeType === "edit"}
											InputLabelProps={{ shrink: true }}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid spacing={1} container>
						<Grid item xs={12}>
							<TextField
								required={!smbcInstance}
								variant="outlined"
								label="SB Description"
								margin='normal'
								id="description"
								multiline
								minRows={5}
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={data?.description}
								onChange={(e) => { onFieldChange("description", e.target.value); onRestErrorKey("description") }}
								error={error.description ? true : false}
								helperText={error.description ? error.description : ""}
							/>
						</Grid>
					</Grid>
					<Grid spacing={1} container>
						<Grid item xs={12} md={3} sm={3}>
							<Autocomplete
								id="type"
								options={['ALERT', 'STANDARD', 'OPTIONAL']}
								getOptionLabel={option => option}
								margin='normal'
								fullWidth
								value={data?.type || null}
								onChange={(e, value) => onFieldChange("type", value)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="SB Type"
										placeholder="Select SB Type"
										variant="outlined"
										fullWidth
										margin='normal'
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={3} sm={3}>
							<TextField
								variant="outlined"
								label="SB Effectivity"
								margin='normal'
								id="effectivity"
								fullWidth
								inputProps={{ maxLength: 200 }}
								InputLabelProps={{ shrink: true }}
								value={data?.effectivity}
								onChange={(e) => onFieldChange("effectivity", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={3} sm={3}>
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<KeyboardDatePicker
									id="effectivity_date"
									label="SB Effective Date"
									margin='normal'
									format={dayMonthDateFormat}
									inputVariant="outlined"
									fullWidth
									disableFuture
									InputLabelProps={{ shrink: true }}
									minDate={moment().subtract(25, "years")}
									value={data?.effectivity_date || null}
									error={false}
									helperText={""}
									onPaste={(event) => event.preventDefault()}
									onChange={(data) => onFieldChange("effectivity_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12} md={3} sm={3}>
							<Autocomplete
								id="sb_compliance_status"
								options={['OPEN', 'COMPLIED', 'FACTORY COMPLIED', 'PARTIALLY COMPLIED',  'INFORMATION ONLY', 'CANCELLED','NOT APPLICABLE','REPEAT INSPECTION']}
								getOptionLabel={option => option}
								margin='normal'
								fullWidth
								value={data?.sb_compliance_status || null}
								onChange={(e, value) => onFieldChange("sb_compliance_status", value)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Compliance Status"
										placeholder="Select Compliance Status"
										variant="outlined"
										fullWidth
										margin='normal'
										InputLabelProps={{ shrink: true }}
									/>
								)}
							/>
						</Grid>
					</Grid>
					<Grid spacing={1} container>
						<Grid item xs={12} md={3} sm={3}>
							<TextField
								variant="outlined"
								label={
									<span style={{ verticalAlign: 'super' }}>
										SB Compliance TSN
										<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999</span>} arrow>
											<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
										</Tooltip>
									</span>
								}
								margin='normal'
								id="sb_compliance_tsn"
								inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data?.sb_compliance_tsn) && 10 }}
								InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
								fullWidth
								value={regexConstants.alphanumespcespclchar.test(data?.sb_compliance_tsn) ? data?.sb_compliance_tsn : format_Commas(data?.sb_compliance_tsn.replace(/[^0-9.]/g, ''))}
								// value={data?.sb_compliance_tsn ? format_Commas(data?.sb_compliance_tsn.replace(/[^0-9.]/g, '')) : ''}
								disabled={data?.sb_compliance_status === "NOT APPLICABLE"}
								error={error.sb_compliance_tsn ? true : false}
								helperText={error?.sb_compliance_tsn ? error?.sb_compliance_tsn : ""}
								onChange={(e) => {
									const value = format_Commas(e.target.value).replace(/[^0-9.]/g, '');
									const numericValue = value.replace(/[^\d.]/g, '');
									const dotCount = (numericValue.match(/\./g) || []).length;
									const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
									if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
										onFieldChange("sb_compliance_tsn", e.target.value);
										setError({ ...error, sb_compliance_tsn: e.target.value === (null || undefined || "") ? "" : !specificKey.includes(e.target.value.toUpperCase()) && specificMessTsn })
									} else if (isValid || value === "") {
										onFieldChange("sb_compliance_tsn", numericValue);
										setError({ ...error, sb_compliance_tsn: regexConstants.numberWithDot.test(numericValue) || e.target.value === (null || undefined || "") ? "" : e.target.value === null ? "" : "" })

									}
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3} sm={3}>
							<TextField
								variant="outlined"
								label={
									<span style={{ verticalAlign: 'super' }}>
										SB Compliance CSN
										<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK or 0 to 99999</span>} arrow>
											<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
										</Tooltip>
									</span>
								}
								margin='normal'
								id="sb_compliance_csn"
								fullWidth
								inputProps={{ maxLength: regexConstants.alphanumespcespclchar.test(data?.sb_compliance_csn) ? 8 : 6 }}
								InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
								disabled={data?.sb_compliance_status === "NOT APPLICABLE"}
								error={error.sb_compliance_csn ? true : false}
								helperText={error.sb_compliance_csn ? error.sb_compliance_csn : ""}
								value={regexConstants.alphanumespcespclchar.test(data?.sb_compliance_csn) ? data.sb_compliance_csn : format_Commas(data?.sb_compliance_csn.toString().replace(/[^0-9.]/g, ''))}
								onChange={(e) => {
									if (regexConstants.alphanumespcespclchar.test(e.target.value)) {
										onFieldChange("sb_compliance_csn", e.target.value);
										setError({ ...error, sb_compliance_csn: e.target?.value === null || e.target?.value === "" ? "" : !specificKey.includes(e.target.value.toUpperCase()) && specificMessCsn })

									} else {
										e.target.value.length <= 6 && (regexConstants.onlyNumeric.test(format_Commas(e.target.value).replace(/[^0-9.]/g, '')) || e.target.value === "") && onFieldChange('sb_compliance_csn', e.target.value)
										setError({ ...error, sb_compliance_csn: e.target?.value === null || regexConstants.numberWithComma.test(e.target.value) || e.target?.value === "" ? "" : "" })

									}
								}
								}
							/>
						</Grid>
						<Grid item xs={12} md={3} sm={3} className="input-calender">
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<KeyboardDatePicker
									id="sb_compliance_date"
									label={<> SB Compliance Date {smbcInstance ? null : data?.sb_compliance_status === "COMPLIED" ? (<span style={{ color: "red" }}> *</span>) : null} </>}
									format={dayMonthDateFormat}
									inputVariant="outlined"
									fullWidth
									disableFuture
									margin='normal'
									onPaste={(event) => event.preventDefault()}
									InputLabelProps={{ shrink: true }}
									minDate={moment().subtract(25, "years")}
									value={data?.sb_compliance_date || null}
									error={error.sb_compliance_date ? true : false}
									helperText={error.sb_compliance_date ? error.sb_compliance_date : ""}
									onChange={(data) => onFieldChange("sb_compliance_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						{smbcInstance ? <Grid item xs={12} md={3} sm={3}>
							<TextField
								variant="outlined"
								label="Category"
								value={data?.sb_category}
								inputProps={{ maxLength: 100 }}
								margin="normal"
								fullWidth
								InputLabelProps={{ shrink: true }}
								onChange={(e) => onFieldChange("sb_category", e.target.value)}
							/>
						</Grid> :
							<Grid item xs={12} md={3} sm={3}>
								<TextField
									variant="outlined"
									label={<> Service Bulletin <span style={{ color: "red" }}> *</span> </>}
									id="service_bulletin"
									margin="normal"
									fullWidth
									InputLabelProps={{ shrink: true }}
									value={data?.service_bulletin}
									error={error.service_bulletin ? true : false}
									helperText={error.service_bulletin ? error.service_bulletin : ""}
									onChange={(e) => onFieldChange("service_bulletin", e.target.value)}
								/>
							</Grid>
						}
					</Grid>
					<Grid spacing={1} container>
						{smbcInstance && <Grid item xs={12} md={4} sm={4}>
							<TextField
								variant="outlined"
								label="SB Impact"
								id="sb_impact"
								margin="normal"
								fullWidth
								inputProps={{ maxLength: 250 }}
								InputLabelProps={{ shrink: true }}
								required={!smbcInstance}
								value={data?.sb_impact}
								onChange={(e) => onFieldChange("sb_impact", e.target.value)}
							/>
						</Grid>}
						<Grid item xs={12} md={smbcInstance ? 4 : 3} sm={smbcInstance ? 4 : 3}>
							<TextField
								variant="outlined"
								label="Revision"
								id="revision"
								margin="normal"
								fullWidth
								inputProps={{ maxLength: 200 }}
								InputLabelProps={{ shrink: true }}
								required={!smbcInstance}
								value={data?.revision}
								error={error.revision ? true : false}
								helperText={error.revision ? error.revision : ""}
								onChange={(e) => { onFieldChange("revision", e.target.value); onRestErrorKey("revision") }}
							/>
						</Grid>
						<Grid item xs={12} md={smbcInstance ? 4 : 3} sm={smbcInstance ? 4 : 3} className="input-calender">
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<KeyboardDatePicker
									id="revision_date"
									required={!smbcInstance}
									label="Revision Date"
									format={dayMonthDateFormat}
									inputVariant="outlined"
									margin="normal"
									fullWidth
									disableFuture
									onPaste={(event) => event.preventDefault()}
									InputLabelProps={{ shrink: true }}
									minDate={moment().subtract(25, "years")}
									value={data?.revision_date || null}
									error={error.revision_date ? true : false}
									helperText={error.revision_date ? error.revision_date : ""}
									onChange={(data) => { onFieldChange("revision_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onRestErrorKey("revision_date") }}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12} md={smbcInstance ? 4 : 3} sm={smbcInstance ? 4 : 3}>
							<TextField
								variant="outlined"
								label="AD"
								margin='normal'
								id="ad_no"
								fullWidth
								value={data?.ad_no}
								inputProps={{ maxLength: 200 }}
								InputLabelProps={{ shrink: true }}
								onChange={(e) => onFieldChange("ad_no", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={smbcInstance ? 4 : 3} sm={smbcInstance ? 4 : 3}>
							<TextField
								required={!smbcInstance}
								variant="outlined"
								label="Work Order"
								id="workorder"
								margin='normal'
								fullWidth
								inputProps={{ maxLength: 500 }}
								InputLabelProps={{ shrink: true }}
								value={data?.workorder}
								onChange={(e) => { onFieldChange("workorder", e.target.value); onRestErrorKey("workorder") }}
								error={error.workorder ? true : false}
								helperText={error.workorder ? error.workorder : ""}
							/>
						</Grid>
						<Grid item xs={12} md={smbcInstance ? 4 : 3} sm={smbcInstance ? 4 : 3}>
							<TextField
								variant="outlined"
								label="Subject"
								id="subject"
								margin='normal'
								inputProps={{ maxLength: 200 }}
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={data?.subject}
								onChange={(e) => onFieldChange("subject", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={smbcInstance ? 4 : 3} sm={smbcInstance ? 4 : 3}>
							<FormControl required={!smbcInstance} margin='normal' error={error.sb_compliance_dfp ? true : false} helperText={error.sb_compliance_dfp ? error.sb_compliance_dfp : ""} >
								<FormLabel component="legend">SB Compliance DFP</FormLabel>
								<RadioGroup
									row
									aria-label="sb_compliance_dfp"
									name="sb_compliance_dfp"
									defaultValue="top"
									value={data?.sb_compliance_dfp}
									onChange={(e) => { onFieldChange("sb_compliance_dfp", e.target.value); onRestErrorKey("sb_compliance_dfp") }}
								>
									<FormControlLabel
										value="yes"
										control={<Radio size="small" color="primary" />}
										label="Yes"
									/>
									<FormControlLabel
										value="no"
										control={<Radio size="small" color="primary" />}
										label="No"
									/>
								</RadioGroup>
								{error.sb_compliance_dfp && (
									<span style={{ color: "red", fontSize: '0.75rem' }}>
										{error.sb_compliance_dfp}
									</span>
								)}
							</FormControl>
						</Grid>
						<Grid item xs={12} md={smbcInstance ? 4 : 3} sm={smbcInstance ? 4 : 3}>
							<FormControl margin='normal'>
								<FormLabel component="legend">Release Certificate</FormLabel>
								<RadioGroup
									row
									aria-label="release_certificate"
									name="release_certificate"
									defaultValue="top"
									value={data?.release_certificate}
									onChange={(e, value) => onFieldChange("release_certificate", e.target.value)}
								>
									<FormControlLabel
										value="yes"
										control={<Radio size="small" color="primary" />}
										label="Yes"
									/>
									<FormControlLabel
										value="no"
										control={<Radio size="small" color="primary" />}
										label="No"
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={smbcInstance ? 4 : 3} sm={smbcInstance ? 4 : 3}>
							<FormControl margin='normal'>
								<FormLabel component="legend"> Certificate of Conformity</FormLabel>
								<RadioGroup
									row
									aria-label="certificate_of_conformity"
									name="certificate_of_conformity"
									defaultValue="top"
									value={data?.certificate_of_conformity}
									onChange={(e, value) => onFieldChange("certificate_of_conformity", e.target.value)}
								>
									<FormControlLabel
										value="yes"
										control={<Radio size="small" color="primary" />}
										label="Yes"
									/>
									<FormControlLabel
										value="no"
										control={<Radio size="small" color="primary" />}
										label="No"
									/>
									<FormControlLabel
										value="N/A"
										control={<Radio size="small" color="primary" />}
										label="N/A"
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} sm={6}>
							<TextField
								label="Remarks"
								margin='normal'
								variant='outlined'
								fullWidth
								InputLabelProps={{ shrink: true }}
								onChange={(e) => onFieldChange("remark", e.target.value)}
								rows={3}
								multiline
							/>
						</Grid>
						<Grid item xs={12} md={6} sm={6} margin='normal' style={{ marginTop: "15px" }}>
							<div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px" }}>
								<span onClick={() => setUploadFile(true)} style={{ cursor: 'pointer' }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
								<div style={{ overflow: "auto" }}>
									{data?.attachments?.length > 0 && data?.attachments?.map((attachments) =>
										<>{attachments.name}
											<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
											<CloseIcon onClick={() => onRemoveUploadFile(attachments)} color='secondary' style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
										</>
									)}
								</div>
							</div>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
					<Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
				</DialogActions>
			</Dialog>
			{
				uploadFile ?
					<Dialog
						open={true}
						onClose={() => setUploadFile(false)}
						className='camo-management-modal'
						maxWidth={'md'}
					>
						<DialogTitle id="scroll-dialog-title">
							Uplaod SB  File
						</DialogTitle>
						<DialogContent dividers={true}>
							<div style={{ width: '380px' }}>
								<DropzoneArea
									filesLimit={5}
									maxFileSize={104857600}
									showPreviewsInDropzone={false}
									useChipsForPreview={true}
									showPreviews={true}
									dropzoneText={<p>Drag & Drop file Or Click Here </p>}
									dropzoneClass="drag-drop-cnt"
									maxWidth="sm"
									showAlerts={['info', 'error']}
									alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
									clearOnUnmount={true}
									onChange={(value) => onFieldChange("attachments", value)}
								/>
							</div>
						</DialogContent>
						<DialogActions>
							<Button disabled={isLoading} onClick={() => setUploadFile(false)} color='primary' size='small' variant='outlined'>Cancel</Button>
							<Button onClick={() => setUploadFile(false)} color='primary' size='small' variant='contained'>
								{isLoading ? <CircularProgress size={24} color='white' /> : 'Save'}
							</Button>
						</DialogActions>
					</Dialog>
					: null

			}
			{isLoading ? <PageLoader /> : null}

		</>
	)
}
export default AddEditFleetSB;