import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { TableRow, TableCell, Tooltip, IconButton } from "@material-ui/core"
import AssetCard from "../components/AssetCard"
import { Link } from "react-router-dom"
import { assetTypeValues } from "../../../constants"
import { Visibility } from "@material-ui/icons"
import { getLocalStorageInfo } from "../../../utils"
import config from '../../../config';
import FlightIcon from '@material-ui/icons/Flight';
import { assetType } from "../../../constants"

const AssetList = ({ item, tabIndex }) => {
	const truncateString = (str, maxLength) => {
		if (str.length <= maxLength) {
			return str;
		}
		return str.substring(0, maxLength - 3) + "...";
	};

	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532)
	const truncatedCountry = truncateString(item.country || "--", 18);
	const rowClick = item.asset_type === 1 ? `/${item.id}/${item.asset_type}/amp/list` : smbcInstance ? `/${item.id}/${item.asset_type}/op_history/list` : `/${item.id}/${item.asset_type}/ad/list`

	return (
		<>
			{smbcInstance ?
				<TableRow hover tabIndex={-1} style={{ cursor: "pointer" }} >
					<TableCell component={Link} to={rowClick}><AssetCard item={item} /></TableCell>
					<TableCell component={Link} to={rowClick}>{item?.msn ? item?.msn : item?.asset_name || '--'}</TableCell>
					<TableCell component={Link} to={rowClick}>{item?.lessee ? item?.lessee?.name : '--'}</TableCell>
					<TableCell component={Link} to={rowClick}>{item?.operator?.name || '--'}</TableCell>
					<TableCell component={Link} to={rowClick}>{item.status && item.status.value === 1 ? (<p className="status on-lease">ON LEASE</p>) : null}
						{item.status && item.status.value === 2 ? (<p className="status off-lease">OFF LEASE</p>) : null}
						{item.status && item.status.value === 3 ? (<p className="status on-ground">In Storage</p>) : null}
						{item.status && item.status.value === 4 ? (<p className="status sold">WRITTEN OFF/ SOLD</p>) : null}
						{item.status && item.status.value === 0 ? (<p className="status sold">Draft</p>) : null}</TableCell>
					<TableCell  component={Link} to={rowClick}>{item?.on_wing_status ? item?.on_wing_msn ? item?.on_wing_msn : "On Wing" :  "Off Wing"}</TableCell>
					<TableCell>
						{assetTypeValues && assetTypeValues[item.asset_type] && assetTypeValues[item.asset_type].urlType ? (
							<>
								<ul className='list-inline'>
									<li className='list-inline-item' style={{ cursor: 'pointer', position: 'relative', marginRight: '7px' }}>
										<Tooltip title="Display Asset" arrow>
											<IconButton className="action-icon" component={Link} to={item.asset_type === 1 ? `/${item.id}/${item.asset_type}/amp/list` : `/${item.id}/${item.asset_type}/op_history/list`}> <Visibility /></IconButton>
										</Tooltip>
									</li>
									{/* <li className='list-inline-item' style={{ cursor: 'pointer', position: 'relative', }}>
										<Tooltip title='Technical Specification' arrow>
											<a href={`${config.domain.subDomian}technical-specification/${assetType[item.asset_type]}/${item.slug}`} target="_blank"><FlightIcon fontSize='small' color='primary' /></a>
										</Tooltip>
									</li> */}
								</ul>

							</>) : null}
					</TableCell>
				</TableRow>
				:
				<TableRow hover tabIndex={-1} style={{ cursor: "pointer" }} >
					<TableCell component={Link} to={rowClick}><AssetCard item={item} /></TableCell>
					<TableCell component={Link} to={rowClick}>{item?.msn ? item?.msn : item?.asset_name || '--'}</TableCell>
					{tabIndex === 2 ? null : <TableCell component={Link} to={rowClick}>{item.current_registration_number ? item.current_registration_number : "--"}</TableCell>}
					<TableCell component={Link} to={rowClick}>{item.lessor_name && item.lessor_name.name ? item.lessor_name.name : "--"}</TableCell>
					<TableCell component={Link} to={rowClick}>{item.owner && item.owner.name ? item.owner.name : "--"}</TableCell>
					<TableCell component={Link} to={rowClick}><Tooltip title={item.country ? item.country : "--"} arrow><span>{truncatedCountry ? truncatedCountry : "--"}</span></Tooltip></TableCell>
					<TableCell component={Link} to={rowClick}>{item.status && item.status.value === 1 ? (<p className="status on-lease">ON LEASE</p>) : null}
						{item.status && item.status.value === 2 ? (<p className="status off-lease">OFF LEASE</p>) : null}
						{item.status && item.status.value === 3 ? (<p className="status on-ground">In Storage</p>) : null}
						{item.status && item.status.value === 4 ? (<p className="status sold">WRITTEN OFF/ SOLD</p>) : null}
						{item.status && item.status.value === 0 ? (<p className="status sold">Draft</p>) : null}</TableCell>
					<TableCell>
						{assetTypeValues && assetTypeValues[item.asset_type] && assetTypeValues[item.asset_type].urlType ? (
							<>
								<ul className='list-inline'>
									<li className='list-inline-item' style={{ cursor: 'pointer', position: 'relative', marginRight: '7px' }}>
										<Tooltip title="Display Asset" arrow>
											<IconButton className="action-icon" component={Link} to={item.asset_type === 1 ? `/${item.id}/${item.asset_type}/amp/list` : `/${item.id}/${item.asset_type}/ad/list`}> <Visibility /></IconButton>
										</Tooltip>
									</li>
									<li className='list-inline-item' style={{ cursor: 'pointer', position: 'relative', }}>
										<Tooltip title='Technical Specification' arrow>
											<a href={`${config.domain.subDomian}technical-specification/${assetType[item.asset_type]}/${item.slug}`} target="_blank"><FlightIcon fontSize='small' color='primary' /></a>
										</Tooltip>
									</li>
								</ul>

							</>) : null}
					</TableCell>
				</TableRow>
			}
		</>
	)
}
export default withRouter(AssetList)