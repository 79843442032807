import React, { Fragment, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DownloadXLSAssetDetail from "../../../../shared_elements/DownloadXLSAssetDetail";
import AddIcon from "@material-ui/icons/Add";
import { isMobile } from "react-device-detect";
import { Button, Dialog, TextField, Menu, MenuItem, Tooltip } from "@material-ui/core";
import AdTaskForm from "./AdTaskForm";
import { getLocalStorageInfo } from "../../../../utils";
import config from "../../../../config"
import DownloadSample from "../../../../shared_elements/DownloadSample";
import { DownloadAdSampleFile } from "../apiService";
import { convertFilterObject, removeEmptyKey, permissionCheckFnforCamo, } from "../../../../utils";
import DownloadPDFAssetDetail from "../../../../shared_elements/components/DownloadPDFAssetDetail";


const AdSearchBar = ({ applyingFilter, filterOptions, tabIndex, pageLoader, props, searchData, fileUploadData, importFile, exportReportFn, headerTsn, headerCsn, currentRecordPerPage, uuid ,slug,getResponseBack,basicDetails}) => {
    const [open, setOpen] = useState(false);
    const [menuAnchorEl, menuSetAnchorEl] = useState(null);
    const [exportAnchorEl, exportSetAnchorEl] = useState(null);
    const [downloadAnchorEl, downloadSetAnchorEl] = useState(null);
    const [formTitle, setFormTitle] = useState("");
    const [actionType, setActionType] = useState("");

    const openMenu = Boolean(menuAnchorEl);
    const openExportMenu = Boolean(exportAnchorEl);
    const openDownloadMenu = Boolean(downloadAnchorEl);

    const handlefilterButtonClick = (event) => {
        menuSetAnchorEl(event.currentTarget);
    };

    const onHandleClose = () => {
        menuSetAnchorEl(null);
    };

    const addNewTask = () => {
        setOpen(true);
        setFormTitle("Airworthiness Directives (ADs)");
        setActionType("add");
    };

    const closeAddForm = () => {
        setOpen(false);
    };

    const importFileFunc = (e) => {
        const files = e.target.files;
        const formData = new FormData();
        const fileType = files[0]?.type;
        if ( !fileType.endsWith("application/vnd.ms-excel") ) {
            formData.append("file", files[0]);
            importFile(formData, props, false);
            e.target.value = null;
        } else {
            formData.append("file", files[0]);
            importFile(formData, props, true);
            e.target.value = null;
        }
    };

    const onExportHandleClose = () => {
        exportSetAnchorEl(null);
    };

    const onExportHandleClick = (event) => {
        exportSetAnchorEl(event.currentTarget);
    };

    const ondownloadHandleClose = () => {
        downloadSetAnchorEl(null);
    };
    const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)
    return (
        <Fragment>
            <div id="AmpSearchBar">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <div>
                            <Button
                                id="basic-button"
                                className="menu-btn"
                                aria-controls={openMenu ? "basic-menu" : ""}
                                aria-haspopup="true"
                                aria-expanded={openMenu ? "true" : ""}
                                onClick={handlefilterButtonClick}
                            >
                                <FilterListIcon />
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={menuAnchorEl}
                                open={openMenu}
                                onClose={onHandleClose}
                                MenuListProps={{ "aria-labelledby": "basic-button", }}
                            >
                                <MenuItem onClick={onHandleClose}>Over due tasks</MenuItem>
                                <MenuItem onClick={onHandleClose}>Tasks</MenuItem>
                                <MenuItem onClick={onHandleClose}>All tasks</MenuItem>
                            </Menu>
                        </div>
                        <div className="search-input">
                            <TextField
                                id="search"
                                placeholder={smbcInstance ? "Search by  AD Description ": "Search ATA Chapter, AD Reference Number, AD Issuing Authority, AD Description"}
                                onChange={searchData}
                            />
                            <Tooltip
                                title={smbcInstance ? "Search by  AD Description ":"Search ATA Chapter, AD Reference Number, AD Issuing Authority, AD Description"}
                                variant="contained"
                            >
                                <SearchIcon className="search-icon" />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        {permissionCheckFnforCamo({
                            primaryKey: "ad_status",
                            keyIndex: "C",
                        }) ? (
                            <Tooltip title="ADD AD STATUS">
                                <Button
                                    onClick={addNewTask}
                                    variant="contained"
                                    color="primary"
                                >
                                    <AddIcon style={{ marginTop: "-1px" }} className="add-icon" />
                                     ADD AD STATUS
                                </Button>
                            </Tooltip>
                        ) : null}
                        <Button variant="outlined" color="primary" component="label">
                            <span>IMPORT</span>
                            <input
                                className="upload-input"
                                onChange={(e) => importFileFunc(e)}
                                id="fileUplaod"
                                name="file"
                                type="file"
                                accept="application/vnd.ms-excel"
                            />
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            id="basic-button"
                            aria-controls={openExportMenu ? "basic-menu" : ""}
                            aria-haspopup="true"
                            aria-expanded={openExportMenu ? "true" : ""}
                            onClick={onExportHandleClick}
                        >
                            EXPORT
                            <KeyboardArrowDownIcon style={{ marginTop: "-1px" }} />
                        </Button>
                        <Menu
                            className="import-menu-item export"
                            id="basic-menu"
                            anchorEl={exportAnchorEl}
                            open={openExportMenu}
                            onClose={onExportHandleClose}
                            MenuListProps={{ "aria-labelledby": "basic-button", }}
                        >
                            <MenuItem className="export-list">
                                <DownloadXLSAssetDetail
                                    files={{ title: "EXCEL", extension: "xls", key: "" }}
                                    exportReportFn={exportReportFn}
                                />
                            </MenuItem>
                            <MenuItem className="export-list">
                                <DownloadPDFAssetDetail
                                    files={{ title: "PDF", extension: "pdf", key: "" }}
                                    exportReportFn={exportReportFn}
                                />
                            </MenuItem>
                        </Menu>

                        <DownloadSample
                            variant="outlined"
                            color="primary"
                            files={{ title: "DOWNLOAD SAMPLE", extension: "xls", key: "" }}
                            exportSample={(file) => DownloadAdSampleFile( { download: file.extension, ...{ ...convertFilterObject( removeEmptyKey(applyingFilter), filterOptions )}}, pageLoader, props ,basicDetails)
                            }
                        />
                        <Menu
                            className="import-menu-item export"
                            id="basic-menu"
                            anchorEl={downloadAnchorEl}
                            open={openDownloadMenu}
                            onClose={ondownloadHandleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem></MenuItem>
                        </Menu>
                        {/* download sample ends */}
                        <Dialog open={open}  style={{ height: isMobile && "650px" }}>
                            <AdTaskForm
                                formTitle={formTitle}
                                closeAddForm={() => closeAddForm()}
                                addForm={() => setOpen(true)}
                                props={props}
                                headerTsn={headerTsn}
                                headerCsn={headerCsn}
                                actionType={actionType}
                                fileUploadData={fileUploadData}
                                currentRecordPerPage={currentRecordPerPage}
                                uuid={uuid}
                                slug={slug}
                                basicDetails={basicDetails}
                                getResponseBack={() => getResponseBack()}
                            />
                        </Dialog>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AdSearchBar;
