import React, { Fragment, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import { convertFilterObject, removeEmptyKey, permissionCheckFnforCamo,getLocalStorageInfo } from "../../../../utils";
import FilterListIcon from "@material-ui/icons/FilterList";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Button, Dialog, TextField, Menu, MenuItem, Tooltip } from "@material-ui/core";
import InventoryTaskForm from "./InventoryTaskForm";
import { DownloadSampleFile } from "../apiServices";
import { isMobile } from "react-device-detect";
import DownloadXLSAssetDetail from "../../../../shared_elements/DownloadXLSAssetDetail";
import DownloadSample from "../../../../shared_elements/DownloadSample";
import DownloadPDFAssetDetail from "../../../../shared_elements/components/DownloadPDFAssetDetail";

const InventorySearchBar = ({ applyingFilter, filterOptions, tabIndex, pageLoader, props, searchData, fileUploadData, importFile, exportReportFn, headerTsn, headerCsn, currentRecordPerPage, date_of_manufacture, uuid, getResponseBack, assetsDetail,basicDetails }) => {
	const [open, setOpen] = useState(false);
	const [menuAnchorEl, menuSetAnchorEl] = useState(null);
	const [importAnchorEl, importSetAnchorEl] = useState(null);
	const [exportAnchorEl, exportSetAnchorEl] = useState(null);
	const [downloadAnchorEl, downloadSetAnchorEl] = useState(null);
	const [formTitle, setFormTitle] = useState("");
	const [actionType, setActionType] = useState("");

	const openMenu = Boolean(menuAnchorEl);
	const openExportMenu = Boolean(exportAnchorEl);
	const openDownloadMenu = Boolean(downloadAnchorEl);

	const handlefilterButtonClick = (event) => {
		menuSetAnchorEl(event.currentTarget);
	};

	const onHandleClose = () => {
		menuSetAnchorEl(null);
	};

	const addNewTask = () => {
		setOpen(true);
		setFormTitle("Add Inventory ");
		setActionType("add");
	};

	const closeAddForm = () => {
		setOpen(false);
	};

	const importFileFunc = (e) => {
		const files = e.target.files;
		const formData = new FormData();
		const fileType = files[0].type;
		if ( !fileType.endsWith("application/vnd.ms-excel") ) {
            formData.append("file", files[0]);
            importFile(formData, props, false);
            e.target.value = null;
        } else {
            formData.append("file", files[0]);
            importFile(formData, props, true);
            e.target.value = null;
        }
	};

	const onExportHandleClose = () => {
		exportSetAnchorEl(null);
	};

	const onExportHandleClick = (event) => {
		exportSetAnchorEl(event.currentTarget);
	};

	const ondownloadHandleClose = () => {
		downloadSetAnchorEl(null);
	};
	const boeingInstance = getLocalStorageInfo()?.defaultLessor?.id === 475
	return (
		<Fragment>
			<div id="AmpSearchBar">
				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex align-items-center">
						<div className="search-input">
							<TextField
								id="search"
								placeholder="Search Part Number Serial Number,Description"
								onChange={searchData}
							/>
							<Tooltip
								title="Search Part Number Serial Number,Description"
								variant="contained"
							>
								<SearchIcon className="search-icon" />
							</Tooltip>
						</div>
					</div>
					<div className="btn-wrapper">
						{window?.location?.search.includes('archived-assets') ? null : (
							<Tooltip title="ADD INVENTORY LIST">
								<Button
									onClick={addNewTask}
									variant="contained"
									color="primary"
									size="small"
								>
									<AddIcon style={{ marginTop: "-1px" }} className="add-icon" />
									ADD INVENTORY LIST
								</Button>
							</Tooltip>
						)}
					{ window?.location?.search.includes('archived-assets')  || boeingInstance ? null :	<Button variant="outlined" color="primary" component="label">
							<span>IMPORT</span>
							<input
								className="upload-input"
								onChange={(e) => importFileFunc(e)}
								id="fileUplaod"
								name="file"
								type="file"
								 accept="application/vnd.ms-excel"
							/>
						</Button>}
						<Button
							variant="outlined"
							color="primary"
							id="basic-button"
							aria-controls={openExportMenu ? "basic-menu" : ""}
							aria-haspopup="true"
							aria-expanded={openExportMenu ? "true" : ""}
							onClick={onExportHandleClick}
						>
							EXPORT
							<KeyboardArrowDownIcon style={{ marginTop: "-1px" }} />
						</Button>
						<Menu
							className="import-menu-item export"
							id="basic-menu"
							anchorEl={exportAnchorEl}
							open={openExportMenu}
							onClose={onExportHandleClose}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
						>
							<MenuItem className="export-list">
								<DownloadXLSAssetDetail
									files={{ title: "EXCEL", extension: "xls", key: "" }}
									exportReportFn={exportReportFn}
								/>
							</MenuItem>
							<MenuItem className="export-list">
								<DownloadPDFAssetDetail
									files={{ title: "PDF", extension: "pdf", key: "" }}
									exportReportFn={exportReportFn}
								/>
							</MenuItem>
						</Menu>
					{boeingInstance ? null:	<DownloadSample
							variant="outlined"
							color="primary"
							files={{ title: "DOWNLOAD SAMPLE", extension: "xls", key: "" }}
							exportSample={(file) =>
								DownloadSampleFile(
									{ download: file.extension, ...{ ...convertFilterObject( removeEmptyKey(applyingFilter), filterOptions ), }, },
									pageLoader,
									props,
									basicDetails
								)
							}
						/>}
						<Dialog open={open}  style={{ height: isMobile && "650px" }}>
							<InventoryTaskForm
								date_of_manufacture={date_of_manufacture}
								headerTsn={headerTsn}
								headerCsn={headerCsn}
								formTitle={formTitle}
								closeAddForm={() => closeAddForm()}
								addForm={()=>setOpen(true)}
								props={props}
								actionType={actionType}
								fileUploadData={fileUploadData}
								currentRecordPerPage={currentRecordPerPage}
								uuid={uuid}
								getResponseBack={() => getResponseBack()}
							/>
						</Dialog>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default InventorySearchBar;