import EngineOffwing from './containers/EngineOffwing';
import PageLayout from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
import CustomAlerts from './containers/CustomAlerts';
import EngineStandBag from './containers/EngineStandBag';
export const reportsRoutes = [
	{
		path: '/reports/engOffWing',
		component: PageLayout(userRoleBaseAccessHoc(EngineOffwing, []), { apps: 'Engine-Wing', layoutPhase: 1 }),
		key: 'Engine-Wing'
	},
	{
		path: '/reports/engStandBag',
		component: PageLayout(userRoleBaseAccessHoc(EngineStandBag, []), { apps: 'Stand-Bag', layoutPhase: 1 }),
		key: 'Stand-Bag'
	},
	{
		path: '/reports/custom-alerts',
		component: PageLayout(userRoleBaseAccessHoc(CustomAlerts, []), { apps: 'Custom-Alerts', layoutPhase: 1 }),
		key: 'Custom-Alerts'
	},
]
