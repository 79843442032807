import React, { useState, useEffect, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Button, DialogTitle, DialogContent, DialogActions, TextField, } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalPutService, globalGetService } from '../../utils/globalApiServices';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { checkApiStatus } from '../../utils';
import { dayMonthDateFormat, backendDateFormat } from '../../constants';
import { regexConstants } from '../../constants/regEx';
import { PageLoader } from '../../shared_elements';
import { engineDetailObj, offWingObj } from '.';
import Switch from '@material-ui/core/Switch';
import EngOnOffWing from './EngOnOffWing';
import { object } from 'prop-types';

function EngineDetailForm({ slug, closeUpdateForm, basicDetailData, getResponseBack, assetDetail, assets, svData, pltConstants, }) {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(basicDetailData ? basicDetailData : engineDetailObj);
    const [error, setError] = useState({});
    const [dropDownList, setDropDownList] = useState([])
    const [pageLoader, setPageLoader] = useState(false)

    const todayDate = moment(new Date()).format(backendDateFormat);
    const leaseDate = data?.new_tec_spec?.lease_term_date || basicDetailData?.new_tec_spec?.lease_term_date;
    const monthRemaining = moment(leaseDate, backendDateFormat).diff(moment(todayDate, backendDateFormat), 'month');

    // const leaseStartDate =data?.lease_start_date || basicDetailData?.new_tec_spec?.lease_start_date;
    // const leaseDate =  data?.lease_term_date || basicDetailData?.new_tec_spec?.lease_term_date;
    // const monthRemaining = moment(leaseDate, backendDateFormat).diff(moment(leaseStartDate, backendDateFormat), 'month')

    useEffect(() => {
        allDropDownList()
    }, [])
    const onFieldChange = (keyParam, value, extraProps) => {
        if (extraProps) {
            if (keyParam === "toggle_mtbr_mcbr") {
                setData({
                    ...data,
                    [extraProps]: {
                        ...data[extraProps],
                        [keyParam]: !value,
                        "smbc_forecast_mcbr": '',
                        "smbc_forecast_mtbr": ''
                    }
                })
            } else {
                setData({
                    ...data,
                    [extraProps]: {
                        ...data[extraProps],
                        [keyParam]: value
                    }

                })
            }
        } else {
            if (keyParam === 'off_wing_status') {
                setData({ ...data, [keyParam]: value, off_wing_detail: offWingObj });
            } else if (keyParam === 'on_wing_status') {
                setData({ ...data, [keyParam]: value, off_wing_detail: offWingObj, off_wing_status: null, on_wing_msn: '', on_wing_lessor: '' });
            } else {
                setData({ ...data, [keyParam]: value })
            }
        }
    }

    const onRestErrorKey = (keyParam) => {
        setError({ ...error, [keyParam]: '' })

    }

    const onAddNewAsset = () => {
        setPageLoader(true)
        let payload = Object.assign({}, data)
        payload = {
            ...payload,
            id: data.id || null,
            slug: data.slug || null,
            location: assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] ? assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] : data.country || null,
            operator: data?.operator || null,
            lessee: data?.lessee || null,
            position: assets?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] ? assets?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] : data.position || null,
            lessor_name: data?.lessor_name || null,
            delivery_date_to_current_operator: data?.delivery_date_to_current_operator || null,
            average_monthly_hours: assets?.sort((a, b) => b - a).map(filterId => filterId.monthly_FH)[0] || null,
            average_monthly_cycles: assets?.sort((a, b) => b - a).map(filterId => filterId.monthly_FC)[0] || null,
            purchase_date: data?.purchase_date || null,
            next_delivery_date: data?.next_delivery_date || null,
            country: assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] ? assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] : data.country || null,
            region: data?.region || null,
            date_of_installation: data?.date_of_installation || null,
            tslsv: data?.tslsv || null,
            cslsv: data?.cslsv || null,
            owner: data?.owner || null,
            sub_status: ({ value: data?.sub_status?.id || data?.sub_status?.value }) || null,
            status: ({ value: data?.status?.id || data?.status?.value, label: data?.label }) || null,
            llp_limiter: data?.llp_limiter || null,
            Soft_time_limiter: data?.Soft_time_limiter || null,
            avg_flight_ratio: data?.avg_flight_ratio || null,
            new_tec_spec: {
                aircraft_model: data?.new_tec_spec?.aircraft_model || null,
                owner_aircraft: data?.new_tec_spec?.owner_aircraft || null,
                location_reg_aircraft: assets?.sort((a, b) => b - a).map(filterId => filterId?.location_reg_aircraft)[0] || null,
                date_of_removal: data?.new_tec_spec?.date_of_removal || null,
                avg_derate: +data?.new_tec_spec.avg_derate || 0,
                engine_stand_part_number: data?.new_tec_spec?.engine_stand_part_number || null,
                engine_stand_serial_number: data?.new_tec_spec.engine_stand_serial_number || null,
                engine_stand_location: data?.new_tec_spec?.engine_stand_location || null,
                qec_config: data?.new_tec_spec.qec_config || null,
                qec_part_number: data?.new_tec_spec.qec_part_number || null,
                min_llp_prior_to_term_date: data?.new_tec_spec?.min_llp_prior_to_term_date || null,
                lease_start_date: data?.new_tec_spec?.lease_start_date || null,
                lease_term_date: data?.new_tec_spec?.lease_term_date || null,
                redelivery_date: data?.new_tec_spec?.redelivery_date || null,
                eec_software_part_number: data?.new_tec_spec.eec_software_part_number || null,
                eec_software_version: data.new_tec_spec?.eec_software_version || null,
                monthly_use_fee: data.new_tec_spec?.monthly_use_fee || '',
                monthly_llp_fee: data.new_tec_spec?.monthly_llp_fee || '',
                accumulated_mr: data.new_tec_spec?.accumulated_mr || '',
                month_remaining: monthRemaining ? monthRemaining : 0,
                preservation_date: data?.new_tec_spec?.preservation_date,
                preservation_expiry_date: data?.new_tec_spec?.preservation_expiry_date,
                bag: data.new_tec_spec?.bag,
                stand: data.new_tec_spec?.stand,
                engine_stand_base_sn: data?.new_tec_spec?.engine_stand_base_sn || null,
                engine_stand_base_pn: data?.new_tec_spec?.engine_stand_base_pn || null,
                engine_stand_cradle_sn: data?.new_tec_spec?.engine_stand_cradle_sn || null,
                engine_stand_cradle_pn: data?.new_tec_spec?.engine_stand_cradle_pn || null,
                operating_region: data?.new_tec_spec?.operating_region || null,
                sv_interval_remaining: svInterRem()?.length != 0 ? svInterRem().toFixed(2) > 0 ? svInterRem().toFixed(2) : "0" : null,
                toggle_mtbr_mcbr: data?.new_tec_spec?.toggle_mtbr_mcbr,
                smbc_forecast_mcbr: data?.new_tec_spec?.toggle_mtbr_mcbr ? data?.new_tec_spec?.smbc_forecast_mcbr ? parseInt(data?.new_tec_spec?.smbc_forecast_mcbr) : null : data?.new_tec_spec?.smbc_forecast_mtbr ? parseInt(parseInt(data?.new_tec_spec?.smbc_forecast_mtbr).toFixed(2) / (assetDetail?.utilization_ratio)) : null, // making similarity FC unit
                smbc_forecast_mtbr: data?.new_tec_spec?.toggle_mtbr_mcbr ? data?.new_tec_spec?.smbc_forecast_mcbr ? parseFloat(parseInt(data?.new_tec_spec?.smbc_forecast_mcbr).toFixed(2) * parseFloat(assetDetail?.utilization_ratio).toFixed(2)) : null : data?.new_tec_spec?.smbc_forecast_mtbr ? parseFloat(data?.new_tec_spec?.smbc_forecast_mtbr) : null,
                engine_stand_color: data?.new_tec_spec?.engine_stand_color || null,
                update_date: data?.new_tec_spec?.update_date || null,
                comments: data?.new_tec_spec?.comments || null,
                comment: data?.new_tec_spec?.comment || null,
                boot_strape_pn: data?.new_tec_spec?.boot_strape_pn || null,
                blanks: data?.new_tec_spec?.blanks || null,
                service_date: data?.new_tec_spec?.service_date || null,
                shock_mount_exp_date: data?.new_tec_spec?.shock_mount_exp_date || null,
                shock_mount_limit: data?.new_tec_spec?.shock_mount_limit || null,
                shock_mount_mfg_date: data?.new_tec_spec?.shock_mount_mfg_date || null,
                std_manufacture: data?.new_tec_spec?.std_manufacture || null,

            }
        };
        globalPutService(`camo/engine_tech_spec/${slug}/details/`, payload)
            .then((response) => {
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
                    closeUpdateForm(); getResponseBack()
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } })

                }
            })
    }
    const allDropDownList = () => {
        globalGetService(`camo/all_dropdown_list/`)
            .then(response => {
                if (response?.status === 200) {
                    setDropDownList(response.data)
                }
            })
    }

    const stand_and_bag = ['YES', 'NO', 'N/A']

    const svInterRem = () => {
        if (assetDetail?.sv_data) {
            if (data?.new_tec_spec?.toggle_mtbr_mcbr) {
                return data.new_tec_spec.sv_interval_remaining = data?.new_tec_spec?.smbc_forecast_mcbr - assetDetail?.cslv || ''
            } else {
                return data.new_tec_spec.sv_interval_remaining = (mcbrValue() > 0 ? parseInt(mcbrValue()) : 0) - assetDetail?.cslv || ''
            }
        } else {
            if (data?.new_tec_spec?.toggle_mtbr_mcbr) {
                return data.new_tec_spec.sv_interval_remaining = data?.new_tec_spec?.smbc_forecast_mcbr - assetDetail?.csn || ''
            } else {
                return data.new_tec_spec.sv_interval_remaining = (mcbrValue() > 0 ? parseInt(mcbrValue()) : 0) - assetDetail?.csn || ''
            }
        }
    }
    const mcbrValue = () => {
        if (data?.new_tec_spec?.toggle_mtbr_mcbr) {
            return data.new_tec_spec.smbc_forecast_mcbr = data?.new_tec_spec.smbc_forecast_mcbr ? data?.new_tec_spec?.smbc_forecast_mcbr : ""
        } else {
            return data.new_tec_spec.smbc_forecast_mcbr = data?.new_tec_spec.smbc_forecast_mtbr ? (parseInt(data?.new_tec_spec?.smbc_forecast_mtbr) / (assetDetail?.utilization_ratio)).toFixed(0) : ""
        }
    }

    const mcbtValue = () => {
        if (data?.new_tec_spec?.toggle_mtbr_mcbr) {
            return data.new_tec_spec.smbc_forecast_mtbr = data?.new_tec_spec?.smbc_forecast_mcbr ? (parseFloat(data?.new_tec_spec?.smbc_forecast_mcbr) * assetDetail?.utilization_ratio).toFixed(2) : ''
        } else {
            return data.new_tec_spec.smbc_forecast_mtbr = data?.new_tec_spec?.smbc_forecast_mtbr ? data?.new_tec_spec?.smbc_forecast_mtbr : ""
        }
    }

    const forcasteCal = ((svInterRem() / assetDetail.utilization_fh_avg) * 30.44) > 0 ? ((svInterRem() / assetDetail.utilization_fh_avg) * 30.44)  : 0
    const newDate = moment(new Date()).add(parseInt(forcasteCal), 'days').format(backendDateFormat);

    return (
        <div id="AmpTaskForm" className='engine-tech-spec'>
            <DialogTitle >
                Update Technical Specification
                <CloseIcon className="close-icon" onClick={closeUpdateForm} />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            id="new_tec_spec.aircraft_model"
                            label="A/C model"
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.aircraft_model || ""}
                            onChange={(e, value) => onFieldChange('aircraft_model', e.target.value, "new_tec_spec")}
                            onFocus={() => onRestErrorKey('aircraft_model')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList?.opertor_lessee_list || []}
                            getOptionLabel={option => option.name}
                            value={data?.lessee}
                            onChange={(e, value) => onFieldChange("lessee", value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('lessee')} {...params} label="Operator (Lessee)" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList?.operator_sub_Lessee || []}
                            getOptionLabel={option => option.name}
                            value={data?.operator}
                            onChange={(e, value) => onFieldChange("operator", value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('operator')} {...params} label="Operator ((Sub-Lessee))" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Owner A/C"
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.owner_aircraft || ""}
                            onChange={(e, value) => onFieldChange('owner_aircraft', e.target.value, "new_tec_spec")}
                            onFocus={() => onRestErrorKey('owner_aircraft')}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList?.owner_list || []}
                            getOptionLabel={option => option.name}
                            id="lessor_name"
                            value={data?.lessor_name}
                            onChange={(e, value) => onFieldChange('lessor_name', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('Lessor')} {...params} label="Lessor Name" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Delivery Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={data?.delivery_date_to_current_operator || null}
                                error={error?.delivery_date_to_current_operator ? true : false}
                                helperText={error?.delivery_date_to_current_operator ? error?.delivery_date_to_current_operator : ''}
                                onChange={(data, value) => onFieldChange('delivery_date_to_current_operator', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="Monthly EFH"
                            label="Monthly EFH"
                            fullWidth
                            margin="normal"
                            value={assets?.sort((a, b) => b - a).map(filterId => filterId?.monthly_FH)[0] || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Monthly EFC"
                            fullWidth
                            margin="normal"
                            value={assets?.sort((a, b) => b - a).map(filterId => filterId?.monthly_FC)[0] || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Date of Purchase"
                                format={dayMonthDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture).subtract(6, 'months')}
                                value={data?.purchase_date ? data?.purchase_date : null}
                                error={error?.purchase_date ? true : false}
                                helperText={error?.purchase_date ? error?.purchase_date : ''}
                                onChange={(data, value) => onFieldChange('purchase_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Next Delivery  Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={data?.next_delivery_date ? data?.next_delivery_date : null}
                                error={error?.next_delivery_date ? true : false}
                                helperText={error?.next_delivery_date ? error?.next_delivery_date : ''}
                                onChange={(data, value) => onFieldChange('next_delivery_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Locations (A/C Reg.)"
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.location_reg_aircraft ? data?.new_tec_spec?.location_reg_aircraft : data?.new_tec_spec?.location_reg_aircraft || assets?.sort((a, b) => b - a).map(filterId => filterId?.location_reg_aircraft)[0] || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList?.position_list || []}
                            getOptionLabel={option => option.label}
                            id="position"
                            disabled
                            value={assets?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] ? assets?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] : data?.position || null}
                            // onChange={(e, value) => onFieldChange('position', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('position')} {...params} label="Locations (Position)" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="Locations (Country)"
                            fullWidth
                            margin="normal"
                            value={assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] ? assets?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] : data?.country || "--"}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            fullWidth
                            options={dropDownList?.region_list || []}
                            getOptionLabel={(option) => option?.name || ''}
                            id="operating_region"
                            value={data?.new_tec_spec?.operating_region || null}
                            onChange={(e, value) => onFieldChange('operating_region', value, "new_tec_spec")}
                            renderInput={params => <TextField  {...params} label="Operating Region" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            id="operating_environment"
                            options={['Neutral', 'Middle East/North Africa', 'India', 'China']}
                            getOptionLabel={option => option}
                            value={data?.new_tec_spec?.operating_environment}
                            disabled
                            renderInput={params => <TextField  {...params} label="Operating Enviroment " margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Date of installation"
                                format={dayMonthDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={data?.date_of_installation ? data?.date_of_installation : null}
                                error={error?.date_of_installation ? true : false}
                                helperText={error?.date_of_installation ? error?.date_of_installation : ''}
                                onChange={(data, value) => onFieldChange('date_of_installation', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Date of Removal"
                                format={dayMonthDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={data?.new_tec_spec?.date_of_removal || null}
                                error={error?.new_tec_spec?.date_of_removal ? true : false}
                                helperText={error.new_tec_spec?.date_of_removal ? error?.new_tec_spec?.date_of_removal : ''}
                                onChange={(data, value) => onFieldChange('date_of_removal', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item md={3}>
                        <TextField
                            id="avg_flight_ratio"
                            label="Avg. Flight Ratio"
                            fullWidth
                            margin="normal"
                            value={data?.avg_flight_ratio ? (data?.avg_flight_ratio).toFixed(2) : ""}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            id="avg_derate"
                            label="Avg. Derate %"
                            fullWidth
                            margin="normal"
                            value={data.new_tec_spec?.avg_derate || ""}
                            onFocus={() => onRestErrorKey('avg_derate')}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            onChange={(e) => {
                                const value = e.target.value;
                                const numericValue = value.replace(/[^\d.]/g, '');
                                const dotCount = (numericValue.match(/\./g) || []).length;
                                const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 3 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 2 && regexConstants.numberWithDot.test(numericValue);
                                if (isValid || value === "") {
                                    onFieldChange("avg_derate", numericValue, "new_tec_spec");
                                }
                                onRestErrorKey("avg_derate");
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="TSLSV"
                            fullWidth
                            margin="normal"
                            value={data?.tslsv || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="CSLSV"
                            fullWidth
                            margin="normal"
                            value={data?.cslsv || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="LLP Limiter(s)"
                            fullWidth
                            margin="normal"
                            value={data?.llp_limiter || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Soft-time Limiter(s)"
                            fullWidth
                            margin="normal"
                            value={data?.Soft_time_limiter}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList.owner_list || []}
                            getOptionLabel={option => option.name}
                            id="owner"
                            value={data?.owner}
                            onChange={(e, value) => onFieldChange('owner', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('owner')} {...params} label="Asset Owner" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="Engine Stand Prime P/N"
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.engine_stand_part_number || ""}
                            onChange={(e, value) => onFieldChange('engine_stand_part_number', e.target.value, "new_tec_spec")}
                            onFocus={() => onRestErrorKey('engine_stand_part_number')}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'

                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Engine Stand Prime S/N"
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.engine_stand_serial_number || ""}
                            onChange={(e, value) => onFieldChange('engine_stand_serial_number', e.target.value, "new_tec_spec")}
                            onFocus={() => onRestErrorKey('engine_stand_serial_number')}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'

                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Engine Stand Base P/N"
                            variant='outlined'
                            margin="normal"
                            fullWidth
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            value={data?.new_tec_spec?.engine_stand_base_pn || ""}
                            onChange={(e, value) => onFieldChange('engine_stand_base_pn', e.target.value, "new_tec_spec")}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Engine Stand Base S/N "
                            margin="normal"
                            variant='outlined'
                            fullWidth
                            inputProps={{ maxLength: 20 }}
                            value={data?.new_tec_spec?.engine_stand_base_sn || ""}
                            onChange={(e, value) => onFieldChange('engine_stand_base_sn', e.target.value, "new_tec_spec")}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="Engine Stand Cradle P/N"
                            margin="normal"
                            variant='outlined'
                            fullWidth
                            inputProps={{ maxLength: 20 }}
                            value={data?.new_tec_spec?.engine_stand_cradle_pn || ""}
                            onChange={(e, value) => onFieldChange('engine_stand_cradle_pn', e.target.value, "new_tec_spec")}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Engine Stand Cradle S/N"
                            variant='outlined'
                            margin="normal"
                            fullWidth
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            value={data?.new_tec_spec?.engine_stand_cradle_sn || ""}
                            onChange={(e, value) => onFieldChange('engine_stand_cradle_sn', e.target.value, "new_tec_spec")}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Engine Stand Location"
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.engine_stand_location || ""}
                            onChange={(e, value) => onFieldChange('engine_stand_location', e.target.value, "new_tec_spec")}
                            onFocus={() => onRestErrorKey('engine_stand_location')}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="QEC Config."
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.qec_config || ""}
                            onChange={(e, value) => onFieldChange('qec_config', e.target.value, "new_tec_spec")}
                            onFocus={() => onRestErrorKey('qec_config')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="QEC P/N."
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.qec_part_number || ""}
                            onChange={(e, value) => onFieldChange('qec_part_number', e.target.value, "new_tec_spec")}
                            onFocus={() => onRestErrorKey('qec_part_number')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="SV Forecast Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                value={moment(newDate).format(dayMonthDateFormat)}
                                error={false}
                                helperText={''}
                                disabled
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList.asset_sub_status_list || []}
                            getOptionLabel={option => option.label}
                            id="sub_status"
                            value={data?.sub_status}
                            onChange={(e, value) => onFieldChange('sub_status', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('sub_status')} {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Lease Start Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail.date_of_manufacture)}
                                value={data?.new_tec_spec?.lease_start_date || null}
                                error={error?.new_tec_spec?.lease_start_date ? true : false}
                                helperText={error.new_tec_spec?.lease_start_date ? error?.new_tec_spec?.lease_start_date : ''}
                                onChange={(data, value) => onFieldChange('lease_start_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Lease Term Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment().subtract(25, 'years')}
                                value={data?.new_tec_spec?.lease_term_date || null}
                                error={error?.new_tec_spec?.lease_term_date ? true : false}
                                helperText={error.new_tec_spec?.lease_term_date ? error?.new_tec_spec?.lease_term_date : ''}
                                onChange={(data, value) => onFieldChange('lease_term_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                label="Redelivery Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment().subtract(25, 'years')}
                                value={data?.new_tec_spec?.redelivery_date || null}
                                error={error?.new_tec_spec?.redelivery_date ? true : false}
                                helperText={error.new_tec_spec?.redelivery_date ? error?.new_tec_spec?.redelivery_date : ''}
                                onChange={(data, value) => onFieldChange('redelivery_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Month Remaining"
                            fullWidth
                            margin="normal"
                            value={monthRemaining >= 0 ? monthRemaining : 0}
                            inputProps={{ maxLength: 20 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={dropDownList.asset_status_list || []}
                            getOptionLabel={option => option.label}
                            id="status"
                            value={data?.status}
                            onChange={(e, value) => onFieldChange('status', value)}
                            renderInput={params => <TextField onFocus={() => onRestErrorKey('status')} {...params} label="On/Off Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>

                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="EEC Software P/N"
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.eec_software_part_number || ""}
                            onChange={(e, value) => onFieldChange('eec_software_part_number', e.target.value, "new_tec_spec")}
                            onFocus={() => onRestErrorKey('eec_software_part_number')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="EEC Software Version"
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.eec_software_version || ""}
                            onChange={(e, value) => onFieldChange('eec_software_version', e.target.value, "new_tec_spec")}
                            onFocus={() => onRestErrorKey('eec_software_version')}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Monthly Use Fee"
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.monthly_use_fee || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="Monthly LLP Fee"
                            fullWidth
                            margin="normal"
                            value={data?.new_tec_spec?.monthly_llp_fee || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Autocomplete
                            options={stand_and_bag || []}
                            getOptionLabel={(option) => option}
                            value={data?.new_tec_spec?.stand || null}
                            onChange={(e, value) => onFieldChange('stand', value, "new_tec_spec")}
                            InputLabelProps={{ shrink: true }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Stand"
                                    margin="normal"
                                    fullWidth
                                    placeholder="Select Stand"
                                    variant="outlined"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            options={stand_and_bag || []}
                            getOptionLabel={(option) => option}
                            value={data?.new_tec_spec?.bag || null}
                            onChange={(e, value) => onFieldChange('bag', value, "new_tec_spec")}
                            InputLabelProps={{ shrink: true }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Bag/Cover"
                                    margin="normal"
                                    fullWidth
                                    placeholder="Select Bag"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Preservation Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={data?.new_tec_spec?.preservation_date ? data?.new_tec_spec?.preservation_date : null}
                                error={false}
                                helperText={''}
                                onChange={(data, value) => onFieldChange('preservation_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Preservation Expiry Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(data?.new_tec_spec?.preservation_date ? data?.new_tec_spec?.preservation_date : assetDetail?.date_of_manufacture)}
                                value={data?.new_tec_spec?.preservation_expiry_date ? data?.new_tec_spec?.preservation_expiry_date : null}
                                error={false}
                                helperText={''}
                                onChange={(data, value) => onFieldChange('preservation_expiry_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            label="Accumulated MR"
                            fullWidth
                            margin="normal"
                            value={data?.accumulated_mr || ""}
                            inputProps={{ maxLength: 25 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            label="SV Interval Remaining"
                            fullWidth
                            margin="normal"
                            value={svInterRem()?.length != 0 ? svInterRem().toFixed(2) > 0 ? svInterRem().toFixed(2) : "0" : 0}
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            disabled
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <h5 style={{ color: '#0000008a' }}>MTBR/ MCBR</h5>
                        <Switch
                            checked={data?.new_tec_spec?.toggle_mtbr_mcbr}
                            onChange={() => onFieldChange("toggle_mtbr_mcbr", data?.new_tec_spec?.toggle_mtbr_mcbr, "new_tec_spec")}
                            color="primary"
                            id="toggle_mtbr_mcbr"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="MCBR"
                            margin='normal'
                            variant='outlined'
                            fullWidth
                            value={mcbrValue() === 'Infinity' ? 0 : mcbrValue()}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: regexConstants.onlyNumeric.test(data?.new_tec_spec?.smbc_forecast_mcbr) && 5 }}
                            disabled={!data?.new_tec_spec?.toggle_mtbr_mcbr}
                            onChange={(e) => {
                                if (regexConstants.onlyCharacter.test(e.target.value)) {
                                } else {
                                    (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange("smbc_forecast_mcbr", e.target.value, "new_tec_spec")
                                }
                            }
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="MTBR"
                            margin='normal'
                            variant='outlined'
                            fullWidth
                            value={mcbtValue()}
                            // value={data?.new_tec_spec?.smbc_forecast_mtbr}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: regexConstants.numberWithDot.test(data?.new_tec_spec?.smbc_forecast_mtbr) && 8 }}
                            disabled={data?.new_tec_spec?.toggle_mtbr_mcbr}
                            onChange={(e) => {
                                const value = (e.target.value).replace(/[^0-9.]/g, '');
                                const numericValue = value.replace(/[^\d.]/g, '');
                                const dotCount = (numericValue.match(/\./g) || []).length;
                                const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                if (regexConstants.onlyCharacter.test(e.target.value)) {
                                } else if (isValid || value === "") {
                                    onFieldChange("smbc_forecast_mtbr", numericValue, "new_tec_spec");
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Engine Stand Color"
                            margin='normal'
                            variant='outlined'
                            fullWidth
                            value={data?.new_tec_spec?.engine_stand_color}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            onChange={(e) => onFieldChange("engine_stand_color", e.target.value, "new_tec_spec")}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Update Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={data?.new_tec_spec?.update_date ? data?.new_tec_spec?.update_date : null}
                                error={false}
                                helperText={''}
                                onChange={(data, value) => onFieldChange('update_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Comments"
                            margin='normal'
                            variant='outlined'
                            fullWidth
                            value={data?.new_tec_spec?.comments}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 150 }}
                            onChange={(e) => onFieldChange("comments", e.target.value, "new_tec_spec")}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Boot Strape PN"
                            margin='normal'
                            variant='outlined'
                            fullWidth
                            value={data?.new_tec_spec?.boot_strape_pn}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            onChange={(e) => onFieldChange("boot_strape_pn", e.target.value, "new_tec_spec")}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Blanks"
                            margin='normal'
                            variant='outlined'
                            fullWidth
                            value={data?.new_tec_spec?.blanks}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            onChange={(e) => onFieldChange("blanks", e.target.value, "new_tec_spec")}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Service Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={data?.new_tec_spec?.service_date ? data?.new_tec_spec?.service_date : null}
                                error={false}
                                helperText={''}
                                onChange={(data, value) => onFieldChange('service_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Shock Mount Mfg Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={data?.new_tec_spec?.shock_mount_mfg_date ? data?.new_tec_spec?.shock_mount_mfg_date : null}
                                error={false}
                                helperText={''}
                                onChange={(data, value) => onFieldChange('shock_mount_mfg_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                label="Shock Mount Exp Date"
                                format={dayMonthDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                minDate={moment(assetDetail?.date_of_manufacture)}
                                value={data?.new_tec_spec?.shock_mount_exp_date ? data?.new_tec_spec?.shock_mount_exp_date : null}
                                error={false}
                                helperText={''}
                                onChange={(data, value) => onFieldChange('shock_mount_exp_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Shock Mount Limit (Years)"
                            margin='normal'
                            variant='outlined'
                            fullWidth
                            value={data?.new_tec_spec?.shock_mount_limit}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength:10}}
                            onChange={(e) =>  regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange("shock_mount_limit", e.target.value, "new_tec_spec") : e.preventDefault()}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Std Manufacturer"
                            margin='normal'
                            variant='outlined'
                            fullWidth
                            value={data?.new_tec_spec?.std_manufacture}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 150 }}
                            onChange={(e) => onFieldChange("std_manufacture", e.target.value, "new_tec_spec")}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Comment"
                            margin='normal'
                            variant='outlined'
                            fullWidth
                            value={data?.new_tec_spec?.comment}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 150 }}
                            onChange={(e) => onFieldChange("comment", e.target.value, "new_tec_spec")}
                        />
                    </Grid>
                </Grid>
                <EngOnOffWing
                    error={error}
                    onResetError={(key) => setError({ ...error, [key]: '' })}
                    onFieldChange={onFieldChange}
                    basicDetails={data}
                    pltConstants={pltConstants}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={onAddNewAsset} > SAVE CHANGES </Button>
                <Button color="primary" variant="contained" onClick={closeUpdateForm}>CANCEL</Button>
            </DialogActions>
            {pageLoader ? <PageLoader /> : null}
        </div>
    )
}

export default withRouter(EngineDetailForm)