export const adSmbcFilterOptions = {
    'applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'ad_compliance_status': {
        'inputType': 'dropdown',
        'title': 'AD Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'faa_ad': {
        'inputType': 'text',
        'title': 'FAA AD',
    },
    'faa_superseded': {
        'inputType': 'text',
        'title': 'FAA Superseded',
    },
    'easa_ad': {
        'inputType': 'text',
        'title': 'EASA AD',
    },
    'easa_superseded': {
        'inputType': 'text',
        'title': ' EASA Superseded',
    },
}

export const adFilterOptions = {
    'ata': {
        'inputType': 'text',
        'title': 'ATA Chapter',
    },
    'ad_no': {
        'inputType': 'text',
        'title': 'Reference Number',
    },
    'issuing_authority': {
        'inputType': 'text',
        'title': 'Issuing Authority',
    },
    'applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'ad_compliance_status': {
        'inputType': 'dropdown',
        'title': 'AD Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
}

export const sbFilterOptions = {
    'ata_chapter': {
        'inputType': 'text',
        'title': 'ATA Chapter',
    },
    'type': {
        'inputType': 'dropdown',
        'title': 'AD Ccompliance Status',
        'options': [{ label: 'Alert', value: '0' }, { label: 'Standard', value: '1' }, { label: 'Optional', value: '2' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'sb_applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'sb_compliance_status': {
        'inputType': 'dropdown',
        'title': 'SB Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'sb_ref_number': {
        'inputType': 'text',
        'title': 'SB Ref Number',
    },
}
export const sbSmbcFilterOptions = {
    'sb_applicability': {
        'inputType': 'dropdown',
        'title': 'Applicability',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'sb_compliance_status': {
        'inputType': 'dropdown',
        'title': 'SB Ccompliance Status',
        'options': [{ label: 'OPEN', value: '0' }, { label: 'COMPLIED', value: '1' }, { label: 'PARTIALLY COMPLIED', value: '2' }, { label: 'NOT APPLICABLE', value: '3' }],
        'labelKey': 'label',
        'valueKey': 'label',
    },
    'sb_category': {
        'inputType': 'text',
        'title': 'SB category',
    },
    'sb_ref_number': {
        'inputType': 'text',
        'title': 'SB Ref Number',
    },
}

export const configureFilterOptions = {
    'engine_family': {
        'inputType': 'dropdown',
        'title': 'Engine family',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
    },
}

export const servceablefilterOption = {
    'engine_type': {
        'inputType': 'dropdown',
        'title': 'Engine Type',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'esn': {
        'inputType': 'text',
        'title': 'Serial Number',
    },
    'lessee': {
        'inputType': 'dropdown',
        'title': 'Current Lesse',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'slug',
        'multiple': false
    },
    'status': {
        'inputType': 'dropdown',
        'title': 'Status',
        'options': [{ label: "Archive", value: "5" }, { label: "On Lease", value: "1" }, { label: "Off Lease", value: "2" }, { label: "On Ground", value: "3" },
        { label: "Written Off/ Sold / Part out", value: "4" }],
        'labelKey': 'label',
        'valueKey': 'value',
    }
}

export const notificationPreferences = [
    { label: 'Daily', value: 1 },
    { label: 'Weekly', value: 2 },
    { label: 'Monthly', value: 3 }
];

export const customAlrtFilterOps = {
    activity_type: {
        inputType: 'dropdown',
        placeholder: 'Please select Activity Type',
        title: 'Activity Type',
        options: [{ label: 'Portfolio', value: 1 }, { label: 'Serviceable Report', value: 2 }, { label: 'Unserviceable Report', value: 3 }],
        labelKey: 'label',
        valueKey: 'value'
    },
    alert_frequency_type: {
        inputType: 'dropdown',
        placeholder: 'Please select Notification Preference',
        title: 'Notification Preference',
        options: notificationPreferences,
        labelKey: 'label',
        valueKey: 'value'
    },

};
export const stand_bagFilter = {
    'engine_type': {
        'inputType': 'dropdown',
        'title': 'Engine Type',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'id',
        'multiple': true
    },
    'esn': {
        'inputType': 'text',
        'title': 'Serial Number',
    },
    'current_lessee': {
        'inputType': 'dropdown',
        'title': 'Lessee',
        'options': [],
        'labelKey': 'name',
        'valueKey': 'slug',
        'multiple': false
    },

};




