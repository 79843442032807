import React, { useEffect, useState } from "react";
import { DialogTitle, DialogContent, DialogActions, Button, Box, IconButton, Grid, Tooltip, Dialog, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from "@material-ui/icons/Close"
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { imgStoragePath,assetType } from "../../constants";
import config from '../../config'


const ShowAttachments = ({ handleClose, handleShowAttachments, openAttachment, downloadAllApi, item, files, removeAttachment,basicDetails }) => {
	const [prevAttachements, setprevAttachements] = useState({ modal: false, data: null })

	const downloadAll = (event, files, triggerExport) => {
		if (triggerExport) {
			downloadAllApi(files);
		}
	}

	return (
		<div class="getAttachmentDialogue">
			<DialogTitle>Attachments</DialogTitle>
			<Box position="absolute" top={0} right={0}>
				<IconButton> <Close onClick={handleClose} style={{ fill: "white" }} /> </IconButton>
			</Box>
			<DialogContent className="showAttachmentList">
				{handleShowAttachments?.length > 0 ?
					Object.keys(handleShowAttachments).map((key1, index) => {
						const file = handleShowAttachments[key1].path || handleShowAttachments[key1].file;
						return (
							<Grid container spacing={2}>
								<Grid item xs={8}>
									<p>{handleShowAttachments[key1].name}</p>
								</Grid>
								{handleShowAttachments[key1]?.type === 'folder' && <Grid item xs={2}>
										<Tooltip title={`Records Data Room :- ${handleShowAttachments[key1]?.location}`} arrow>
											<a href={`${config.domain.records}${assetType[handleShowAttachments[key1]?.asset_type_id]}/${handleShowAttachments[key1]?.asset_slug}/data-room/technical/${handleShowAttachments[key1]?.uuid}`} target="_new"><img style={{ width: '15px',float:'right' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
										</Tooltip>
									</Grid>}
									{handleShowAttachments[key1]?.type === 'folder' ? null :<Grid item xs={1}>
										<Tooltip title="View">
											<VisibilityIcon color="primary" onClick={() => setprevAttachements({ modal: true, data: handleShowAttachments[key1] })} />
										</Tooltip>
									</Grid>}
									{handleShowAttachments[key1]?.type === 'folder' ? null :<Grid item xs={1}>
										<Tooltip title="Download">
											<button className="downloadIcon" onClick={() => { openAttachment(file); }} > <SaveAltIcon key={key1} color="primary" /> </button>
										</Tooltip>
									</Grid>}
								{basicDetails?.status?.value === 5 ? null :<Grid item xs={1}>
									<Tooltip title="Remove">
										<button className="downloadIcon" onClick={() => { removeAttachment(item, handleShowAttachments, handleShowAttachments[key1].id, handleShowAttachments[key1].uuid) }} > <CloseIcon key={key1} color="secondary" /> </button>
									</Tooltip>
								</Grid>}
							</Grid>
						);
					})
					:
					<p style={{ textAlign: "center" }}>No Files(s) found</p>
				}
			</DialogContent>
			<DialogActions>
				{handleShowAttachments?.length > 0 ? <Button color="primary" variant="contained" onClick={(event) => downloadAll(event, files, "triggerExport")} files={{ title: "AMP", extension: "zip", key: "" }} > Download All </Button> : null}
				<Button color="primary" variant="contained" onClick={handleClose}> Close </Button>
			</DialogActions>
			{prevAttachements.modal ?
				<Dialog
					open={true}
					onClose={() => setprevAttachements({ modal: false })}
					style={{ height: "100vh" }}
					fullWidth
					maxWidth="lg"
				>
					<DialogTitle>
						{prevAttachements?.data?.name}
					</DialogTitle>
					<DialogContent dividers>
						{prevAttachements?.data?.name.endsWith(".xls") || prevAttachements?.data?.name.endsWith(".xlsx") || prevAttachements?.data?.name.endsWith(".docx") ?
							<iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(decodeURIComponent(prevAttachements.data.file ? prevAttachements.data.file : prevAttachements.data.path))} width='100%' height={window.innerHeight} frameborder='0'></iframe>
							:
							null
						}
						{prevAttachements?.data?.name.endsWith(".png") || prevAttachements?.data?.name.endsWith(".jpg") || prevAttachements?.data?.name.endsWith(".jpeg") ?
							<div className="dialog-background" style={{ backgroundImage: `url(${decodeURIComponent(prevAttachements.data.file ? prevAttachements.data.file : prevAttachements.data.path)})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", height: "100vh" }}> </div>
							:
							null
						}
						{prevAttachements?.data?.name.endsWith(".pdf") ?
							<object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(decodeURIComponent(prevAttachements.data?.file ? prevAttachements?.data?.file : prevAttachements?.data?.path))}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{ width: '100%', height: '100vh' }}>
								<param name="type" value="text/html" />
							</object>
							:
							null
						}
					</DialogContent>
					<DialogActions>
						<Button color="primary" size="small" variant="contained" onClick={() => window.open(decodeURIComponent(prevAttachements?.data?.file ? prevAttachements?.data?.file : prevAttachements.data?.path), "_blank")}>Download</Button>
						<Button color="secondary" size="small" variant="outlined" onClick={() => setprevAttachements({ modal: false })}>Okay</Button>
					</DialogActions>
				</Dialog> : null
			}
		</div>
	);
};

export default ShowAttachments;
